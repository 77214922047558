import React, { Component } from 'react';
import Interpreter from 'models/interpreter';
import { arrayToObject } from 'utils/Helper';
import { createUserWithRandomPassword } from 'services/auth-service';
import { getPriceClassOptions } from 'services/languages-service';

// components
import Input, { ValueToState } from 'components/Input/input.js';
import Button from 'components/Button/button.js';
import MultiSelectDropdown from 'components/MultiSelectDropdown/multi-select-dropdown.js';
import SingleSelectDropdown from 'components/SingleSelectDropdown/single-select-dropdown.js';

import jquery from 'jquery';
import './new-interpreter.css';

export default class NewInterpreter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: this.props.interpreter && this.props.interpreter.firstName,
      lastName: this.props.interpreter && this.props.interpreter.lastName,
      //email: this.props.interpreter && this.props.interpreter,
      phoneNumber: this.props.interpreter && this.props.interpreter.phoneNumber,
      city: this.props.interpreter && this.props.interpreter.city,
      sex: this.props.interpreter && this.props.interpreter.sex,
      languages:
        this.props.interpreter && this.props.interpreter.getLanguagesArray(),
      priceClass: this.props.interpreter && this.props.interpreter.priceClass,
      successMessage: null,
      errorMessage: null,
      isLoading: false,
      tempPassword: null,
      inputErrors: {},
    };

    this.singleSelectOptions = [
      { label: 'Mies', value: 'man' },
      { label: 'Nainen', value: 'woman' },
    ];

    this.savePressed = this.savePressed.bind(this);
  }

  //   clearForm() {
  //     this.inputfirstName.value = null
  //     this.inputlastName.value = null
  //     this.inputPhoneNumber.value = null
  //   }

  validate() {
    var inputErrors = {};

    // mehedi - redundant or condition check.
    // !this.state.firstName || this.state.firstName == ''
    if (!this.state.firstName) {
      inputErrors.firstName = 'Vaaditaan';
    }

    if (!this.state.lastName) {
      inputErrors.lastName = 'Vaaditaan';
    }

    if (!this.state.email && !this.props.interpreter) {
      inputErrors.email = 'Vaaditaan';
    }

    if (!this.state.city) {
      inputErrors.city = 'Vaaditaan';
    }

    if (!this.state.phoneNumber) {
      inputErrors.phoneNumber = 'Vaaditaan';
    }

    if (!this.state.sex) {
      inputErrors.sex = 'Vaaditaan';
    }

    this.setState({ inputErrors });

    return jquery.isEmptyObject(inputErrors); // inputErrors.isEmpty()
  }

  savePressed() {
    this.setState({ isLoading: true });

    // VALIDATION!!!!!!!!!!!
    const valid = this.validate();
    if (!valid) {
      this.setState({ isLoading: false });
      return;
    }

    if (this.props.interpreter) {
      // EDIT EXISTING
      if (
        this.state.firstName &&
        this.state.lastName &&
        this.state.sex &&
        this.state.languages
      ) {
        this.props.interpreter.updateInterpreterData(
          this.state.firstName,
          this.state.lastName,
          this.state.sex,
          this.state.phoneNumber,
          this.state.city,
          this.state.priceClass,
          this.state.languages,
          () => {
            // this.setState({isLoading: false})
            this.props.cancelPressed();
          },
          (error) => {
            console.log(error);
            this.setState({ isLoading: false });
          },
        );
      }
    } else {
      // CREATE NEW ACCOUNT
      createUserWithRandomPassword(this.state.email)
        .then((data) => {
          const languages = this.state.languages
            ? arrayToObject(this.state.languages)
            : null;
          const newInterpreter = new Interpreter(
            data.user.uid,
            false,
            this.state.firstName,
            this.state.lastName,
            this.state.phoneNumber,
            this.state.city,
            languages,
            this.state.sex,
            this.state.priceClass,
          );

          newInterpreter
            .saveInterpreterToFirebase()
            .then(() => {
              this.setState({ tempPassword: data.password, isLoading: false });
            })
            .catch((error) => {
              console.error(error);
              this.setState({
                errorMessage: 'Jotain meni pieleen',
                isLoading: false,
              });
            });
        })
        .catch((error) => {
          switch (error.code) {
            case 'already-exists':
              this.setState({
                errorMessage: 'Tunnukset ovat jo olemassa',
                isLoading: false,
              });
              break;
            default:
              console.error(error.code);
              this.setState({
                errorMessage: 'Tunnusten luominen epäonnistui',
                isLoading: false,
              });
              break;
          }
        });
    }
  }

  render() {
    // const { author, title, text, isLoading, successMessage, errorMessage } = this.state;
    const { interpreter } = this.props;
    const { tempPassword, inputErrors, errorMessage, isLoading } = this.state;

    return (
      <div className="new-interpreter-box">
        {!interpreter ? (
          <h3>{tempPassword ? 'Tulkki luotu' : 'Lisää tulkki'}</h3>
        ) : (
          <h3>Muokkaa tietoja</h3>
        )}

        {tempPassword ? (
          <div>
            <section>
              <div>
                <h5 className="font-light alert-txt">
                  Ota salasana ja käyttäjätunnus ylös! Et näe niitä enää tämän
                  jälkeen.
                </h5>
                <br />
                <h5 className="font-bold">
                  {this.state.firstName} {this.state.lastName}
                </h5>
                <p className="no-margin">Käyttäjätunnus: {this.state.email}</p>
                <p>Salasana: {tempPassword}</p>
              </div>
            </section>

            <div className="button-row">
              <Button round title="Valmis" onClick={this.props.cancelPressed} />
            </div>
          </div>
        ) : (
          <div>
            <Input
              required
              bold
              title="Etunimi"
              errorMessage={inputErrors && inputErrors.firstName}
              valueToState={new ValueToState(this, 'firstName')}
              disabled={isLoading}
            />

            <Input
              required
              bold
              title="Sukunimi"
              valueToState={new ValueToState(this, 'lastName')}
              errorMessage={inputErrors && inputErrors.lastName}
              disabled={isLoading}
            />

            {!interpreter && (
              <Input
                required
                bold
                title="Sähköposti"
                valueToState={new ValueToState(this, 'email')}
                errorMessage={inputErrors && inputErrors.email}
                disabled={isLoading}
              />
            )}

            <Input
              required
              bold
              title="Puhelinnnumero"
              valueToState={new ValueToState(this, 'phoneNumber')}
              errorMessage={inputErrors && inputErrors.phoneNumber}
              disabled={isLoading}
            />

            <Input
              required
              bold
              title="Kaupunki"
              valueToState={new ValueToState(this, 'city')}
              errorMessage={inputErrors && inputErrors.city}
              disabled={isLoading}
            />

            {/* <Input required bold
               title='Puhelinnumero'
               valueToState={new ValueToState(this, 'phoneNumber')}
            /> */}

            <SingleSelectDropdown
              errorMessage={inputErrors && inputErrors.sex}
              disabled={isLoading}
              options={this.singleSelectOptions}
              title="Sukupuoli"
              value={this.state.sex}
              valueChanged={(sex) => this.setState({ sex })}
            />

            <SingleSelectDropdown
              disabled={isLoading}
              options={getPriceClassOptions()}
              title="Luokka"
              value={this.state.priceClass}
              valueChanged={(priceClass) => this.setState({ priceClass })}
            />

            <MultiSelectDropdown
              title="Kielet"
              disabled={isLoading}
              value={this.state.languages}
              valueChanged={(languages) => {
                this.setState({ languages });
              }}
            />

            {/* <Input
               title='Viesti'
               bold
               rows={6}
               valueToState={new ValueToState(this, 'text')}
            /> */}

            {errorMessage && <p className="error-txt">{errorMessage}</p>}

            <div className="button-row">
              <Button
                styleType="secondary"
                round
                title="Peruuta"
                onClick={this.props.cancelPressed}
                disabled={isLoading}
              />
              <Button
                round
                title="Tallenna"
                onClick={this.savePressed}
                disabled={isLoading}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
