import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/fontawesome-pro-light';
import './button-show-details.css';

export default class ButtonShowDetails extends Component {
  render() {
    const { open } = this.props;

    return (
      <button className="btn-show-details" onClick={this.props.onClick}>
        <p className="no-margin">Loki</p>
        <FontAwesomeIcon
          className="fa-icon-details-btn"
          icon={open ? faChevronUp : faChevronDown}
        />
      </button>
    );
  }
}
