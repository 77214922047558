import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import moment from 'moment';

import 'components/InterpretationSearch/interpretations-search.css';

class InterpretationsSearch extends Component {
  state = {
    value: '',
    suggestions: [],
  };

  // search orders and events functionality
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength < 3
      ? []
      : this.props.allCalendarEvents
          .filter(
            ({ title, description }) =>
              title.toLowerCase().includes(inputValue) ||
              (description !== '' &&
                description.toLowerCase().includes(inputValue)),
          )
          .slice(0, 5);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const start = moment(suggestion.start);
    const end = moment(suggestion.end);
    this.setState({ value: '' });
    this.props.onSelectedEvent({ ...suggestion, start, end });
  };

  render() {
    const { value, suggestions } = this.state;

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={(suggestion) => suggestion.title}
        renderSuggestion={(suggestion) => <div>{suggestion.title}</div>}
        inputProps={{
          placeholder: 'Hae tulkkauksista',
          value,
          onChange: this.onChange,
        }}
      />
    );
  }
}

InterpretationsSearch.propTypes = {
  allCalendarEvents: PropTypes.array.isRequired,
  onSelectedEvent: PropTypes.func.isRequired,
};

export default InterpretationsSearch;
