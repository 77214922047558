import { SET_INTERPRETER_NAME } from 'actions/types';

const initialState = {};

const interpreterNamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTERPRETER_NAME:
      const names = Object.assign({}, state);
      names[action.payload.id] = action.payload.name;
      return names;
    default:
      break;
  }
  return state;
};

export default interpreterNamesReducer;
