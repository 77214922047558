//import Validator from 'validator'
// import { fService } from './firebaseService';
/*import firebase from 'firebase/app';
import 'firebase/functions'*/
import firebase from 'config/firebaseConfig';

export function createUserWithEmailAndPassword(
  email,
  password,
  callback,
  onError,
) {
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((data) => {
      callback(data.user);
    })
    .catch((error) => {
      onError(error);
    });
}

export function createUserWithRandomPassword(email) {
  return new Promise((resolve, reject) => {
    const addMessage = firebase.functions().httpsCallable('/user/create');
    addMessage({ email: email })
      .then(function (result) {
        resolve(result.data);
      })
      .catch((error) => {
        console.error('error - createUserWithRandomPassword');
        console.error(error);
        reject(error);
      });
  });
}

export function sendPasswordResetEmail(email, callback, onError) {
  var auth = firebase.auth();

  auth
    .sendPasswordResetEmail(email)
    .then(() => {
      callback();
    })
    .catch((error) => {
      onError(error);
    });
}

function reAuthenticate(password, callback, onError) {
  var user = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(
    user.email,
    password,
  );

  // mehedi
  // reauthenticateAndRetrieveDataWithCredential this function is deprecated
  /*user
    .reauthenticateAndRetrieveDataWithCredential(credential)
    .then(() => {
      callback();
    })
    .catch((error) => {
      onError(error);
    });*/

  user
    .reauthenticateWithCredential(credential)
    .then(() => {
      callback();
    })
    .catch((error) => {
      onError(error);
    });
}

export function changePassword(password, previous, callback, onError) {
  const user = firebase.auth().currentUser;

  reAuthenticate(
    previous,
    () => {
      user
        .updatePassword(password)
        .then(() => {
          callback();
        })
        .catch((error) => {
          onError(error);
        });
    },
    (error) => {
      onError(error);
    },
  );
}

export function updateEmail(password, newEmail, callback, onError) {
  const user = firebase.auth().currentUser;

  reAuthenticate(
    password,
    () => {
      user
        .updateEmail(newEmail)
        .then(() => {
          callback();
        })
        .catch((error) => {
          onError(error);
        });
    },
    (error) => {
      onError(error);
    },
  );
}

export function signOut() {
  firebase.auth().signOut();
}
