import firebase from 'config/firebaseConfig';
import { fService } from 'services/FService.js';
/*import 'firebase/firestore/dist/index.cjs';
import firebase from 'firebase/app'
import 'firebase/firestore'*/

export default class User {
  constructor(
    id,
    firstName,
    lastName,
    organizationId,
    phoneNumber,
    administrator,
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.organizationId = organizationId;
    this.administrator = administrator;
  }

  removeFromOrganization(callback, onError) {
    // Remove the 'organizationId' field from the document
    const userRef = fService.REF_USERS.doc(this.id);
    userRef
      .update({
        organizationId: firebase.firestore.FieldValue.delete(),
      })
      .then(function () {
        callback();
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        onError('Error updating document: ' + error);
      });
  }

  static removeUserFromOrganization(id, callback, onError) {
    // Remove the 'organizationId' field from the document
    const userRef = fService.REF_USERS.doc(id);
    userRef
      .update({
        organizationId: firebase.firestore.FieldValue.delete(),
      })
      .then(function () {
        callback();
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        onError('Error updating document: ' + error);
      });
  }

  static userFromDocument(doc) {
    const data = doc.data();
    // TARVITSEEKO TARKASTAA JOS JOTKUT OMINAISUUDET OVAT PAKOLLISIA?
    const newUser = new User(
      doc.id,
      data.firstName,
      data.lastName,
      data.organizationId,
      data.phoneNumber,
      data.rules && data.rules.administrator,
    );
    return newUser;
  }

  static getUser(id, callback, onError) {
    const userRef = fService.REF_USERS.doc(id);
    userRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const newUser = User.userFromDocument(doc);
          callback(newUser);
        } else {
          // doc.data() will be undefined in this case
          onError('No such document!');
        }
      })
      .catch(function (error) {
        onError(error);
      });
  }

  static getUsers(organizationId, callback, onError) {
    var users = [];
    const userRef = fService.REF_USERS;

    userRef
      .where('organizationId', '==', organizationId)
      .orderBy('lastName') // jos lastName ei ole , niin ei hae ollenkaan - pakolliset nimet!
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const newUser = User.userFromDocument(doc);
          users.push(newUser);
        });

        callback(users);
      })
      .catch((error) => {
        const message = 'Error getting documents: ' + error;
        onError(message);
      });
  }

  static createUser(id, firstName, lastName, phone, callback, onError) {
    const userRef = fService.REF_USERS;
    userRef
      .doc(id)
      .set({
        firstName,
        lastName,
        phoneNumber: phone,
      })
      .then(() => {
        callback();
      })
      .catch((error) => {
        onError('Error writing document: ' + error);
      });
  }

  static updateUserData(id, firstName, lastName, callback, onError) {
    const ref = fService.REF_USERS.doc(id);
    ref
      .update({
        firstName,
        lastName,
      })
      .then(() => {
        callback();
      })
      .catch((error) => {
        onError('Error updating document: ' + error);
      });
  }
}
