import { INTERPRETERS_RECEIVED } from '../actions/types';

const initialState = [];

const interpretersReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTERPRETERS_RECEIVED:
      return action.payload.interpreters;

    default:
      break;
  }
  return state;
};

export default interpretersReducer;
