import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import './borderless-button.css';

export default class BorderlessButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      hover: false,
    };
  }

  render() {
    const {
      id,
      className,
      title,
      icon,
      iconOnFocus,
      size,
      styleType,
      color,
      shadowType,
      onClick,
      disabled,
      bold,
      marginRight,
    } = this.props;
    let { style } = this.props;
    if (!style) style = {};
    if (marginRight) style.marginRight = marginRight;

    if (styleType === BorderlessButton.StyleType.DEFAULT && color) {
      let { hover, focused } = this.state;

      if (!hover) {
        style.color = color[0];
      } else {
        if (color[1]) style.color = color[1];
        if (shadowType === BorderlessButton.ShadowType.MATERIAL) {
          if (color[2]) {
            style.boxShadow = '0px 0px 0px 8px ' + color[2];
            style.background = color[2];
          }
        }
      }
      if (
        (hover || focused) &&
        shadowType === BorderlessButton.ShadowType.TEXT_SHADOW &&
        !disabled &&
        color[3]
      ) {
        style.textShadow = '0 0 3px ' + color[3];
        style.filter = 'drop-shadow(0px 0px 2px ' + color[3] + ')';
      }
    }

    return (
      <div
        id={id}
        className={classNames('bro-borderless-button', {
          [className]: className,
        })}
        onClick={onClick}
        data-size={size}
        data-type={styleType}
        data-shadow-type={shadowType}
        disabled={disabled === true ? true : undefined}
        data-bold={bold}
        style={style}
        tabIndex="0"
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focused: true })}
        onBlur={() => this.setState({ focused: false })}
      >
        {title && <p className="title">{title}</p>}
        {title && icon && <span>&nbsp;&nbsp;</span>}
        {icon && (
          <FontAwesomeIcon
            className="icon"
            icon={this.state.focused && iconOnFocus ? iconOnFocus : icon}
          />
        )}
      </div>
    );
  }

  static StyleType = Object.freeze({
    DEFAULT: 'default',
    PRIMARY: 'primary',
    DANGER: 'danger',
  });

  static ShadowType = Object.freeze({
    NONE: 'none',
    MATERIAL: 'material',
    TEXT_SHADOW: 'text_shadow',
  });

  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.any,
    iconOnFocus: PropTypes.any,
    onClick: PropTypes.func,
    size: PropTypes.number,
    styleType: PropTypes.string,
    shadowType: PropTypes.string,
    marginRight: PropTypes.string,
    disabled: PropTypes.bool,
    bold: PropTypes.bool,
    style: PropTypes.object,
    color: PropTypes.arrayOf(PropTypes.string), // array: [color, hover color, shadow color]   - testi että voi laittaa värin parametrinä
  };

  static defaultProps = {
    size: 1,
    styleType: BorderlessButton.StyleType.DEFAULT,
    shadowType: BorderlessButton.ShadowType.MATERIAL,
    disabled: false,
    bold: false,
  };
}
