import React, { Component } from 'react';

import Select from 'react-select';
import 'react-select/dist/react-select.css';
import './single-select-dropdown.css';

export default class SingleSelectDropdown extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value ? this.props.value : null,
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(value) {
    this.setState({ value }, () => {
      this.props.valueChanged(value);
    });
  }

  render() {
    // const { value } = this.state;
    const { placeholder, title } = this.props;

    return (
      <div className="dropdown-container">
        {title && <p>{title}</p>}

        <Select
          className={
            'single-select-dropdown' +
            (this.props.errorMessage ? ' error-dropdown' : '')
          }
          name="single-select-field"
          closeOnSelect={true}
          disabled={this.props.disabled}
          onChange={this.handleSelectChange}
          options={this.props.options}
          placeholder={placeholder ? placeholder : ''}
          simpleValue
          searchable={this.props.searchable ? this.props.searchable : false}
          value={this.props.value}
          clearable={this.props.clearable || true}
        />

        {this.props.errorMessage && (
          <p className="error-message-label">{this.props.errorMessage}</p>
        )}
      </div>
    );
  }
}
