import React, {Component} from 'react';
import classNames from 'classnames'
import { connect } from 'react-redux'
import './content-container.css'

class ContentContainer extends Component {


   hasChildren() {

      var has = false

      React.Children.forEach(this.props.children, (child) => {
         if (child !== null && child !== false ) {
            has = true
         }
      });
      
      return has
   }

  render() {
     const { isMobile } = this.props
    return (
      <div className={classNames("content-container", {"mobile": isMobile})}>
         {this.hasChildren() &&
            <div className="outer-c-container">
            <div className="inner-c-container">
               {this.props.children}
            </div>
            </div>
         }
      </div>
    );
  }

}

const mapStateToProps = state => {
   return {
      isMobile: state.device.isMobile,
   }
}

export default connect(mapStateToProps)(ContentContainer)