/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './dropdown.css';

export default class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      origButton: this.props.button,
      buttonRef: React.createRef(),
    };

    // mehedi - unused variable. can be deleted
    this.buttonDom = undefined;
    const button = React.cloneElement(this.props.button, {
      ref: this.state.buttonRef,
    });
    this.dropdownDom = undefined;
    this.dropdownRef = React.createRef();

    this.state.button = button;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.button !== state.button)
      return {
        button: React.cloneElement(props.button, { ref: state.buttonRef }),
      };
    return null;
  }

  componentDidMount() {
    this.dropdownDom = ReactDOM.findDOMNode(this.dropdownRef.current);
    this.domButton = ReactDOM.findDOMNode(this.state.buttonRef.current);
    this.domButton.addEventListener('click', () => {
      if (this.dropdownDom.classList.contains('show')) {
        this.domButton.blur();
      }
    });
    this.domButton.setAttribute('data-toggle', 'dropdown');
    this.domButton.setAttribute('aria-haspopup', 'true');
    this.domButton.setAttribute('aria-expanded', 'false');
    if (this.props.dropdownArrow)
      this.domButton.classList.add('dropdown-toggle');
  }

  render() {
    const { id, menuItems, onSelect, className } = this.props;

    var classN = 'custom-dropdown dropdown';
    if (className) classN += ' ' + className;

    return (
      <div id={id} className={classN}>
        {this.state.button}
        <div
          className="dropdown-menu"
          ref={this.dropdownRef}
          aria-labelledby="dropdownMenuButton"
        >
          {/*mehedi - improved this map function return*/}
          {/*{menuItems.map((options) => {
            if (options.hidden) return;
            return (
              <a
                key={options.value}
                className="dropdown-item"
                onClick={() => onSelect(options.value)}
                data-type={options.type}
              >
                {options.label}
              </a>
            );
          })}*/}
          {menuItems.map(
            (options) =>
              !options.hidden && (
                <a
                  key={options.value}
                  className="dropdown-item"
                  onClick={() => onSelect(options.value)}
                  data-type={options.type}
                >
                  {options.label}
                </a>
              ),
          )}
        </div>
      </div>
    );
  }

  static propTypes = {
    id: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    button: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    dropdownArrow: PropTypes.bool,
  };
}

/* ---- PARAMETERS ---- \

	id
		- string

	button [required]
		- button component that opens dropdown
		- Example:
				<BorderlessButton
					icon={faCog}
					iconOnFocus={faCogS}
					shadowType={BorderlessButton.ShadowType.NONE}
					disabled={loading}
				/>


	menuItems [required]
		- array of json items containing value, label and type parameter
		- types: "danger" // ei muita nyt
		- Example:
			[
				{ value: "about": label: "About" },
				{ value: "sign_out", label: "Sign Out", type: "danger" }
			]


	onSelect [required]
		- function that returns menuItem value


	dropdownArrow
		- boolean
		- is dropdown arrow visible next to button


*/
