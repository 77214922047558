import React, {Component} from 'react';
import BorderlessButton from '../BorderlessButton/borderless-button.js'
import Dropdown from '../Dropdown/dropdown.js'
import './title-component.css'
import classNames from 'classnames'

import {faCog as faCogS} from '@fortawesome/fontawesome-pro-solid'

export default class TitleComponent extends Component {

  render() {

   const { title, menuItems, menuItemSelected, noMargin } = this.props

    return (
       <div className={classNames("title-component-container", {"no-margin" : noMargin})}>
      <div className="title-component">
         <h3 className="title-component-title">{title}</h3>

         { menuItems &&
         <div>
          <Dropdown menuItems={menuItems} onSelect={menuItemSelected} button={
              <BorderlessButton className="cog-wheel" icon={faCogS}/>
            }/>
         </div>
        }
        </div>
        {this.props.children}
      </div>
    );
  }

}