import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'fullcalendar';

// redux
import { connect } from 'react-redux';
import { setInterpreters } from 'actions/setInterpreters';

// firebase
import CalendarEvent from 'models/calendar-event';
import Interpreter from 'models/interpreter';

// components
import ContentContainer from 'components/ContentContainer/content-container';
import ContainerBox from 'components/ContainerBox/container-box';
import LoadingSpinner from 'components/LoadingSpinner/loading-spinner';
import ListContainer from 'components/ListContainer/list-container';
import Button from 'components/Button/button';
import Toggle from 'components/Toggle/toggle';
import NewEventBox from './NewEventBox/new-event-box';
import EventModal from './EventModal/event-modal';

import 'fullcalendar/dist/locale/fi';
import List from 'components/List/list';
import InterpretersModal from './InterpretersModal/interpreters-modal';
import $ from 'jquery';
import 'fullcalendar/dist/fullcalendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import './page-calendar.css';
import InterpretationsSearch from '../InterpretationSearch/interpretations-search';

class PageCalendar extends Component {
  // Example: https://codepen.io/IsmiKin/pen/WbOeGw?editors=0010

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      createEvent: false,
      editEvent: false,
      selectedEvent: null,
      interpreter: this.props.user.interpreterId || null,
      interpreterOptions: [],
      calendarViewType: 'month',
      timeModified: false,
      durations: {},
      showModal: false,
      jumpDate: null,
      allCalendarEvents: [],
    };

    this.defaultView = this.props.user.isInterpreter ? 'listWeek' : 'month';

    this.colorIlmoitusulkkaus = '#3722f6';
    this.colorLasnaolotulkkaus = '#00C853';
    this.colorPikatulkkaus = '#424242';
    this.colorPuhelintulkkaus = '#FFFF00';
    this.colorCancelled = '#D50000';
    this.colorKaannos = '#512DA8';
    this.colorUnavailable = '#C0C0C0';
    this.colorVideoPuhelut = '#c018de';
  }

  getAllEvents = () => {
    CalendarEvent.getAllEvents(
      (allCalendarEvents) => this.setState({ allCalendarEvents }),
      (error) => console.log(error),
    );
  };

  componentDidMount() {
    this.createCalendar();

    if (this.props.user.isAdministrator) {
      Interpreter.getInterpreters(
        (interpreters) => {
          this.props.setInterpreters(interpreters);
        },
        (error) => {
          console.log(error);
        },
      );

      this.getAllEvents();
    }
  }

  componentDidUpdate(prevProp, prevState) {
    if (
      this.state.interpreter !== prevState.interpreter ||
      this.state.timeModified !== prevState.timeModified
    ) {
      $('#calendar').fullCalendar('removeEvents');
      this.reloadEvents();
    }
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  createCalendar = () => {
    const _this = this;
    const STATUS_NORMAL = 'normal';

    $('#calendar').fullCalendar({
      // put your options and callbacks here
      header: { center: 'month,agendaWeek,listWeek' }, //basicDay,listDay
      defaultView: this.defaultView,
      nowIndicator: true,
      timeFormat: 'H(:mm)', // uppercase H for 24-hour clock
      locale: 'fi',
      timezone: 'local',

      // eventSources: this.eventSources,
      // events: this.state.events,

      color: 'black', // an option!
      textColor: 'yellow', // an option!
      // themeSystem: "bootstrap4",

      views: {
        month: {
          // name of view
          //   titleFormat: 'YYYY, MM, DD'
          // other view-specific options here
          displayEventTime: false,
        },
        agendaWeek: {
          displayEventTime: false,
        },
      },

      viewRender: function (view, element) {
        _this.setState({ calendarViewType: view.name });
      },

      // this code will show event info as tooltip
      /*eventRender: function (eventObj, $el) {
        const time =
          dateToString(eventObj.start.toDate(), true, false) +
          ' klo. ' +
          dateToString(eventObj.start.toDate(), false, true);


        $el.popover({
          title: eventObj.title || '',
          content: time + ', ' + (eventObj.description || ''),
          trigger: 'hover',
          placement: 'top',
          container: 'body',
        });
      },*/

      eventSources: [
        {
          events: function (start, end, timezone, callback) {
            CalendarEvent.getEvents(
              start.toDate(),
              end.toDate(),
              _this.state.interpreter,
              CalendarEvent.Types.PUHELINTULKKAUS,
              STATUS_NORMAL,
              _this.state.timeModified,
              (jsonEvents, events) => {
                // _this.getDurationFromEvents(events, CalendarEvent.Types.PUHELINTULKKAUS)
                callback(jsonEvents);
              },
              (error) => console.log(error),
            );
          },
          color: _this.colorPuhelintulkkaus,
          textColor: 'black',
        },
        {
          events: function (start, end, timezone, callback) {
            CalendarEvent.getEvents(
              start.toDate(),
              end.toDate(),
              _this.state.interpreter,
              CalendarEvent.Types.ILMOITUSTULKKAUS,
              STATUS_NORMAL,
              _this.state.timeModified,
              (jsonEvents, events) => {
                // _this.getDurationFromEvents(events, CalendarEvent.Types.ILMOITUSTULKKAUS)
                callback(jsonEvents);
              },
              (error) => console.log(error),
            );
          },
          color: _this.colorIlmoitusulkkaus,
          textColor: 'white',
        },
        {
          events: function (start, end, timezone, callback) {
            CalendarEvent.getEvents(
              start.toDate(),
              end.toDate(),
              _this.state.interpreter,
              CalendarEvent.Types.LÄSNÄOLOTULKKAUS,
              STATUS_NORMAL,
              _this.state.timeModified,
              (jsonEvents, events) => {
                // _this.getDurationFromEvents(events, CalendarEvent.Types.LÄSNÄOLOTULKKAUS)
                callback(jsonEvents);
              },
              (error) => console.log(error),
            );
          },
          color: _this.colorLasnaolotulkkaus,
          textColor: 'black',
        },
        {
          events: function (start, end, timezone, callback) {
            CalendarEvent.getEvents(
              start.toDate(),
              end.toDate(),
              _this.state.interpreter,
              CalendarEvent.Types.PIKATULKKAUS,
              STATUS_NORMAL,
              _this.state.timeModified,
              (jsonEvents, events) => {
                // _this.getDurationFromEvents(events, CalendarEvent.Types.PIKATULKKAUS)
                callback(jsonEvents);
              },
              (error) => console.log(error),
            );
          },
          color: _this.colorPikatulkkaus,
          textColor: 'white',
        },
        {
          events: function (start, end, timezone, callback) {
            CalendarEvent.getEvents(
              start.toDate(),
              end.toDate(),
              _this.state.interpreter,
              CalendarEvent.Types.KÄÄNNOS,
              STATUS_NORMAL,
              _this.state.timeModified,
              (jsonEvents, events) => {
                callback(jsonEvents);
              },
              (error) => console.log(error),
            );
          },
          color: _this.colorKaannos,
          textColor: 'white',
        },
        {
          events: function (start, end, timezone, callback) {
            CalendarEvent.getEvents(
              start.toDate(),
              end.toDate(),
              _this.state.interpreter,
              CalendarEvent.Types.VIDEOPUHELET,
              STATUS_NORMAL,
              _this.state.timeModified,
              (jsonEvents, events) => {
                callback(jsonEvents);
              },
              (error) => console.log(error),
            );
          },
          color: _this.colorVideoPuhelut,
          textColor: 'white',
        },
        {
          events: function (start, end, timezone, callback) {
            CalendarEvent.getEvents(
              start.toDate(),
              end.toDate(),
              _this.state.interpreter,
              null,
              'cancelled',
              _this.state.timeModified,
              (jsonEvents, events) => {
                callback(jsonEvents);
              },
              (error) => console.log(error),
            );
          },
          color: this.colorCancelled,
          textColor: 'white',
        },
        {
          events: function (start, end, timezone, callback) {
            CalendarEvent.getEvents(
              start.toDate(),
              end.toDate(),
              _this.state.interpreter,
              CalendarEvent.Types.UNAVAILABLE,
              STATUS_NORMAL,
              _this.state.timeModified,
              (jsonEvents, events) => {
                callback(jsonEvents);
              },
              (error) => console.log(error),
            );
          },
          color: this.colorUnavailable,
          textColor: 'black',
        },
      ],

      // events: function(start, end, timezone, callback) {
      //    const interpreter = _this.props.user.interpreterId || null
      //    CalendarEvent.getEvents(start, end, interpreter, callback, (error) => console.log(error))
      // },

      loading: function (isLoading, view) {
        _this.setState({ isLoading });
      },

      eventClick: function (event, jsEvent, view) {
        _this.setState({ selectedEvent: event });
      },

      eventMouseover: function (event, jsEvent, view) {
        document.body.style.cursor = 'pointer';
      },

      eventMouseout: function (event, jsEvent, view) {
        document.body.style.cursor = 'default';
      },

      dayClick: function () {
        // alert('a day has been clicked!');
      },
    });
  };

  reloadEvents = () => {
    $('#calendar').fullCalendar('refetchEvents');
  };

  goToDate = (date) => {
    this.setState({ jumpDate: date });
    date && $('#calendar').fullCalendar('gotoDate', date);
  };

  cellTapped = (interpreterId) => {
    if (this.state.interpreter === interpreterId) {
      this.setState({ interpreter: null });
    } else {
      this.setState({ interpreter: interpreterId });
    }
  };

  render() {
    const {
      isLoading,
      createEvent,
      editEvent,
      selectedEvent,
      interpreter,
      timeModified,
      showModal,
      allCalendarEvents,
    } = this.state;
    const { user, interpreters, filteredInterpreters, isMobile } = this.props;

    return (
      <div>
        {user.isAdministrator && (
          <ListContainer
            title="Kalenteri"
            listItems={
              filteredInterpreters.interpreters.length > 0
                ? filteredInterpreters.interpreters
                : interpreters
            }
            cellTapped={this.cellTapped}
            // createNewTapped={() => this.setState({createEvent: true})}
            selectedItem={interpreter ? interpreter : null}
          />
        )}

        <ContentContainer>
          {/*<OrderSearch
            allCalendarEvents={allCalendarEvents}
            onSelectedEvent={(selectedEvent) =>
              this.setState({ selectedEvent })
            }
          />*/}

          <InterpretationsSearch
            allCalendarEvents={allCalendarEvents}
            onSelectedEvent={(selectedEvent) =>
              this.setState({ selectedEvent })
            }
          />

          <ContainerBox>
            <div id="calendar" className="full-calendar" />

            <div
              className="event-legends"
              style={{
                display: isMobile ? 'none' : '',
              }}
            >
              <div className="legend-row">
                <div
                  className="event-legend"
                  style={{ backgroundColor: this.colorIlmoitusulkkaus }}
                />
                <p>Ilmoitustulkkaus</p>
              </div>

              <div className="legend-row">
                <div
                  className="event-legend"
                  style={{ backgroundColor: this.colorLasnaolotulkkaus }}
                />
                <p>Läsnäolotulkkaus</p>
              </div>

              <div className="legend-row">
                <div
                  className="event-legend"
                  style={{ backgroundColor: this.colorPikatulkkaus }}
                />
                <p>Pikatulkkaus</p>
              </div>

              <div className="legend-row">
                <div
                  className="event-legend"
                  style={{ backgroundColor: this.colorPuhelintulkkaus }}
                />
                <p>Puhelintulkkaus</p>
              </div>

              <div className="legend-row">
                <div
                  className="event-legend"
                  style={{ backgroundColor: this.colorKaannos }}
                />
                <p>Käännös</p>
              </div>

              <div className="legend-row">
                <div
                  className="event-legend"
                  style={{ backgroundColor: this.colorVideoPuhelut }}
                />
                <p>Video puhelut</p>
              </div>

              <div className="legend-row">
                <div
                  className="event-legend"
                  style={{ backgroundColor: this.colorCancelled }}
                />
                <p>Peruttu</p>
              </div>

              <div className="legend-row">
                <div
                  className="event-legend"
                  style={{ backgroundColor: this.colorUnavailable }}
                />
                <p>Ei käytetävissä</p>
              </div>
            </div>

            {isLoading && (
              <div className="loading-indicator">
                <LoadingSpinner />
              </div>
            )}
          </ContainerBox>

          {selectedEvent && (
            <EventModal
              event={selectedEvent}
              onEditPressed={() => this.setState({ editEvent: true })}
              onClose={() => this.setState({ selectedEvent: null })}
              reloadEvents={this.reloadEvents}
            />
          )}

          {showModal && (
            <InterpretersModal
              onClose={this.handleCloseModal}
              primaryBtnTitle="OK"
              primaryBtnPressed={this.handleCloseModal}
              title="Tulkit"
            >
              <List
                listItems={
                  filteredInterpreters.interpreters.length > 0
                    ? filteredInterpreters.interpreters
                    : interpreters
                }
                cellTapped={this.cellTapped}
                selectedItem={interpreter ? interpreter : null}
              />
            </InterpretersModal>
          )}

          {(createEvent || (editEvent && selectedEvent !== null)) && (
            <NewEventBox
              event={selectedEvent}
              onEventCreated={() => {
                this.reloadEvents();
                this.setState({
                  createEvent: false,
                  editEvent: false,
                  selectedEvent: null,
                });
              }}
              onClose={() =>
                this.setState({
                  createEvent: false,
                  editEvent: false,
                  selectedEvent: null,
                })
              }
              reFetchEvents={this.getAllEvents}
            />
          )}

          {user.isAdministrator && (
            <div>
              <Button
                onClick={() => this.setState({ createEvent: true })}
                title="Uusi Varaus"
              />

              {isMobile && (
                <Button onClick={() => this.handleShowModal()} title="Tulkit" />
              )}

              <DatePicker
                selected={this.state.jumpDate}
                onChange={(date) => this.goToDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                isClearable
                placeholderText={`Valitse kuukausi`}
              />
              <br />
              <br />
              <div className="flex-row">
                <p style={{ marginBottom: 0, marginRight: '12px' }}>
                  Vain varaukset, jossa kestoa on muokattu
                </p>
                <Toggle
                  toggleId="time-modified-toggle"
                  isOn={timeModified}
                  onChange={(on) => {
                    this.setState({ timeModified: on });
                  }}
                />
              </div>
              <br />
            </div>
          )}
        </ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    interpreters: state.interpreters,
    filteredInterpreters: state.filteredInterpreters,
    isMobile: state.device.isMobile,
  };
};

export default connect(mapStateToProps, { setInterpreters })(PageCalendar);
