import {
  SEARCH_INTERPRETERS,
  SET_FILTERED_INTERPRETERS_TO_INITIAL_STATE,
  SET_SEARCH_TERM,
} from 'actions/types';

const initialState = {
  interpreters: [],
  isSearchTerm: false,
};

const searchInterpretersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_INTERPRETERS:
      return { ...state, interpreters: payload };
    case SET_SEARCH_TERM:
      return { ...state, isSearchTerm: payload };
    case SET_FILTERED_INTERPRETERS_TO_INITIAL_STATE:
      return initialState;
    default:
      return state;
  }
};

export default searchInterpretersReducer;
