import React, { Component } from 'react';
import { connect } from 'react-redux';

import ContentContainer from 'components/ContentContainer/content-container.js';
import AnalyticsContainer from 'components/AnalyticsContainer/analytics-container';

class PageLogs extends Component {
  getContent = () => {
    const { organizationId, isAdministrator, interpreterId } = this.props;

    if (isAdministrator) {
      return <AnalyticsContainer />;
    } else if (organizationId) {
      return <AnalyticsContainer organizationId={organizationId} />;
    } else if (interpreterId) {
      return <AnalyticsContainer interpreterId={interpreterId} />;
    } else {
      return null;
    }
  };

  render() {
    return (
      <div>
        <ContentContainer>{this.getContent()}</ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizationId: state.auth.user.organizationId,
    isAdministrator: state.auth.user.isAdministrator,
    interpreterId: state.auth.user.interpreterId,
  };
};

export default connect(mapStateToProps)(PageLogs);
