import { fService } from '../services/FService.js';
/*import * as firebase from 'firebase';
import 'firebase/firestore/dist/index.cjs';*/
import firebase from '../config/firebaseConfig';
import { arrayToObject } from '../utils/Helper';
import guid from '../utils/Uuid.js';
import sha1 from 'sha1';

export default class Organization {
  constructor(id, name, login, priceClasses, locked, admins) {
    this.name = name;
    this.login = login;
    this.priceClasses = priceClasses;
    this.id = id;
    this.locked = locked;
    this.admins = admins;
  }

  getAdminArray(callback, onError) {
    var array = [];
    const adminRef = fService.REF_ORGANIZATIONS.doc(this.id)
      .collection('settings')
      .doc('administrators');

    adminRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const data = doc.data();

          var array = [];
          if (data)
            array = Object.keys(data).map(function (key) {
              return key;
            });
          callback(array);
        } else {
          // doc.data() will be undefined in this case
          onError('No such document!');
        }
      })
      .catch(function (error) {
        onError(error);
      });

    if (this.admins) {
      array = Object.keys(this.admins).map(function (key) {
        return key;
      });
    }
    return array;
  }

  static resetPassword(id, password, callback, onError) {
    const ref = fService.REF_ORGANIZATIONS.doc(id)
      .collection('settings')
      .doc('password');
    ref
      .set({
        value: sha1(password),
      })
      .then(function () {
        callback();
      })
      .catch((error) => {
        onError('Error creating organization: ' + error);
      });
  }

  static createNewOrganization(
    name,
    login,
    priceClasses,
    admins,
    callback,
    onError,
  ) {
    var password = '';
    var i;
    for (i = 0; i < 6; i++) {
      password += Math.floor(Math.random() * 10) + 0;
    }

    var sha1password = sha1(password);

    const uuid = guid();

    const db = firebase.firestore();
    const ref = fService.REF_ORGANIZATIONS.doc(uuid);
    const adminData = arrayToObject(admins);

    // ----------

    // Get a new write batch
    var batch = db.batch();

    batch.set(ref, {
      name,
      login,
      priceClasses,
    });

    var psRef = ref.collection('settings').doc('password');
    batch.set(psRef, {
      value: sha1password,
    });

    var adminsRef = ref.collection('settings').doc('administrators');
    batch.set(adminsRef, adminData);

    // Commit the batch
    batch
      .commit()
      .then(function () {
        const data = {
          login,
          password,
        };
        callback(data);
      })
      .catch((error) => {
        onError('Error creating organization: ' + error);
      });

    // --------

    // ref.add({
    //    admins: adminArray,
    //    name,
    //    login
    // })
    // .then(function(ref) {
    //       callback(ref)
    // })
    // .catch(function(error) {
    //    onError("Error writing document: " + error)
    // });
  }

  updateOrganizationData(name, login, priceClasses, admins, callback, onError) {
    const ref = fService.REF_ORGANIZATIONS.doc(this.id);
    const adminData = arrayToObject(admins);

    var batch = firebase.firestore().batch();

    batch.update(ref, {
      name,
      login,
      priceClasses,
    });

    var adminsRef = ref.collection('settings').doc('administrators');
    batch.set(adminsRef, adminData);

    batch
      .commit()
      .then(function () {
        callback();
      })
      .catch((error) => {
        onError('Error updating organization: ' + error);
      });
  }

  static organizationFromDocument(doc) {
    const data = doc.data();
    //  const timestamp = data.date_created
    //  const date = timestamp.toDate()
    const newOrganization = new Organization(
      doc.id,
      data.name,
      data.login,
      data.priceClasses,
      data.locked,
    );
    return newOrganization;
  }

  static getOrganizations(callback, onError) {
    var organizations = [];
    const organizationRef = fService.REF_ORGANIZATIONS;

    organizationRef
      .orderBy('name')
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          const newOrganization = Organization.organizationFromDocument(doc);
          organizations.push(newOrganization);
        });
        callback(organizations);
      })
      .catch((error) => {
        const message = 'Error getting documents: ' + error;
        onError(message);
      });
  }

  static resetPassword;

  static getOrganization(id, callback, onError) {
    const ref = fService.REF_ORGANIZATIONS.doc(id);
    ref
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const organization = Organization.organizationFromDocument(doc);
          callback(organization);
        } else {
          // doc.data() will be undefined in this case
          onError('No such document!');
        }
      })
      .catch(function (error) {
        onError(error);
      });
  }

  static checkIfOrganizationAdministrator(id, email, callback, onError) {
    const ref = fService.REF_ORGANIZATIONS.doc(id)
      .collection('settings')
      .doc('administrators');
    ref
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const data = doc.data();
          if (data[email]) {
            callback(true);
          } else {
            callback(false);
          }
        } else {
          // doc.data() will be undefined in this case
          onError('No such document!');
        }
      })
      .catch(function (error) {
        onError(error);
      });
  }

  lock(callback, onError) {
    const ref = fService.REF_ORGANIZATIONS.doc(this.id);
    var locked = true;
    if (this.locked && locked === true) {
      locked = false;
    }
    ref
      .update({
        locked: locked,
      })
      .then(function () {
        callback();
      })
      .catch(function (error) {
        onError('Error updating document: ' + error);
      });
  }

  static getOrganizationName(id, callback, onError) {
    const ref = fService.REF_ORGANIZATIONS.doc(id);
    ref
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const data = doc.data();
          const name = data.name;
          callback(name);
        } else {
          // doc.data() will be undefined in this case
          onError('No such document!');
        }
      })
      .catch(function (error) {
        onError(error);
      });
  }
}
