import React, { Component } from 'react';

// firebase
import Log from 'models/log';
import User from 'models/user';
import Interpreter from 'models/interpreter';
import Organization from 'models/organization';
import { getLanguageDictionary } from 'services/languages-service';

// redux
import { connect } from 'react-redux';
import { setUserName } from 'actions/setUserName';
import { setInterpreterName } from 'actions/setInterpreterName';
import { setOrganizationName } from 'actions/setOrganizationName';

// components
import Input, { ValueToState } from 'components/Input/input';
import Button from 'components/Button/button';

import './log-details.css';

class LogDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      log: this.props.log ? this.props.log : null,
      editing: false,
      correctedDuration: null,
      isLoading: false,
      inputError: null,
      refresh: false,
    };

    this.languages = getLanguageDictionary();
  }

  componentWillUnmount() {
    if (this.state.refresh) {
      window.location.reload();
    }
  }

  secondaryBtnPressed() {
    this.setState({ editing: !this.state.editing });
  }

  actionPressed() {
    if (this.state.editing) {
      this.setState({ isLoading: true });

      const newDuration = this.state.correctedDuration.trim();

      // VALIDATION (numbers)
      if (!newDuration || newDuration === '') {
        this.setState({ isLoading: false, inputError: 'Vaaditaan' });
        return;
      }

      const number = Number(newDuration);

      if (
        this.props.log &&
        this.state.correctedDuration &&
        this.state.correctedDuration !== ''
      ) {
        // Update to firebase
        this.props.log.updateDuration(
          number,
          () => {
            const newLog = new Log();
            const updateLog = Object.assign(newLog, this.props.log);
            updateLog.duration = this.state.correctedDuration;
            this.setState({
              log: updateLog,
              editing: false,
              correctedDuration: null,
              isLoading: false,
              refresh: true,
            }); // KORJAA - Ei päivitä mitän front endissä

            this.props.logUpdated(updateLog.id, updateLog.duration);
          },
          (error) => {
            console.log(error);
          },
        );
      }
    } else {
      this.props.closePressed();
    }
  }

  render() {
    const { log, isLoading, inputError } = this.state;
    const { isAdministrator, isOrganizationAdmin } = this.props;

    return (
      <div>
        {log && (
          <table className="logs-table">
            <tbody>
              {log.error && (
                <tr className="error-detils-row">
                  <th>Virhe</th> <td>{log.getError()}</td>
                </tr>
              )}
              {log.userReport && (
                <tr className="error-detils-row">
                  <th>Käyttäjän viesti:</th> <td>{log.userReport}</td>
                </tr>
              )}
              <tr>
                <th>Päivämäärä</th> <td>{log.getDateString()}</td>
              </tr>
              <tr>
                <th>Kellonaika</th> <td>{log.getTimeString()}</td>
              </tr>
              {isAdministrator && (
                <tr>
                  <th>Organisaatio</th>{' '}
                  <td>{this.getOrganizationName(log.organizationId)}</td>
                </tr>
              )}
              {isOrganizationAdmin && (
                <tr>
                  <th>Henkilö</th> <td>{this.getUserName(log.userId)}</td>
                </tr>
              )}
              {isAdministrator && (
                <tr>
                  <th>Tulkki</th>{' '}
                  <td>{this.getInterpreterName(log.interpreterId)}</td>
                </tr>
              )}
              <tr>
                <th>Kielestä</th>{' '}
                <td>
                  {this.languages[log.languageFrom] +
                    ' (' +
                    log.languageFrom +
                    ')'}
                </td>
              </tr>
              <tr>
                <th>Kielelle</th>{' '}
                <td>
                  {this.languages[log.languageTo] + ' (' + log.languageTo + ')'}
                </td>
              </tr>
              <tr>
                <th>Kesto (sek)</th>
                {this.state.editing ? (
                  <Input
                    placeholder={log.duration}
                    valueToState={new ValueToState(this, 'correctedDuration')}
                    disabled={isLoading}
                    required
                    errorMessage={inputError}
                    regex="^[0-9]*$"
                  />
                ) : (
                  <td>{log.duration}</td>
                )}
              </tr>
              <tr>
                <th>Laskutettava kesto (min)</th>{' '}
                <td>{log.getCorrectedDurationInMinutes()}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="log-details-button-row">
          {isAdministrator && (
            <Button
              styleType="secondary"
              round
              title={this.state.editing ? 'Peruuta' : 'Muokkaa'}
              onClick={this.secondaryBtnPressed.bind(this)}
              disabled={isLoading}
            />
          )}

          {this.state.editing &&
            isAdministrator &&
            log.error === 'user_report' && (
              <Button
                round
                title="Poista virhe"
                styleType={Button.StyleType.DANGER}
                onClick={() => {
                  this.setState({ isLoading: true });
                  log.removeError(
                    () => {
                      const newLog = new Log();
                      const updatedLog = Object.assign(newLog, this.props.log);
                      updatedLog.error = undefined;
                      this.setState({
                        isLoading: false,
                        editing: false,
                        log: updatedLog,
                        refresh: true,
                      });
                      this.props.logUpdated(updatedLog.id, null, true);
                    },
                    (error) => {
                      this.setState({ isLoading: false });
                      error.log(error);
                    },
                  );
                }}
                disabled={isLoading}
              />
            )}

          <Button
            round
            title={this.state.editing ? 'Tallenna' : 'Sulje'}
            onClick={this.actionPressed.bind(this)}
            disabled={isLoading}
          />
        </div>
      </div>
    );
  }

  getOrganizationName(id) {
    if (!id) return '-';

    if (this.props.organizationNames[id]) {
      return this.props.organizationNames[id];
    } else {
      Organization.getOrganizationName(
        id,
        (name) => {
          this.props.setOrganizationName(name, id);
        },
        (error) => {
          console.log(error);
        },
      );
      return '';
    }
  }

  getInterpreterName(id) {
    if (!id) return '-';

    if (this.props.interpreterNames[id]) {
      return this.props.interpreterNames[id];
    } else {
      Interpreter.getInterpreterName(
        id,
        (name) => {
          this.props.setInterpreterName(name, id);
        },
        (error) => {
          console.log(error);
        },
      );
      return '';
    }
  }

  getUserName(id) {
    if (!id) return '-';

    if (this.props.userNames[id]) {
      return this.props.userNames[id];
    } else {
      User.getUser(
        id,
        (user) => {
          const name = user.firstName + ' ' + user.lastName;
          this.props.setUserName(name, id);
        },
        (error) => {
          console.log(error);
        },
      );
      return '';
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAdministrator: state.auth.user.isAdministrator,
    isOrganizationAdmin: state.auth.user.isOrganizationAdmin,
    organizationNames: state.organizationNames,
    interpreterNames: state.interpreterNames,
    userNames: state.userNames,
  };
};

export default connect(mapStateToProps, {
  setInterpreterName,
  setOrganizationName,
  setUserName,
})(LogDetails);
