import { ORGANIZATIONS_RECEIVED } from "../actions/types";


const initialState = []

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATIONS_RECEIVED:
      return action.payload.organizations
    default:
      break
  }
  return state;
}

export default organizationReducer;
