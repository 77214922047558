import {
  SEARCH_INTERPRETERS,
  SET_SEARCH_TERM,
  SET_FILTERED_INTERPRETERS_TO_INITIAL_STATE,
} from './types';

export const setFilteredInterpreters = (payload) => {
  return {
    type: SEARCH_INTERPRETERS,
    payload,
  };
};

export const setSearchTermForInterpreter = (payload) => {
  return {
    type: SET_SEARCH_TERM,
    payload,
  };
};

export const setFilteredInterpretersToInitialState = () => {
  return { type: SET_FILTERED_INTERPRETERS_TO_INITIAL_STATE };
};
