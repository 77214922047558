import React, { Component } from 'react';
import { dateToString, getLanguageArray, stringToDate } from 'utils/Helper';
import languagesJson from 'language-codes.json';
import { format } from 'date-fns';
import moment from 'moment';

// firebase
import InterpretationRequest from 'models/interpretation-request';
import CalendarEvent from 'models/calendar-event';

// components
import ContainerBox from 'components/ContainerBox/container-box';
import Button from 'components/Button/button';
import NewEventBox from 'components/PageCalendar/NewEventBox/new-event-box';
import Toggle from 'components/Toggle/toggle';
import ContentContainer from 'components/ContentContainer/content-container';
import Dialog from 'components/Dialog/dialog';
import LoadingSpinner from 'components/LoadingSpinner/loading-spinner';

import './page-requests.css';

export default class PageRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      requestToCalendar: undefined,
      requestToDelete: undefined,
      requestToUpdate: undefined,
      isLoading: false,
      hideHandledRequests: true,
      searchTerm: '',
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    InterpretationRequest.getAll((err, requests) => {
      if (err) console.error(err);
      else if (requests) {
        // console.log(requests);
        this.setState({ requests, isLoading: false });
      }
    });
  }

  handleOnChange = (event) => {
    this.setState({ searchTerm: event.target.value.toLowerCase() });
  };

  render() {
    const {
      requests,
      requestToCalendar,
      isLoading,
      hideHandledRequests,
      requestToDelete,
      requestToUpdate,
      searchTerm,
    } = this.state;

    let filteredRequests;

    if (searchTerm) {
      const possibleDateFormats = [
        'DD-MM-YYYY',
        'DD.MM.YYYY',
        'DD/MM/YYYY',
        'DD-M-YYYY',
        'DD.M.YYYY',
        'DD/M/YYYY',
        'D-MM-YYYY',
        'D.MM.YYYY',
        'D/MM/YYYY',
        'D-M-YYYY',
        'D.M.YYYY',
        'D/M/YYYY',
      ];
      const requiredDateFormat = 'dd.MM.yyyy';

      const isDateValid = moment(
        searchTerm,
        possibleDateFormats,
        true,
      ).isValid();

      let inputDate = null;

      if (isDateValid) {
        const delimiters = ['.', '-', '/'];
        if (searchTerm.includes(delimiters[0])) {
          inputDate = stringToDate(searchTerm, delimiters[0]);
        } else if (searchTerm.includes(delimiters[1])) {
          inputDate = stringToDate(searchTerm, delimiters[1]);
        } else if (searchTerm.includes(delimiters[2])) {
          inputDate = stringToDate(searchTerm, delimiters[2]);
        }
      }

      const formattedInputDate = inputDate
        ? format(inputDate, requiredDateFormat)
        : null;

      // console.log(formattedInputDate);

      filteredRequests = requests.filter((el) => {
        const ordererEmail = el.ordererEmail
          ? el.ordererEmail.toLowerCase()
          : '';
        const ordererName = el.ordererName ? el.ordererName.toLowerCase() : '';
        const ordererPhone = el.ordererPhone
          ? el.ordererPhone.toLowerCase()
          : '';
        const notes = el.notes ? el.notes.toLowerCase() : '';
        const billingInfo = el.billingInfo ? el.billingInfo.toLowerCase() : '';
        const organization = el.organization
          ? typeof el.organization === 'string'
            ? el.organization
            : el.organization.toString()
          : '';
        const type = el.type ? el.type.toLowerCase() : '';
        const customerName = el.customerName
          ? el.customerName.toLowerCase()
          : '';
        const location = el.location ? el.location.toLowerCase() : '';
        const startDate =
          typeof el.startTime !== 'undefined' &&
          format(el.startTime, requiredDateFormat);
        const shippedDate =
          el.timestamp && format(el.timestamp, requiredDateFormat);
        // console.log(shippedDate);
        // console.log(startDate);
        // console.log(inputDate);

        return (
          ordererEmail.includes(searchTerm) ||
          ordererName.includes(searchTerm) ||
          ordererPhone.includes(searchTerm) ||
          customerName.includes(searchTerm) ||
          location.includes(searchTerm) ||
          organization === searchTerm ||
          type.includes(searchTerm) ||
          notes.includes(searchTerm) ||
          billingInfo.includes(searchTerm) ||
          (el.duration && el.duration === +searchTerm) ||
          getLanguageArray([el.language]).some((lan) =>
            lan.toLowerCase().includes(searchTerm),
          ) ||
          (startDate &&
            formattedInputDate &&
            startDate === formattedInputDate) ||
          (shippedDate &&
            formattedInputDate &&
            shippedDate === formattedInputDate)
        );
      });
    } else {
      filteredRequests = [...requests];
    }

    const newRequests = filteredRequests.filter(
      (request) => request.status !== 'handled',
    );
    const handledRequests = filteredRequests.filter(
      (request) => request.status === 'handled',
    );

    return (
      <div>
        <ContentContainer>
          <div>
            <h3 style={{ marginBottom: '16px' }}>Uudet tilaukset</h3>
            <div className="custom-input__container">
              <input
                type="text"
                className="custom-input__input"
                placeholder={`etsi tilauksia`}
                onChange={this.handleOnChange}
              />
            </div>
            <small className="info-text" style={{ marginBottom: '-1px' }}>
              {`*Hakeaksesi päivämäärällä kirjoita haku muotoon pp.kk.vvvv`}{' '}
            </small>
            <small className="info-text mb-3">
              {`*esim. "6.8.2021" tai "06.10.2021" tai "06/08/2021" tai "6-08-2021"`}{' '}
            </small>

            {isLoading && <LoadingSpinner />}
            {newRequests.length === 0 && !isLoading && (
              <p>Ei uusia tilauksia</p>
            )}
            {newRequests.map((request) => (
              <RequestContainer
                key={request.id}
                request={request}
                onMarkHandled={() => {
                  this.setState({ requestToUpdate: request });
                }}
                onAddToCalendar={() =>
                  this.setState({ requestToCalendar: request })
                }
              />
            ))}
          </div>

          <div>
            <br />
            <div
              className="flex-row"
              style={{ marginBottom: '16px', alignItems: 'center' }}
            >
              <h3 style={{ marginRight: '20px' }}>Käsitellyt tilaukset</h3>
              <p style={{ margin: 0, marginRight: '12px' }}>Piilota</p>
              <Toggle
                toggleId="hide-handled-requests-toggle"
                isOn={hideHandledRequests}
                onChange={(on) => {
                  this.setState({ hideHandledRequests: on });
                }}
              />
            </div>
            {!hideHandledRequests && (
              <div>
                {handledRequests.length === 0 && !isLoading && (
                  <p>Ei käsiteltyjä tilauksia</p>
                )}
                {handledRequests.map((request) => (
                  <RequestContainer
                    key={request.id}
                    request={request}
                    onDelete={() => this.setState({ requestToDelete: request })}
                  />
                ))}
              </div>
            )}
          </div>
        </ContentContainer>

        {/* DIALOGS */}
        {requestToCalendar && (
          <NewEventBox
            //event={selectedEvent}
            interpretationRequest={requestToCalendar}
            onEventCreated={() => {
              requestToCalendar
                .markHandled()
                .then(() => {
                  this.setState({ requestToCalendar: undefined });
                })
                .catch((err) => {
                  console.error(err);
                  this.setState({ requestToCalendar: undefined });
                });
            }}
            onClose={() => this.setState({ requestToCalendar: undefined })}
          />
        )}

        {requestToDelete && (
          <Dialog
            title="Poista tilaus!"
            message="Poistettua tilausta ei voi enää palauttaa"
            primaryBtnTitle="Poista"
            destructive={true}
            cancelPressed={() => {
              this.setState({ requestToDelete: undefined });
            }}
            confirmPressed={() => {
              requestToDelete
                .delete()
                .then(() => this.setState({ requestToDelete: undefined }))
                .catch((err) => {
                  console.error(err);
                  this.setState({ requestToDelete: undefined });
                });
            }}
          />
        )}

        {requestToUpdate && (
          <Dialog
            title="Siirrä käsiteltyihin"
            confirmPressed={() => {
              requestToUpdate
                .markHandled()
                .then(() => {
                  this.setState({ requestToUpdate: undefined });
                })
                .catch((err) => {
                  console.error(err);
                  this.setState({ requestToUpdate: undefined });
                });
            }}
            cancelPressed={() => {
              this.setState({ requestToUpdate: undefined });
            }}
          />
        )}
      </div>
    );
  }
}

class RequestContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadUrl: undefined,
    };
  }

  componentDidMount() {
    const { request } = this.props;
    if (request.attachment) {
      request
        .downloadAttachment()
        .then((url) => this.setState({ downloadUrl: url }))
        .catch((err) => {
          console.error('Failed to get download url');
        });
    }
  }

  render() {
    const { request, onAddToCalendar, onDelete, onMarkHandled } = this.props;
    const { downloadUrl } = this.state;

    const isHandled = request.status === 'handled';

    const minToHours = (x) => {
      const minutes = x % 60;
      const hours = (x - minutes) / 60;
      let timeString = '';
      if (hours > 0) {
        timeString = hours + ' t';
      }
      if (minutes > 0) {
        timeString += ' ' + minutes + ' min';
      }
      return timeString;
    };

    const getLanguageString = (code) => {
      const language = languagesJson.find((lang) => lang.code === code);
      if (language && language.fi) {
        return language.fi;
      } else {
        return code;
      }
    };

    return (
      <ContainerBox>
        <h4 style={{ fontWeight: 300 }}>Tilaus</h4>
        <InfoRow title="Kieli" value={getLanguageString(request.language)} />
        <InfoRow
          title="Tyyppi"
          value={CalendarEvent.getEventType(request.type)}
        />
        {request.startTime && (
          <InfoRow
            title="Ajankohta"
            value={dateToString(request.startTime, true, true)}
          />
        )}
        {request.duration && (
          <InfoRow
            title="Arvioitu Kesto"
            value={minToHours(request.duration)}
          />
        )}
        {request.location && (
          <InfoRow title="Sijainti" value={request.location} />
        )}
        {request.customerName && (
          <InfoRow title="Tulkattavan nimi" value={request.customerName} />
        )}
        {request.notes && <InfoRow title="Lisätietoja" value={request.notes} />}
        {downloadUrl && (
          <div>
            <a
              href={downloadUrl}
              download
              style={{
                color: '#01213D',
                marginLeft: 0,
                paddingLeft: 0,
                textDecoration: 'underline',
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Lataa liite
            </a>
            <br />
          </div>
        )}

        <br />
        <h4 style={{ fontWeight: 300 }}>Tilaajan Tiedot</h4>
        <InfoRow title="Organisaatio" value={request.organization} />
        <InfoRow title="Nimi" value={request.ordererName} />
        <InfoRow title="Sähköposti" value={request.ordererEmail} />
        {request.ordererPhone && (
          <InfoRow title="Puhelinnumero" value={request.ordererPhone} />
        )}
        {request.billingInfo && (
          <InfoRow title="Laskutustiedot" value={request.billingInfo} />
        )}
        <InfoRow
          title="Tilaus lähetetty"
          value={dateToString(request.timestamp, true, true)}
        />

        <div>
          <div
            className="flex-row"
            style={{ width: '100%', justifyContent: 'flex-end' }}
          >
            {isHandled ? (
              <Button
                title="Poista"
                styleType={Button.StyleType.DANGER}
                round={true}
                onClick={onDelete}
              />
            ) : (
              <span>
                <Button
                  title="Käsitelty"
                  round={true}
                  onClick={onMarkHandled}
                  styleType={Button.StyleType.SECONDARY}
                />
                <Button
                  title="Kalenteriin"
                  round={true}
                  onClick={onAddToCalendar}
                />
              </span>
            )}
          </div>
        </div>
      </ContainerBox>
    );
  }
}

const InfoRow = (props) => {
  const { title, value } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '6px' }}>
      <p style={{ margin: 0, minWidth: '140px' }}>
        <strong>{title + ':'}</strong>
      </p>
      <p style={{ margin: 0 }}>{value}</p>
    </div>
  );
};
