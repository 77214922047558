import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import './multi-select-dropdown.css';
import { getLanguageOptions } from 'services/languages-service';

export default class MultiSelectDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value ? this.props.value : [],
    };

    this.options = this.props.options || getLanguageOptions(); // if options not defined -> use for languages

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(value) {
    this.setState({ value }, () => {
      var val = value.split(',');
      this.props.valueChanged(val);
    });
  }

  render() {
    const { value } = this.state;
    const { placeholder, title } = this.props;
    // const value = selectedOption && selectedOption.value;

    return (
      <div className="dropdown-container">
        {title && <p>{title}</p>}

        <Select
          className="multi-select-dropdown"
          name="multi-select-field"
          closeOnSelect={true}
          disabled={this.props.disabled}
          multi
          onChange={this.handleSelectChange}
          options={this.options}
          placeholder={placeholder ? placeholder : ''}
          removeSelected={this.state.removeSelected}
          simpleValue
          value={value}
        />
      </div>
    );
  }
}
