import React, {Component} from 'react';
import Input, {ValueToState} from '../Input/input'
import ContainerBox from '../ContainerBox/container-box';
import Button from '../Button/button'
import jquery from 'jquery'
import {changePassword} from '../../services/auth-service'


export default class ChangePasswordForm extends Component {

  constructor(props) {
    super(props);

   this.state={
      password: null,
      previous: null,
      repeated: null,
      isLoading: false,
      inputErrors: {},
      success: false,
   }

   this.setPassword = this.setPassword.bind(this)

  }

  validate() {
   var inputErrors = {}
    
    if (!this.state.password || (this.state.password === "")) {
        inputErrors.password = "Vaaditaan"
    }

    if (!this.state.repeated || (this.state.repeated === "")) {
      inputErrors.repeated = "Vaaditaan"
    }

    if (!this.state.previous || (this.state.previous === "")) {
      inputErrors.previous = "Vaaditaan"
    }

    this.setState({ inputErrors })

    return jquery.isEmptyObject(inputErrors) // inputErrors.isEmpty()
  }


  setPassword() {

   this.setState({isLoading: true})
   
   //validate
   const valid = this.validate()
   if (!valid) {
      this.setState({isLoading: false})
      return
   }

   if (this.state.password !== this.state.repeated) {
      this.setState({isLoading: false, errorMessage: "Salasanat eivät täsmää"})
      return
   }

   changePassword(this.state.password, this.state.previous, () => {
      this.setState({isLoading: false, success: true})
   }, (error) => {
      this.setState({isLoading: false})

      if (error.code === "auth/wrong-password") {
         this.setState({errorMessage: "Tarkasta vanha salasana"})
      } else if (error.code === "auth/weak-password") {
         this.setState({errorMessage: "Liian heikko salasana"})
      } else {
         this.setState({errorMessage: "Jotain meni pieleen"})
      }
      console.log(error)
   })


  }


  render() {

   const { isLoading, inputErrors, errorMessage, success } = this.state

   return (
      <ContainerBox>

         {!success ? (
         <div>
            <Input required bold 
               type="password"
               title='Vanha salasana'
               valueToState={new ValueToState(this, 'previous')}
               disabled={isLoading}
               errorMessage={ inputErrors && inputErrors.previous }
            />
            
            <Input required bold 
               type="password"
               title='Uusi Salasana'
               valueToState={new ValueToState(this, 'password')}
               disabled={isLoading}
               errorMessage={ inputErrors && inputErrors.password }
            />

            <Input required bold
               type="password"
               title='Toista uusi salasana'
               valueToState={new ValueToState(this, 'repeated')}
               disabled={isLoading}
               errorMessage={ inputErrors && inputErrors.repeated }
            />

         {errorMessage &&
            <p className="error-txt">{errorMessage}</p>
         }

         <div className="button-row">
            <Button styleType="secondary" round title="Peruuta" onClick={this.props.cancelPressed} disabled={isLoading}/>
            <Button round title="Vahvista" onClick={this.setPassword} disabled={isLoading}/>
         </div>
         </div>
         ): (
            <div>
               <h3>Salasana vaihdettu</h3>
               <br/>
               <Button round title="Valmis" onClick={this.props.cancelPressed}/>
            </div>
         )}
        

      </ContainerBox>
   )

  }


}
