import React, { Component } from 'react';
import { getPriceClassOptions } from 'services/languages-service';
import Organization from 'models/organization';
import { removeObjectFromArray } from 'utils/Helper';

// components
import MultiSelectDropdown from 'components/MultiSelectDropdown/multi-select-dropdown.js';
import LoadingSpinner from 'components/LoadingSpinner/loading-spinner.js';
import RemovableListItem from 'components/RemovableListItem/removable-list-item';
import Input, { ValueToState } from 'components/Input/input.js';
import Button from 'components/Button/button.js';

import jquery from 'jquery';
import './new-organization.css';

// mehedi - unused imports. May be deleted later.
// import BorderlessButton from '../../BorderlessButton/borderless-button.js';
// import {getInterpreterClassOptions, getPriceClassOptions} from '../../../services/languages-service'
// import SingleSelectDropdown from '../../SingleSelectDropdown/single-select-dropdown.js';
// import "firebase/firestore";

export default class NewOrganization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.organization && this.props.organization.name,
      login: this.props.organization && this.props.organization.login,
      priceClasses:
        this.props.organization && this.props.organization.priceClasses,
      admins: [], //(this.props.organization && this.props.organization.admins) ? this.props.organization.getAdminArray() : [],
      newAdmin: '',
      tempPassword: null,
      adminsFetched: false,
      inputErrors: {},
      isLoading: false,
      errorMessage: null,
    };

    this.savePressed = this.savePressed.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    if (this.props.organization) {
      this.props.organization.getAdminArray(
        (admins) => {
          this.setState({ admins, adminsFetched: true });
        },
        (error) => {
          console.log(error);
          this.setState({ adminsFetched: true });
        },
      );
    }
  }

  //   clearAdminForm() {
  //     this.inputfirstName.value = null
  //     this.inputlastName.value = null
  //     this.inputPhoneNumber.value = null
  //   }

  addNewAdmin() {
    if (this.state.newAdmin) {
      const admins = [...this.state.admins];
      admins.indexOf(this.state.newAdmin) === -1
        ? admins.push(this.state.newAdmin)
        : console.log('This item already exists');
      this.setState({ newAdmin: '', admins }, () => {
        // KORJAA input pitää clear
      });
    }
  }

  validate() {
    var inputErrors = {};

    // mehedi - redundant or condition check.
    // !this.state.name || this.state.name === ''
    if (!this.state.name) {
      inputErrors.name = 'Vaaditaan';
    }

    // mehedi - redundant or condition check.
    // !this.state.login || this.state.login === ''
    if (!this.state.login) {
      inputErrors.login = 'Vaaditaan';
    }

    this.setState({ inputErrors });

    return jquery.isEmptyObject(inputErrors); // inputErrors.isEmpty()
  }

  savePressed() {
    this.setState({ isLoading: true });

    // VALIDATION!!!!!!!!!!!
    const valid = this.validate();
    if (!valid) {
      this.setState({ isLoading: false });
      return;
    }

    if (this.props.organization) {
      // EDIT EXISTING
      this.props.organization.updateOrganizationData(
        this.state.name,
        this.state.login,
        this.state.priceClasses,
        this.state.admins,
        () => {
          this.setState({ isLoading: false, errorMessage: null });
          this.props.organizationUpdated();
        },
        (error) => {
          this.setState({
            isLoading: false,
            errorMessage: 'Jotain meni pieleen',
          });
          console.log(error);
        },
      );
    } else {
      // CREATE NEW
      Organization.createNewOrganization(
        this.state.name,
        this.state.login,
        this.state.priceClasses,
        this.state.admins,
        (data) => {
          this.props.oraganizationCreated();
          this.setState({
            tempPassword: data.password,
            login: data.login,
            isLoading: false,
            errorMessage: null,
          });
        },
        (error) => {
          console.log(error);
          this.setState({
            isLoading: false,
            errorMessage: 'Jotain meni pieleen',
          });
        },
      );
    }
  }

  removeAdmin(admin) {
    const admins = removeObjectFromArray([...this.state.admins], admin);
    this.setState({ admins });
  }

  render() {
    const { tempPassword, inputErrors, isLoading, errorMessage } = this.state;

    return (
      <div className="new-organization-box">
        {this.props.organization ? (
          <h3>Muokkaa tietoja</h3>
        ) : (
          <h3>{tempPassword ? 'Uusi asiakas luotu' : 'Luo uusi'}</h3>
        )}

        {tempPassword ? (
          <div>
            <section>
              <div>
                <h5 className="font-light alert-txt">
                  Ota salasana ja käyttäjätunnus ylös! Et näe niitä enää tämän
                  jälkeen.
                </h5>
                <br />
                <h5 className="font-bold">{this.state.name}</h5>
                <p className="no-margin">Käyttäjätunnus: {this.state.login}</p>
                <p>Salasana: {tempPassword}</p>
              </div>
            </section>

            <div className="button-row">
              <Button round title="Valmis" onClick={this.props.cancelPressed} />
            </div>
          </div>
        ) : (
          <div>
            {(this.props.organization && this.state.adminsFetched) ||
            !this.props.organization ? (
              <div>
                <Input
                  required
                  bold
                  title="Nimi"
                  errorMessage={inputErrors && inputErrors.name}
                  valueToState={new ValueToState(this, 'name')}
                  disabled={isLoading}
                />

                <Input
                  required
                  bold
                  title="Käyttäjätunnus"
                  errorMessage={inputErrors && inputErrors.login}
                  valueToState={new ValueToState(this, 'login')}
                  disabled={isLoading}
                />

                <MultiSelectDropdown
                  title={'Luokat'}
                  disabled={isLoading}
                  value={this.state.priceClasses}
                  options={getPriceClassOptions()}
                  valueChanged={(priceClasses) => {
                    this.setState({ priceClasses });
                  }}
                />

                <Input
                  bold
                  className="new-admin-input"
                  title="Lisää admin käyttäjä"
                  value={this.state.newAdmin}
                  valueToState={new ValueToState(this, 'newAdmin')}
                  disabled={isLoading}
                />

                <button
                  className="new-admin-button"
                  onClick={this.addNewAdmin.bind(this)}
                >
                  Lisää
                </button>

                {this.state.admins.length > 0 && (
                  <div>
                    <p className="placeholder-title-123">Admin käyttäjät:</p>

                    {this.state.admins.map((admin, index) => (
                      <RemovableListItem
                        key={admin}
                        title={admin}
                        index={index}
                        removePressed={this.removeAdmin.bind(this)}
                      />
                    ))}
                  </div>
                )}

                {errorMessage && <p className="error-txt">{errorMessage}</p>}

                <div className="button-row">
                  <Button
                    styleType="secondary"
                    round
                    title="Peruuta"
                    onClick={this.props.cancelPressed}
                    disabled={isLoading}
                  />
                  <Button
                    round
                    title="Tallenna"
                    onClick={this.savePressed}
                    disabled={isLoading}
                  />
                </div>
              </div>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        )}
      </div>
    );
  }
}
