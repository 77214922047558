import * as dateFns from 'date-fns';
import { getAllLanguages } from '../services/languages-service'; // as formatDate from 'date-fns/format'

export function getObjectById(array, id) {
  const result = array.find((obj) => {
    return obj.id === id;
  });
  return result;
}

export function removeObjectFromArray(array, obj) {
  const index = array.indexOf(obj);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

export function arrayToObject(arr) {
  var rv = {};
  for (var i = 0; i < arr.length; ++i)
    if (arr[i] !== undefined) rv[arr[i]] = true;
  return rv;
}

//TODO: Onko sama kuin trim()
export function removeLastCharacters(str, number) {
  if (str != null && str.length > number) {
    str = str.substring(0, str.length - number);
  }
  return str;
}

export function dateToString(date, showDate, showTime) {
  if (!date) {
    console.error('Date not found');
    return '';
  }
  if (showDate && showTime) {
    return dateFns.format(date, "dd.MM.yyyy 'klo.' HH:mm");
  } else if (showTime) {
    return dateFns.format(date, 'HH:mm');
  } else {
    return dateFns.format(date, 'dd.MM.yyyy');
  }
}

/*
Date format must be dd.mm.yyyy
so 1st index is date, 2nd index is month & 3rd index is year
if a date is: 09.12.2002
here date=9, month=12, year=2002
*/
export function stringToDate(_date, _delimiter) {
  const dateItems = _date.split(_delimiter);
  const day = parseInt(dateItems[0]);
  let month = parseInt(dateItems[1]);
  const year = parseInt(dateItems[2]);
  month -= 1;
  return new Date(year, month, day);
}

export function getMonthBeginning(date) {
  date.setDate(1);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date.setHours(0);
  date.setMinutes(0);
  return date;
}

export function getLanguageArray(keys) {
  return keys
    .map((el) =>
      Object.values(getAllLanguages().filter((_el) => _el.code === el)[0]),
    )
    .reduce((prev, next) => prev.concat(next));
}

// export function dateToString(date) {

// }
