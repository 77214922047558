import firebase from '../config/firebaseConfig';
import { fService } from '../services/FService.js';
import { arrayToObject } from '../utils/Helper';

export default class Interpreter {
  constructor(
    id,
    status,
    firstName,
    lastName,
    phoneNumber,
    city,
    languages,
    sex,
    priceClass,
  ) {
    this.id = id;
    this.status = status;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.city = city;
    this.languages = languages;
    this.sex = sex;
    this.priceClass = priceClass;
  }

  getLanguagesArray() {
    var array = [];
    if (this.languages) {
      array = Object.keys(this.languages).map(function (key) {
        return key;
      });
    }
    return array;
  }

  lock() {
    const ref = fService.REF_INTERPRETERS.doc(this.id);
    var data = { status: 'locked' };
    if (this.status === 'locked') {
      data = { status: 'offline' };
    }
    ref
      .update(data)
      .then(function () {
        // callback()
      })
      .catch(function (error) {
        console.log('Error updating document: ' + error);
        //onError("Error updating document: " + error)
      });
  }

  static interpreterFromDocument(doc) {
    const data = doc.data();
    // TARVITSEEKO TARKASTAA JOS JOTKUT OMINAISUUDET OVAT PAKOLLISIA?
    return new Interpreter(
      doc.id,
      data.status,
      data.firstName,
      data.lastName,
      data.phoneNumber,
      data.city,
      data.languages,
      data.sex,
      data.priceClass,
    );
  }

  static getInterpreterName(id, callback, onError) {
    const interpreterRef = fService.REF_INTERPRETERS.doc(id);

    interpreterRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const interpreter = Interpreter.interpreterFromDocument(doc);
          const name = interpreter.firstName + ' ' + interpreter.lastName;
          callback(name);
        } else {
          onError('No such document!');
        }
      })
      .catch(function (error) {
        console.log('Error getting document:', error);
      });
  }

  static getInterpreters(callback) {
    const interpretersRef = fService.REF_INTERPRETERS;

    interpretersRef.orderBy('firstName').onSnapshot(function (querySnapshot) {
      var interpreters = [];
      querySnapshot.forEach(function (doc) {
        const newInterpreter = Interpreter.interpreterFromDocument(doc);
        interpreters.push(newInterpreter);
      });
      callback(interpreters);
    });
  }

  static getInterpreter(id, callback) {
    const ref = fService.REF_INTERPRETERS.doc(id);

    ref
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const interpreter = Interpreter.interpreterFromDocument(doc);
          callback(interpreter);
        } else {
          callback();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  saveInterpreterToFirebase() {
    return new Promise((resolve, reject) => {
      const interpretersRef = fService.REF_INTERPRETERS;

      let data = {
        firstName: this.firstName,
        lastName: this.lastName,
        sex: this.sex,
        phoneNumber: this.phoneNumber,
        city: this.city,
        languages: this.languages,
        status: 'offline',
      };

      if (this.priceClass) {
        data['priceClass'] = this.priceClass;
      }

      interpretersRef
        .doc(this.id)
        .set(data)
        .then(function () {
          resolve();
        })
        .catch(function (error) {
          console.log('saveInterpreterToFirebase function called!!');
          console.error(error);
          reject(error);
        });
    });
  }

  // languages in array [fi, ru, de]
  updateInterpreterData(
    firstName,
    lastName,
    sex,
    phoneNumber,
    city,
    priceClass,
    languages,
    callback,
    onError,
  ) {
    const ref = fService.REF_INTERPRETERS.doc(this.id);
    const languagesArray = arrayToObject(languages);
    ref
      .update({
        firstName,
        lastName,
        sex,
        phoneNumber,
        city,
        priceClass: priceClass,
        languages: languagesArray,
      })
      .then(function () {
        callback();
      })
      .catch(function (error) {
        onError('Error updating document: ' + error);
      });
  }

  removeInterpreter(callback, onError) {
    const addMessage = firebase
      .functions()
      .httpsCallable('/interpreter/delete');
    //console.log(this.id)
    addMessage({ interpreter_id: this.id })
      .then(function (result) {
        callback(result);
      })
      .catch((error) => {
        console.log(error.details);
        console.log(error.code);
        onError(error);
      });
  }
}
