/*import * as firebase from 'firebase';
import 'firebase/firestore/dist/index.cjs';*/
import firebase from 'config/firebaseConfig';

export const fService = {
  get REF_BASE() {
    /*const settings = { /!* your settings... *!/ timestampsInSnapshots: true };
      firestore.settings(settings);*/
    return firebase.firestore();
  },

  get KEY_TITLE() {
    return 'title';
  },
  get KEY_DATE_CREATED() {
    return 'date_created';
  },
  get KEY_AUTHOR_DISPLAY_NAME() {
    return 'author_display_name';
  },

  get REF_ORGANIZATIONS() {
    return fService.REF_BASE.collection('organizations');
  },
  get REF_INTERPRETERS() {
    return fService.REF_BASE.collection('interpreters');
  },
  get REF_LOGS() {
    return fService.REF_BASE.collection('logs');
  },
  get REF_USERS() {
    return fService.REF_BASE.collection('users');
  },
  get REF_LANGUAGES() {
    return fService.REF_BASE.collection('settings').doc('languages');
  },

  get REF_CALENDAR_EVENTS() {
    return fService.REF_BASE.collection('calendarEvents');
  },
  get REF_INTERPRETATION_REQUESTS() {
    return fService.REF_BASE.collection('interpretationRequests');
  },
};
