import React, { Component } from 'react';

// redux
import { connect } from 'react-redux';
import {
  setFilteredInterpreters,
  setSearchTermForInterpreter,
  setFilteredInterpretersToInitialState,
} from 'actions/setFilteredInterpreters';

import './interpreter-search.css';
import { getLanguageArray } from '../../utils/Helper';

class InterpreterSearch extends Component {
  // it takes language code array and covert it to a 2d array and then return a 1d array of 3 values for one code
  // ex. ['eng', 'ben'] => language code array
  // [["eng", "englanti", "English"], ["ben", "bengali", "Bengali"]] => converted 2d array
  // [["eng", "englanti", "English", "ben", "bengali", "Bengali"]] => final 1d array
  // getLanguageArray = (keys) => {
  //   return keys
  //     .map((el) =>
  //       Object.values(getAllLanguages().filter((_el) => _el.code === el)[0]),
  //     )
  //     .reduce((prev, next) => prev.concat(next));
  // };

  onChangeSearchInterpreter = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    if (searchTerm.length > 2) {
      const filteredInterpreters = this.props.interpreters.filter(
        (interpreter) => {
          if (interpreter.sex === 'woman') {
            interpreter.sexFin = 'nainen';
          } else {
            interpreter.sexFin = 'mies';
          }

          if (!interpreter.city) interpreter.city = '';

          if (!interpreter.phoneNumber) interpreter.phoneNumber = '';

          const {
            firstName,
            lastName,
            city,
            phoneNumber,
            sex,
            sexFin,
            languages,
          } = interpreter;

          return (
            firstName.toLowerCase().includes(searchTerm) ||
            lastName.toLowerCase().includes(searchTerm) ||
            sex.toLowerCase() === searchTerm ||
            sexFin === searchTerm ||
            city.toLowerCase().includes(searchTerm) ||
            phoneNumber.includes(searchTerm) ||
            getLanguageArray(Object.keys(languages)).some((el) =>
              el.toLowerCase().includes(searchTerm),
            )
          );
        },
      );
      this.props.setSearchTermForInterpreter(true);
      this.props.setFilteredInterpreters(filteredInterpreters);
    } else {
      this.props.setFilteredInterpretersToInitialState();
    }
  };

  componentWillUnmount() {
    this.props.setFilteredInterpretersToInitialState();
  }

  render() {
    return (
      <div style={{ marginBottom: '15px' }}>
        <input
          type="text"
          className="search-form-input"
          onChange={this.onChangeSearchInterpreter}
          placeholder="Etsi tulkeista"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    interpreters: state.interpreters,
  };
};

export default connect(mapStateToProps, {
  setFilteredInterpreters,
  setSearchTermForInterpreter,
  setFilteredInterpretersToInitialState,
})(InterpreterSearch);
