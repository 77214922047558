import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getObjectById } from 'utils/Helper.js';
import { setInterpreters } from 'actions/setInterpreters';
import Interpreter from 'models/interpreter';

// components
import ListContainer from 'components/ListContainer/list-container.js';
import ContentContainer from 'components/ContentContainer/content-container.js';
import ContainerBox from 'components/ContainerBox/container-box.js';
import Modal from 'components/Modal/modal.js';
import Dialog from 'components/Dialog/dialog.js';
import AnalyticsContainer from 'components/AnalyticsContainer/analytics-container';
import TitleComponent from 'components/TitleComponent/title-component.js';
import LoadingSpinner from 'components/LoadingSpinner/loading-spinner';
import NewInterpreter from './NewInterpreter/new-interpreter.js';

class PageInterpreters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedInterpreter: null,
      createNew: false,
      editInterpreter: false,
      interpreters: [],
      isLoading: false,
      showRemoveDialog: false,
    };
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  cellTapped(id) {
    // tarkasta jos uuden luominen kesken
    //  this.context.router.history.push('/tulkit/' + id)
    const selectedInterpreter = getObjectById(this.props.interpreters, id);
    this.setState({
      selectedInterpreter: selectedInterpreter,
      createNew: false,
      editInterpreter: false,
    });
  }

  createNewTapped() {
    //  this.context.router.history.push('/tulkit/uusi')
    this.setState({ selectedInterpreter: null, createNew: true });
  }

  // NYT HAKEE AINA UUDET -> ???
  componentDidMount() {
    Interpreter.getInterpreters(
      (interpreters) => {
        this.props.setInterpreters(interpreters);
      },
      (error) => {
        console.log(error);
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.interpreters !== this.props.interpreters) {
      if (this.state.selectedInterpreter) {
        this.cellTapped(this.state.selectedInterpreter.id);
      }
    }
  }

  menuItemSelected(value) {
    if (value === 'edit') {
      this.setState({ editInterpreter: true });
    } else if (value === 'lock') {
      this.state.selectedInterpreter.lock();
    } else if (value === 'remove') {
      this.setState({ showRemoveDialog: true });
    }
  }

  render() {
    const {
      selectedInterpreter,
      createNew,
      editInterpreter,
      isLoading,
      showRemoveDialog,
    } = this.state;
    const { interpreters, filteredInterpreters } = this.props;

    var menuItems = [
      { value: 'edit', label: 'Muokkaa' },
      { value: 'lock', label: 'Lukitse' },
      { value: 'remove', label: 'Poista', type: 'danger' },
    ];

    if (selectedInterpreter && selectedInterpreter.status === 'locked') {
      menuItems[1] = { value: 'lock', label: 'Poista lukitus' };
    }

    return (
      <div>
        <ListContainer
          title="Tulkit"
          listItems={
            filteredInterpreters.interpreters.length > 0
              ? filteredInterpreters.interpreters
              : interpreters
          }
          cellTapped={this.cellTapped.bind(this)}
          createNewTapped={this.createNewTapped.bind(this)}
          selectedItem={selectedInterpreter ? selectedInterpreter.id : null}
        />

        {isLoading ? (
          <ContentContainer>
            <LoadingSpinner />
          </ContentContainer>
        ) : (
          <ContentContainer>
            {selectedInterpreter && !editInterpreter && (
              <div>
                <TitleComponent
                  // mehedi - use string literals for string concatenation
                  /*title={
                    selectedInterpreter.firstName +
                    ' ' +
                    selectedInterpreter.lastName
                  }*/
                  title={`${selectedInterpreter.firstName} ${selectedInterpreter.lastName}`}
                  menuItems={menuItems}
                  menuItemSelected={this.menuItemSelected.bind(this)}
                />
                <AnalyticsContainer interpreterId={selectedInterpreter.id} />
              </div>
            )}

            {selectedInterpreter && editInterpreter && (
              <ContainerBox>
                <NewInterpreter
                  interpreter={selectedInterpreter}
                  cancelPressed={() =>
                    this.setState({ createNew: false, editInterpreter: false })
                  }
                />
              </ContainerBox>
            )}

            {createNew && (
              <ContainerBox>
                <NewInterpreter
                  cancelPressed={() =>
                    // haluatko varmasti sulkea!
                    this.setState({ createNew: false, editInterpreter: false })
                  }
                />
              </ContainerBox>
            )}
          </ContentContainer>
        )}

        {showRemoveDialog && (
          <Modal>
            <Dialog
              title="Haluatko varmasti poistaa tulkin?"
              primaryBtnTitle="Poista"
              destructive={true}
              cancelPressed={() => this.setState({ showRemoveDialog: false })}
              confirmPressed={() => {
                this.setState({ isLoading: true, showRemoveDialog: false });
                this.state.selectedInterpreter.removeInterpreter(
                  () => {
                    this.setState({
                      selectedInterpreter: null,
                      isLoading: false,
                    });
                  },
                  (error) => {
                    this.setState({ isLoading: false });
                    console.log(error);
                  },
                );
              }}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    interpreters: state.interpreters,
    filteredInterpreters: state.filteredInterpreters,
  };
};

export default connect(mapStateToProps, { setInterpreters })(PageInterpreters);
