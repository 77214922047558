import React, { Component } from 'react';
import './removable-list-item.css';
import { faTrash } from '@fortawesome/fontawesome-pro-regular';
// mehedi - incorrect import
// import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class RemovableListItem extends Component {
  isEven(n) {
    return n % 2 === 0;
  }

  render() {
    const { title } = this.props;

    return (
      <div
        className={
          this.props.index && this.isEven(this.props.index + 1)
            ? 'rli even-rli'
            : 'rli'
        }
      >
        <p>{title}</p>

        <FontAwesomeIcon
          className="rli-trash-icon"
          icon={faTrash}
          onClick={() => {
            this.props.removePressed(title);
          }}
        />
      </div>
    );
  }
}
