import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from 'config/firebaseConfig';

// unnecessary imports
/*import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';*/

// components
import ChangePasswordForm from './change-password-form';
import ChangeEmail from './change-email';
import EditUser from './edit-user';
import LoadingSpinner from 'components/LoadingSpinner/loading-spinner';
import ContentContainer from 'components/ContentContainer/content-container';
import ContainerBox from 'components/ContainerBox/container-box';
import TitleComponent from 'components/TitleComponent/title-component';
import ChangePassword from 'components/ChangePassword/change-password';

class PageAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changePassword: false,
      changeUserPassword: false,
      changeEmail: false,
      editUser: false,
      isLoading: false,
    };

    this.profileMenuItemSelected = this.profileMenuItemSelected.bind(this);
    this.leaveOrganization = this.leaveOrganization.bind(this);
  }

  organizationMenuItemSelected(value) {
    if (value === 'changePassword') {
      this.setState({ changePassword: true });
    } else {
      console.log('no such value');
    }
  }

  leaveOrganization() {
    this.setState({ isLoading: true });
    const addMessage = firebase
      .functions()
      .httpsCallable('/organization/leave');
    addMessage()
      .then(() => {
        this.props.exittedCompany();
      })
      .catch((error) => {
        alert('Jotain meni pieleen');
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  profileMenuItemSelected(value) {
    if (value === 'exit') {
      this.leaveOrganization();
    } else if (value === 'changePassword') {
      this.setState({ changePassword: true });
    } else if (value === 'changeUserPassword') {
      this.setState({ changeUserPassword: true });
    } else if (value === 'changeEmail') {
      this.setState({ changeEmail: true });
    } else if (value === 'edit') {
      this.setState({ editUser: true });
    } else {
      console.log('no such value');
    }
  }

  render() {
    const exitMessage = `Poistu organisaatiosta "${this.props.organizationName}"`;

    const organizationMenuItems = [
      { value: 'changePassword', label: 'Vaihda salasana' },
    ];

    const profileMenuItems = [
      { value: 'edit', label: 'Muokkaa' },
      { value: 'changeUserPassword', label: 'Vaihda salasana' },
      { value: 'changeEmail', label: 'Vaihda sähköposti' },
      { value: 'exit', label: exitMessage, type: 'danger' }, // kumpaan tämä tulee????
    ];

    const {
      changePassword,
      changeUserPassword,
      changeEmail,
      editUser,
      isLoading,
    } = this.state;

    return (
      <div>
        {!isLoading ? (
          <ContentContainer>
            {!changePassword &&
              !changeUserPassword &&
              !changeEmail &&
              !editUser && (
                <div>
                  <TitleComponent
                    title={this.props.organizationName}
                    menuItems={organizationMenuItems}
                    menuItemSelected={this.organizationMenuItemSelected.bind(
                      this,
                    )}
                  >
                    <p>{'Käyttäjätunnus: ' + this.props.login}</p>
                  </TitleComponent>

                  <br />
                  <TitleComponent
                    title={
                      this.props.user.firstName + ' ' + this.props.user.lastName
                    }
                    menuItems={profileMenuItems}
                    menuItemSelected={this.profileMenuItemSelected.bind(this)}
                  >
                    <p>{firebase.auth().currentUser.email}</p>
                  </TitleComponent>
                </div>
              )}

            {changePassword && (
              <ContainerBox>
                <ChangePassword
                  id={this.props.organizationId}
                  cancelPressed={() => {
                    this.setState({ changePassword: false });
                  }}
                />
              </ContainerBox>
            )}

            {/* {!changePassword &&
            <div>
               <br/>
               <TitleComponent title={this.props.user.user.firstName + " " + this.props.user.user.lastName} menuItems={profileMenuItems} menuItemSelected={this.profileMenuItemSelected.bind(this)}>
                  <p>{firebase.auth().currentUser.email}</p>
               </TitleComponent>

            </div>
            } */}

            {changeUserPassword && (
              <ChangePasswordForm
                cancelPressed={() =>
                  this.setState({ changeUserPassword: false })
                }
              />
            )}

            {changeEmail && (
              <ChangeEmail
                cancelPressed={() => this.setState({ changeEmail: false })}
              />
            )}

            {editUser && (
              <EditUser
                cancelPressed={() => this.setState({ editUser: false })}
              />
            )}
          </ContentContainer>
        ) : (
          <ContentContainer>
            <LoadingSpinner />
          </ContentContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizationName: state.auth.user.organizationName,
    organizationId: state.auth.user.organizationId,
    login: state.auth.user.organizationLogin,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PageAccount);
