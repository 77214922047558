import React, { Component } from 'react';
import { getObjectById } from 'utils/Helper';

// firebase
import Interpreter from 'models/interpreter';
import User from 'models/user';
import Organization from 'models/organization';

// redux
import { connect } from 'react-redux';
import { setOrganizationName } from 'actions/setOrganizationName';
import { setInterpreterName } from 'actions/setInterpreterName';
import { setUserName } from 'actions/setUserName';

// components
import Modal from 'components/Modal/modal';
import LogDetails from 'components/LogDetails/log-details';

import './logs-table.css';

class LogsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logs: this.props.logs,
      selectedLog: null,
    };

    this.organizationNames = {};
    this.interpreterNames = {};
    this.userNames = {};
  }

  logUpdated(id, duration, errorRemoved) {
    const logsArray = [...this.state.logs];
    const object = getObjectById(logsArray, id);
    const index = logsArray.indexOf(object);
    if (index !== -1) {
      if (duration) {
        logsArray[index].duration = duration;
      }
      if (errorRemoved) {
        logsArray[index].error = undefined;
      }
    }
    this.setState({ logs: logsArray });
  }

  getOrganizationName(id) {
    if (!id) return '-';

    if (this.props.organizationNames[id]) {
      return this.props.organizationNames[id];
    } else if (this.organizationNames[id]) {
      return this.organizationNames[id];
    } else {
      // this.props.setOrganizationName({id: " "})
      this.organizationNames[id] = ' ';

      Organization.getOrganizationName(
        id,
        (name) => {
          this.props.setOrganizationName(name, id);
        },
        (error) => {
          console.log(error);
        },
      );
      return '';
    }
  }

  getInterpreterName(id) {
    if (!id) return '-';

    if (this.props.interpreterNames[id]) {
      return this.props.interpreterNames[id];
    } else if (this.interpreterNames[id]) {
      return this.interpreterNames[id];
    } else {
      // this.props.setOrganizationName({id: " "})
      this.interpreterNames[id] = ' ';

      Interpreter.getInterpreterName(
        id,
        (name) => {
          this.props.setInterpreterName(name, id);
        },
        (error) => {
          console.log(error);
        },
      );
      return '';
    }
  }

  getUserName(id) {
    if (!id) return '-';

    if (this.props.userNames[id]) {
      return this.props.userNames[id];
    } else if (this.userNames[id]) {
      return this.userNames[id];
    } else {
      this.userNames[id] = ' ';

      User.getUser(
        id,
        (user) => {
          const name = user.firstName + ' ' + user.lastName;
          this.props.setUserName(name, id);
        },
        (error) => {
          console.log(error);
        },
      );
      return '';
    }
  }

  render() {
    const { type, isAdministrator } = this.props;
    const { logs, selectedLog } = this.state;

    return (
      <div>
        {selectedLog && (
          <Modal>
            <LogDetails
              log={selectedLog}
              closePressed={() => this.setState({ selectedLog: null })}
              logUpdated={this.logUpdated.bind(this)}
            />
          </Modal>
        )}

        <table className="logs-table">
          <tbody>
            <tr>
              <th>pvm.</th>
              <th>klo.</th>
              <th>Kesto (min)</th>
              <th>Kielet</th>
              {(type === 'interpreter' || type === 'default') && (
                <th>Organisaatio</th>
              )}
              {(type === 'organization' || type === 'default') &&
                isAdministrator && <th>Tulkki</th>}
              {type === 'organization' && !isAdministrator && <th>Henkilö</th>}

              <th>Arvosana</th>
            </tr>

            {logs.map((log) => {
              let errorClass = null;
              if (log.error) errorClass = ' error-row';
              if (log.error === 'user_report') {
                errorClass = ' interpretation-failed-row';
              } else if (log.error === 'timeout') {
                errorClass = ' timeout';
              } else if (log.error === 'unavailable') {
                errorClass = ' unavailable';
              }
              return (
                <tr
                  key={log.id}
                  className={'log-row' + (errorClass ? errorClass : '')}
                  onClick={() => {
                    this.setState({ selectedLog: log });
                  }}
                >
                  <td>{log.getDateString()}</td>
                  <td>{log.getTimeString()}</td>
                  <td>{log.getCorrectedDurationInMinutes()}</td>
                  <td>{log.getLanguageString()}</td>

                  {(type === 'interpreter' || type === 'default') && (
                    <td>{this.getOrganizationName(log.organizationId)}</td>
                  )}
                  {(type === 'organization' || type === 'default') &&
                    isAdministrator && (
                      <td>{this.getInterpreterName(log.interpreterId)}</td>
                    )}
                  {type === 'organization' && !isAdministrator && (
                    <td>{this.getUserName(log.userId)}</td>
                  )}

                  <td>{log.rating ? log.rating + '/5' : '-'}</td>
                </tr>
              );
            })}

            {logs.length === 0 && (
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                {type !== 'user' && <td>-</td>}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAdministrator: state.auth.user.isAdministrator,
    organizationNames: state.organizationNames,
    interpreterNames: state.interpreterNames,
    userNames: state.userNames,
  };
};

export default connect(mapStateToProps, {
  setOrganizationName,
  setInterpreterName,
  setUserName,
})(LogsTable);
