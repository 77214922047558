import React, { Component } from 'react';

// redux
import { connect } from 'react-redux';
import { setInterpreters } from 'actions/setInterpreters';

// firebase
import Interpreter from 'models/interpreter';

// components
import ListContainer from 'components/ListContainer/list-container';
import ContentContainer from 'components/ContentContainer/content-container';
import CalendarAnalytics from 'components/PageCalendar/CalendarAnalytics/calendar-analytics';

class PageCalendarLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interpreter: null,
    };
  }

  componentDidMount() {
    if (this.props.user.isAdministrator) {
      Interpreter.getInterpreters(
        (interpreters) => {
          this.props.setInterpreters(interpreters);
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //    if (prevState.interpreter !== this.state.interpreter) {

  //    }
  // }

  selectInterpreter = (interpreterId) => {
    if (this.state.interpreter === interpreterId) {
      this.setState({ interpreter: null });
    } else {
      this.setState({ interpreter: interpreterId });
    }
  };

  render() {
    const { interpreter } = this.state;
    const { user, interpreters, filteredInterpreters } = this.props;

    return (
      <div>
        {user.isAdministrator && (
          <ListContainer
            title="Yhteenveto"
            listItems={
              filteredInterpreters.interpreters.length > 0
                ? filteredInterpreters.interpreters
                : interpreters
            }
            cellTapped={this.selectInterpreter}
            selectedItem={interpreter ? interpreter : null}
          />
        )}

        <ContentContainer>
          <CalendarAnalytics interpreter={user.interpreterId || interpreter} />
        </ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    interpreters: state.interpreters,
    filteredInterpreters: state.filteredInterpreters,
  };
};

export default connect(mapStateToProps, { setInterpreters })(PageCalendarLogs);
