import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router';

import firebase from 'config/firebaseConfig';

import Menu from 'burgerMenu.svg';
import Logo from 'tulkki-logo.svg';
import $ from 'jquery';
import './navbar.css';

class Navbar extends Component {
  menuItemSelected = (item) => {
    const history = this.props.history;

    switch (item) {
      case 'orders':
        history.push('/tilaukset');
        break;
      case 'calendar':
        history.push('/kalenteri');
        break;
      case 'calendarLogs':
        history.push('/yhteenveto');
        break;
      case 'logs':
        history.push('/loki');
        break;
      case 'customers':
        history.push('/asiakkaat');
        break;
      case 'interpreters':
        history.push('/tulkit');
        break;
      case 'languages':
        history.push('/kielet');
        break;
      case 'workers':
        history.push('/henkilosto');
        break;
      case 'info':
        history.push('/tulkit');
        break;
      case 'signOut':
        firebase
          .auth()
          .signOut()
          .then(
            function () {
              console.log('Signed Out');
            },
            function (error) {
              console.error('Sign Out Error', error);
            },
          );
        break;
      default:
        console.log(item);
        break;
    }
  };

  render() {
    const { currentUser } = this.props;

    if (!currentUser) return null;

    var menuItems = [];

    if (currentUser.isInterpreter) {
      menuItems = [
        { value: 'calendar', label: 'Kalenteri' },
        { value: 'calendarLogs', label: 'Yhteenveto' },
        { value: 'signOut', label: 'Kirjaudu ulos', destructive: true },
      ];
    }

    if (currentUser.isAdministrator) {
      menuItems = [
        { value: 'orders', label: 'Tilaukset' },
        { value: 'calendar', label: 'Kalenteri' },
        { value: 'calendarLogs', label: 'Yhteenveto' },
        { value: 'logs', label: 'Loki' },
        { value: 'customers', label: 'Asiakkaat' },
        { value: 'interpreters', label: 'Tulkit' },
        { value: 'languages', label: 'Kielet' },
        { value: 'signOut', label: 'Kirjaudu ulos', destructive: true },
      ];
    }

    if (currentUser.isOrganizationAdmin) {
      menuItems = [
        { value: 'logs', label: 'Loki' },
        { value: 'workers', label: 'Henkilöstö' },
        { value: 'info', label: 'Tiedot' },
        { value: 'signOut', label: 'Kirjaudu ulos', destructive: true },
      ];
    }

    return (
      <div className="navbar">
        <img className="logo" src={Logo} alt="METRISAVA" />
        <div className="container">
          <button
            type="button"
            className="toggle-menu-btn"
            data-toggle="collapse"
            data-target="#menu-items"
          >
            <img className="toggle-icon" src={Menu} alt="Toggle" />
          </button>

          <div id="menu-items" className="collapse menu-items">
            {menuItems.map((item) => {
              return (
                <div
                  key={item.value}
                  className={classNames(
                    'toggle-menu-item',
                    { disabled: item.disabled },
                    { destructive: item.destructive },
                  )}
                  onClick={() => {
                    this.menuItemSelected(item.value);
                    $('#menu-items').collapse('toggle');
                  }}
                >
                  <p>{item.label}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

// {currentUser.isInterpreter &&
//    <div>
//       <li className="menu-item">
//          <NavLink to="/kalenteri" className="menu-link">Kalenteri</NavLink>
//       </li>
//       {/* <li className="menu-item">
//          <NavLink exact to="/loki" className="menu-link">Loki</NavLink>
//       </li> */}
//    </div>
// }

// {currentUser.isAdministrator &&
//       <div>
//          <li className="menu-item">
//             <NavLink to="/kalenteri" className="menu-link">Kalenteri</NavLink>
//          </li>
//          <li className="menu-item">
//             <NavLink exact to="/loki" className="menu-link">Loki</NavLink>
//          </li>
//          <li className="menu-item">
//             <NavLink to="/asiakkaat" className="menu-link">Asiakkaat</NavLink>
//          </li>
//          <li className="menu-item">
//             <NavLink to="/tulkit" className="menu-link">Tulkit</NavLink>
//          </li>
//          <li className="menu-item">
//             <NavLink to="/kielet" className="menu-link">Kielet</NavLink>
//          </li>
//       </div>
//    }

//    { currentUser.isOrganizationAdmin &&
//       <div>
//          <li className="menu-item">
//             <NavLink exact to="/loki" className="menu-link">Loki</NavLink>
//          </li>
//          <li className="menu-item">
//             <NavLink to="/henkilosto" className="menu-link">Henkilöstö</NavLink>
//          </li>
//          <li className="menu-item">
//             <NavLink to="/tiedot" className="menu-link">Tiedot</NavLink>
//          </li>
//       </div>
//    }

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(Navbar));
