import React, {Component} from 'react';
import Organization from '../../models/organization'
import Input, {ValueToState} from '../Input/input'
import Button from '../Button/button'
import jquery from 'jquery'
//import './change-password.css'


export default class ChangePassword extends Component {

   constructor(props) {
      super(props)

      this.state = {
         password: null,
         repeated: null,
         errorMessage: null,
         success: false,
         inputErrors: {},
         isLoading: false
      }


      this.setPassword = this.setPassword.bind(this)
   }

   validate() {
      var inputErrors = {}
       
       if (!this.state.password || (this.state.password === "")) {
           inputErrors.password = "Vaaditaan"
       }
   
       if (!this.state.repeated || (this.state.repeated === "")) {
         inputErrors.repeated = "Vaaditaan"
       }
   
       this.setState({ inputErrors })
   
       return jquery.isEmptyObject(inputErrors) // inputErrors.isEmpty()
     }

   setPassword() {

      this.setState({isLoading: true})

      // VALIDATION!!!!!!!!!!!
      const valid = this.validate()
      if (!valid) {
         this.setState({isLoading: false})
         return
      }

      if (this.state.password === this.state.repeated) {

         Organization.resetPassword(this.props.id, this.state.password, () => {
            this.setState({errorMessage: null, success: true, isLoading: false})
         }, (error) => {
            console.log(error)
            this.setState({isLoading: false,errorMessage: "Jotain meni pieleen. Yritä myöhemmin uudelleen"})
         })

      } else {
         this.setState({isLoading: false, errorMessage: "Salasanat eivät täsmää"})
      }
   }



  render() {

   const { isLoading, inputErrors, errorMessage } = this.state


    return (

      <div>
         {this.state.success ? (
            <div>

               <h3>Salasana vaihdettu</h3>
               <br/>

               <Button round title="Valmis" onClick={this.props.cancelPressed}/>

            </div>
         ) : (
         <div>
            <Input required bold 
               type="password"
               title='Uusi Salasana'
               valueToState={new ValueToState(this, 'password')}
               disabled={isLoading}
               errorMessage={ inputErrors && inputErrors.password }
            />

            <Input required bold
               type="password"
               title='Toista uusi salasana'
               valueToState={new ValueToState(this, 'repeated')}
               disabled={isLoading}
               errorMessage={ inputErrors && inputErrors.repeated }
            />

            {errorMessage &&
               <p className="error-txt">{errorMessage}</p>
            }

            <div className="button-row">
               <Button styleType="secondary" round title="Peruuta" onClick={this.props.cancelPressed} disabled={isLoading}/>
               <Button round title="Vahvista" onClick={this.setPassword} disabled={isLoading}/>
            </div>
         </div>
         )}
      </div>
    );
  }

}