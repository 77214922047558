import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

let firebaseConfig = {};

// test phase config
if (process.env.REACT_APP_BUILD_STAGE === 'dev') {
  firebaseConfig = {
    apiKey: 'AIzaSyBit0iVeQA5ifrLFBtroVbOUiBNR0R4z54',
    authDomain: 'tulkki247.firebaseapp.com',
    databaseURL: 'https://tulkki247.firebaseio.com',
    projectId: 'tulkki247',
    storageBucket: 'tulkki247.appspot.com',
    messagingSenderId: '46151401043',
    appId: '1:46151401043:web:fe535b6d55d1aa30fed0a6',
    measurementId: 'G-5TFD48B5LQ',
  };
}

// production phase config
if (process.env.REACT_APP_BUILD_STAGE === 'prod') {
  firebaseConfig = {
    apiKey: 'AIzaSyBDbtLccYtOnY8nTOjFCZZXLw4Uz-vk-GI',
    authDomain: 'pohjois-savon-tukkikeskus.firebaseapp.com',
    databaseURL: 'https://pohjois-savon-tukkikeskus.firebaseio.com',
    projectId: 'pohjois-savon-tukkikeskus',
    storageBucket: 'pohjois-savon-tukkikeskus.appspot.com',
    messagingSenderId: '1037178435444',
    appId: '1:1037178435444:web:fd09f8343edd6cca',
  };
}

/*const firebaseDevConfig = {
  apiKey: 'AIzaSyBit0iVeQA5ifrLFBtroVbOUiBNR0R4z54',
  authDomain: 'tulkki247.firebaseapp.com',
  databaseURL: 'https://tulkki247.firebaseio.com',
  projectId: 'tulkki247',
  storageBucket: 'tulkki247.appspot.com',
  messagingSenderId: '46151401043',
  appId: '1:46151401043:web:fe535b6d55d1aa30fed0a6',
  measurementId: 'G-5TFD48B5LQ',
};
firebase.initializeApp(firebaseDevConfig);*/

/*const firebaseProdConfig = {
  apiKey: 'AIzaSyBDbtLccYtOnY8nTOjFCZZXLw4Uz-vk-GI',
  authDomain: 'pohjois-savon-tukkikeskus.firebaseapp.com',
  databaseURL: 'https://pohjois-savon-tukkikeskus.firebaseio.com',
  projectId: 'pohjois-savon-tukkikeskus',
  storageBucket: 'pohjois-savon-tukkikeskus.appspot.com',
  messagingSenderId: '1037178435444',
  appId: '1:1037178435444:web:fd09f8343edd6cca',
};

firebase.initializeApp(firebaseProdConfig);*/

firebase.initializeApp(firebaseConfig);

firebase.firestore();

export default firebase;
