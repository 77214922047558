export const ORGANIZATIONS_RECEIVED = 'ORGANIZATIONS_RECEIVED';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const STAFF_RECEIVED = 'STAFF_RECEIVED';
export const SET_ORGANIZATION_NAME = 'SET_ORGANIZATION_NAME';
export const SET_USER_NAME = 'SET_USER_NAME';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const SET_SIZE = 'SET_SIZE';

// interpreters
export const SET_INTERPRETER_NAME = 'SET_INTERPRETER_NAME';
export const INTERPRETERS_RECEIVED = 'INTERPRETERS_RECEIVED';
export const SEARCH_INTERPRETERS = 'SEARCH_INTERPRETERS';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_FILTERED_INTERPRETERS_TO_INITIAL_STATE =
  'SET_FILTERED_INTERPRETERS_TO_INITIAL_STATE';
