import React, {Component} from 'react';
import {sendPasswordResetEmail} from '../../services/auth-service'
import Input from '../Input/input'
import Button from '../Button/button'
import './page-forgot-password'
import jquery from 'jquery'


export default class PageForgotPassword extends Component {

	constructor(props) {
		super(props);

		this.state = {
         email: '',
         inputErrors: {},
         isLoading: false,
         errorMessage: null
		}

      this.onChange = this.onChange.bind(this)

   }

   onChange(e) {
      this.setState({ [e.target.name]: e.target.value });
   }
   
   sendPressed() {
      
      this.setState({isLoading: true})
      const valid = this.validate()
      if (!valid) {
         this.setState({isLoading: false})
         return
      }

      sendPasswordResetEmail(this.state.email, () => {
         this.props.cancelPressed()
      }, (error) => {
         console.log(error)
         this.setState({errorMessage: "Jotain meni pieleen"})
      })
   }


   validate() {
      var inputErrors = {}
       
       if (!this.state.email || (this.state.email === "")) {
           inputErrors.email = "Vaaditaan"
       }
   
       this.setState({ inputErrors })
   
       return jquery.isEmptyObject(inputErrors) // inputErrors.isEmpty()
     }




  render() {

   // const { joinOrganization } = this.props
   const { email, isLoading, errorMessage, inputErrors } = this.state;

    return (
         <div>

            <div className="signInForm">

            <h2>Salasanan palautus</h2>
            <br/>


            <Input
               type="text"
               name="email"
               placeholder="Sähköposti"
               value={email}
               onChange={this.onChange}
               autoFocus
               errorMessage={inputErrors.email}
               disabled={isLoading}
            />

            {errorMessage && 
                  <p className="error-txt no-margin">{errorMessage}</p>
            }

            <div className="button-row">
               <Button
                  title="Peruuta"
                  styleType="secondary"
                  round
                  disabled={isLoading}
                  onClick = {() => this.props.cancelPressed()}
               />
               <Button 
                  title="Lähetä"
                  round
                  disabled={isLoading}
                  onClick = {this.sendPressed.bind(this)}
               />
            </div>

            </div>

         </div>


    );
  }

}
