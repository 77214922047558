import languagesJson from 'language-codes.json';
import { fService } from './FService';

//export default class LanguageService {

//parse json
export function getLanguageOptions() {
  return languagesJson.map((lang) => {
    //return  {value: lang.alpha2, label: lang.English + " (" + lang.alpha2 + ")"}
    return { value: lang.code, label: lang.fi + ' (' + lang.code + ')' };
  });
}

// parse json to a js array
// return [{code: "eng", fi: "englanti", en: "English"}]
export function getAllLanguages() {
  return languagesJson.map(({ code, fi, en }) => ({
    code,
    fin: fi,
    eng: en,
  }));
}

export function getPriceClassOptions() {
  return [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
  ];
}

export function getLanguageDictionary() {
  const languages = [];
  languagesJson.forEach((lang) => {
    // languages[lang.alpha2] = lang.English
    languages[lang.code] = lang.fi;
  });
  return languages;
}

export function getLanguages(callback, onError) {
  const ref = fService.REF_LANGUAGES;
  ref
    .get()
    .then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        callback(data);
      } else {
        callback();
      }
    })
    .catch(function (error) {
      onError(error);
    });
}

export function saveLanguagesToFirebase(
  languagesFrom,
  languagesTo,
  callback,
  onError,
) {
  fService.REF_LANGUAGES.set({
    from: languagesFrom,
    to: languagesTo,
  })
    .then(() => {
      callback();
    })
    .catch((error) => {
      onError(error);
    });
}

//}
