import React, { Component } from 'react';
import './loading-spinner.css'

export default class LoadingSpinner extends Component {

	render() {
		return <div className="loader" />
	}

}
