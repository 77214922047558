import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/fontawesome-pro-solid';
import './default-cell.css';

export default class DefaultCell extends Component {
  render() {
    const { selected, title, subtitle, status, number } = this.props;

    return (
      <div
        className={'default-cell' + (selected ? ' selected-cell' : '')}
        onClick={() => this.props.cellTapped(this.props.id)}
      >
        <div className="cell-label-container">
          <p className="title-label">{title}</p>
          <p className="subtitle-label">{subtitle}</p>
        </div>

        {/* <p className="date-label">{date}</p> */}

        {typeof status === 'string' && (
          <div>
            {status === 'locked' ? (
              <FontAwesomeIcon className="lock-icon" icon={faLock} />
            ) : (
              <div className="cell-info-box">
                <p>({number || '-'})</p>
                <div className={'status-circle ' + (status ? status : '')} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
