import User from 'models/user';
import Organization from 'models/organization';
import Interpreter from 'models/interpreter';

// import { signOut } from '../services/auth-service';

export default class CurrentUser {
  constructor() {
    this.isAdministrator = false;
    this.isOrganizationAdmin = false;
    this.isInterpreter = false;
    this.interpreterId = null;
    this.organizationId = null;
    this.organizationName = null;
    this.organizationLogin = null;
    this.isOrganizationLocked = false;
    this.hasNoPermission = false;

    this.firstName = null;
    this.lastName = null;

    // tässä lataa kaiken tarvittavan aluksi ja triggeröi sitten loadCallback
  }

  setupUser(firebaseUser, loadCallback) {
    if (!firebaseUser) {
      loadCallback();
      return;
    }

    Interpreter.getInterpreter(firebaseUser.uid, (interpreter) => {
      if (interpreter) {
        this.isInterpreter = true;
        this.firstName = interpreter.firstName;
        this.lastName = interpreter.lastName;
        this.interpreterId = firebaseUser.uid;
        loadCallback();
      } else {
        //-------------------------------------

        // console.log(firebaseUser)
        User.getUser(
          firebaseUser.uid,
          (user) => {
            this.firstName = user.firstName;
            this.lastName = user.lastName;

            // CHECK IF ADMINISTRATOR
            if (user.administrator === true) {
              this.isAdministrator = true;
              loadCallback();
              return;
            }

            // GET ORGANIZATION
            if (user.organizationId) {
              this.organizationId = user.organizationId;

              Organization.checkIfOrganizationAdministrator(
                user.organizationId,
                firebaseUser.email,
                (administrator) => {
                  if (administrator) {
                    // ------------------------------------------
                    this.isOrganizationAdmin = true;
                    Organization.getOrganization(
                      user.organizationId,
                      (organization) => {
                        this.organizationName = organization.name;
                        this.organizationLogin = organization.login;
                        if (organization.locked) {
                          this.isOrganizationLocked = true;
                        }

                        loadCallback();
                      },
                      (error) => {
                        console.log(error);
                        this.hasNoPermission = true;
                        loadCallback();
                      },
                    );
                    //-------------------------------------------
                  } else {
                    this.hasNoPermission = true;
                    loadCallback();
                  }
                },
                (error) => {
                  // console.log(error)
                  // console.log("Not organization Admin - should have signed out")
                  this.hasNoPermission = true; // KOSKA FIREBASE RULES estää hakemasta jos ei ole
                  loadCallback();
                  // signOut()
                },
              );
            } else {
              // PRESENT VIEW WHERE USER CAN JOIN ORGANIZATION
              loadCallback(true); // No organization id
            }
          },
          (error) => {
            console.error(error); // NYT JÄÄ VAIN LATAAMAAN JOS EI LÖYDY
            console.error('LOGIN ERROR');
            // signOut() // TOD NÄK: Document not found - jos laittaa tämän, niin heittää ulos, kun luo tunnukset
          },
        );

        //-------------------------------------
      }
    });
  }
}
