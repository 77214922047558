import { fService } from 'services/FService.js';

export default class CalendarEvent {
  constructor(
    id,
    start,
    end,
    status,
    type,
    title,
    description,
    interpreter,
    notes,
    startCorrected,
    endCorrected,
    dateCreated,
    dateEdited,
  ) {
    this.id = id;
    this.start = start;
    this.end = end;
    this.status = status;
    this.type = type;
    this.title = title;
    this.description = description;
    this.interpreter = interpreter;

    // date created and edited was added later. All events does not have these
    this.dateCreated = dateCreated;
    this.dateEdited = dateEdited;

    //Interpreter can set these values
    this.notes = notes; // Interpreters notes about the event
    this.startCorrected = startCorrected; // Corrected times: To know the real duration of the interpretation -> use these for billing if exists
    this.endCorrected = endCorrected;
  }

  static StatusTypes = {
    CANCELLED: 'cancelled',
  };

  //NOTE: IF These are changed - update also to tilauslomake
  static Types = {
    PUHELINTULKKAUS: 'puhelintulkkaus',
    LÄSNÄOLOTULKKAUS: 'läsnäolotulkkaus',
    ILMOITUSTULKKAUS: 'ilmoitustulkkaus',
    PIKATULKKAUS: 'pikatulkkaus',
    KÄÄNNOS: 'käännös',
    VIDEOPUHELET: 'videoPuhelut',
    UNAVAILABLE: 'unavailable',
  };

  static getEventType(type) {
    const types = {
      ilmoitustulkkaus: 'Ilmoitustulkkaus',
      läsnäolotulkkaus: 'Läsnäolotulkkaus',
      pikatulkkaus: 'Pikatulkkaus',
      puhelintulkkaus: 'Puhelintulkkaus',
      käännös: 'Käännös',
      videoPuhelut: 'Video puhelut',
      unavailable: 'Ei käytettävissä',
    };
    return types[type];
  }

  static removeEvent(id, callback, onError) {
    const ref = fService.REF_CALENDAR_EVENTS.doc(id);
    ref
      .delete()
      .then(function () {
        callback();
      })
      .catch(function (error) {
        console.error(error);
        onError(error);
      });
  }

  static editEvent(
    id,
    start,
    end,
    status,
    type,
    title,
    description,
    interpreter,
    notes,
    startCorrected,
    endCorrected,
    dateEdited,
  ) {
    const timeModified = startCorrected ? true : false;

    let data = {
      start,
      end,
      title,
      description,
      status,
      type,
      interpreter,
      startCorrected,
      endCorrected,
      notes,
      dateEdited,
    };

    if (timeModified) {
      data['isTimeModified'] = true;
    }

    return new Promise((resolve, reject) => {
      const ref = fService.REF_CALENDAR_EVENTS.doc(id);
      ref
        .update(data)
        .then(function () {
          resolve();
        })
        .catch(function (error) {
          console.error(error);
          console.error('Error updating document');
          reject();
        });
    });
  }

  // If eventID exists, is editing -> skip event where id === eventId
  static overlappingExists = (start, end, interpreter, eventId) => {
    return new Promise((resolve, reject) => {
      const eventsRef = fService.REF_CALENDAR_EVENTS;
      // Get items that start before new item ends
      let query = eventsRef
        .where('start', '<', end)
        .orderBy('start')
        .where('interpreter', '==', interpreter)
        .where('status', '==', 'normal');

      query
        .get()
        .then((querySnapshot) => {
          let itemCount = 0;
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const endDate = data.end.toDate();
            if (endDate > start && doc.id !== eventId) {
              itemCount += 1;
            }
          });

          if (itemCount > 0) resolve(true);
          else resolve(false);
        })
        .catch((error) => {
          console.error('Failed to check overlapping events.', error);
          reject('Samanaikaisten tapahtumien tarkastus epäonnistui');
        });
    });
  };

  static saveEventToFirebase(
    start,
    end,
    status,
    type,
    title,
    description,
    interpreter,
    dateCreated,
  ) {
    return new Promise((resolve, reject) => {
      const ref = fService.REF_CALENDAR_EVENTS;
      // Save to firebase
      ref
        .doc()
        .set({
          start,
          end,
          title,
          description,
          status,
          type,
          interpreter,
          dateCreated,
        })
        .then(function () {
          resolve();
        })
        .catch(function (error) {
          console.error('Error writing document');
          reject(error);
        });
    });
  }

  removeEventfromFirebase(id) {
    return new Promise((resolve, reject) => {
      fService.REF_CALENDAR_EVENTS.doc(id)
        .delete.then(function () {
          resolve();
        })
        .catch(function (error) {
          console.error('Error removing document: ', error);
          reject(error);
        });
    });
  }

  /*

  updateInterpreterData(firstName, lastName, sex, languages, callback, onError) {
   const ref = fService.REF_INTERPRETERS.doc(this.id)
   const languagesArray = arrayToObject(languages)
   ref.update({
      firstName,
      lastName,
      sex,
      languages: languagesArray
   }).then(function() {
      callback()
   })
   .catch(function(error) {
      onError("Error updating document: " + error)
   });;
  }


*/

  static convertEventsToJSON(events) {
    return events.map((event) => {
      return {
        id: event.id,
        start: event.start,
        end: event.end,
        status: event.status,
        interpreter: event.interpreter,
        type: event.type,
        title: event.title,
        description: event.description,
        notes: event.notes,
        startCorrected: event.startCorrected,
        endCorrected: event.endCorrected,
        allDay: false,
        dateCreated: event.dateCreated,
        dateEdited: event.dateEdited,
      };
    });
  }

  static eventsFromSnapshot(querySnapshot) {
    var events = [];
    querySnapshot.forEach(function (doc) {
      const data = doc.data();

      // TODO: Add validation

      // convert firebase timestamp to date object
      const startDate = data.start.toDate();
      const endDate = data.end.toDate();
      const correctedStart =
        data.startCorrected && data.startCorrected.toDate();
      const correctedEnd = data.endCorrected && data.endCorrected.toDate();

      const dateCreated = data.dateCreated && data.dateCreated.toDate();
      const dateEdited = data.dateEdited && data.dateEdited.toDate();

      const newEvent = new CalendarEvent(
        doc.id,
        startDate,
        endDate,
        data.status,
        data.type,
        data.title,
        data.description,
        data.interpreter,
        data.notes,
        correctedStart,
        correctedEnd,
        dateCreated,
        dateEdited,
      );
      events.push(newEvent);
    });
    return events;
  }

  static getEvents(
    dateFrom,
    dateTo,
    interpreter,
    type,
    status,
    isTimeModified,
    callback,
    onError,
  ) {
    const eventsRef = fService.REF_CALENDAR_EVENTS;

    const from = dateFrom; //.toDate()
    const to = dateTo; // .toDate()

    let query = eventsRef
      .where('start', '>=', from)
      .where('start', '<', to)
      .orderBy('start');

    if (interpreter) {
      query = query.where('interpreter', '==', interpreter);
    }
    if (type) {
      query = query.where('type', '==', type);
    }
    if (status) {
      query = query.where('status', '==', status);
    }

    // isTimeModified tells if startCorrected and endCorrected has been modified -> Get only events where corrected times have been added
    if (isTimeModified) {
      query = query.where('isTimeModified', '==', true);
    }

    query
      .get()
      .then((querySnapshot) => {
        const events = CalendarEvent.eventsFromSnapshot(querySnapshot);
        const jsonEvents = this.convertEventsToJSON(events);
        callback(jsonEvents, events);
      })
      .catch((error) => {
        onError('error getting documents' + error);
      });
  }

  static getAllEvents(callback, onError) {
    const eventsRef = fService.REF_CALENDAR_EVENTS;

    eventsRef
      .get()
      .then((querySnapshot) => {
        callback(CalendarEvent.eventsFromSnapshot(querySnapshot));
      })
      .catch((error) => {
        onError('error getting documents' + error);
      });
  }
}
