import React, {Component} from 'react';
import Input, {ValueToState} from '../Input/input'
import ContainerBox from '../ContainerBox/container-box';
import Button from '../Button/button'
import jquery from 'jquery'
import {connect} from 'react-redux'
import User from '../../models/user'
import firebase from 'firebase/app'
import 'firebase/auth'
import {updateUserData} from '../../actions/updateUserData'


class EditUser extends Component {

  constructor(props) {
    super(props);

   this.state={
      firstName: this.props.currentUser.firstName && this.props.currentUser.firstName,
      lastName: this.props.currentUser.lastName && this.props.currentUser.lastName,
      isLoading: false,
      inputErrors: {},
   }

  }

  validate() {
   var inputErrors = {}
    
    if (!this.state.firstName || (this.state.firstName === "")) {
        inputErrors.firstName = "Vaaditaan"
    }

    if (!this.state.lastName || (this.state.lastName === "")) {
      inputErrors.lastName = "Vaaditaan"
  }

    this.setState({ inputErrors })

    return jquery.isEmptyObject(inputErrors) // inputErrors.isEmpty()
  }


  updateUserData() {

   this.setState({isLoading: true})
   
   //validate
   const valid = this.validate()
   if (!valid) {
      this.setState({isLoading: false})
      return
   }

   // this.props.updateUserData({firstName: this.state.firstName, lastName: this.state.lastName})
   // return

   const uid = firebase.auth().currentUser.uid
   User.updateUserData(uid, this.state.firstName, this.state.lastName, () => {
      // SUCCESS
      this.setState({isLoading: false})
      const data = {
         firstName: this.state.firstName,
         lastName: this.state.lastName
      }
      this.props.updateUserData(data)
      this.props.cancelPressed()

   }, (error) => {
      console.log(error)
      this.setState({errorMessage: "Jotain meni pieleen", isLoading: false})
   })



  }


  render() {

   const { isLoading, inputErrors, errorMessage } = this.state

   return (
      <ContainerBox>

            <Input required bold 
               title='Etunimi'
               valueToState={new ValueToState(this, 'firstName')}
               disabled={isLoading}
               errorMessage={ inputErrors && inputErrors.firstName }
            />

            <Input required bold 
               title='Sukunimi'
               valueToState={new ValueToState(this, 'lastName')}
               disabled={isLoading}
               errorMessage={ inputErrors && inputErrors.lastName }
            />
            

         {errorMessage &&
            <p className="error-txt">{errorMessage}</p>
         }

         <div className="button-row">
            <Button styleType="secondary" round title="Peruuta" onClick={this.props.cancelPressed} disabled={isLoading}/>
            <Button round title="Tallenna" onClick={this.updateUserData.bind(this)} disabled={isLoading}/>
         </div>

      </ContainerBox>
   )

  }


}


const mapStateToProps = state => {
   return {
     currentUser: state.auth.user
   }
 }
 
 
 export default connect(mapStateToProps, {updateUserData})(EditUser)
