import React, { Component } from 'react';
import Organization from 'models/organization';
import Interpreter from 'models/interpreter';
import User from 'models/user';

// redux
import { connect } from 'react-redux';

// component
import DefaultCell from 'components/DefaultCell/default-cell.js';
import './list.css';

class List extends Component {
  //   constructor(props) {
  //   super(props);
  //   // Don't call this.setState() here!
  //   this.state = { counter: 0 };
  //   this.handleClick = this.handleClick.bind(this);
  // }

  // KORJAA! TEE ERROR HANDLING. nyt kaatuu jos jotain property ei ole firebasessa
  getContent() {
    const items = this.props.listItems ? this.props.listItems : [];

    const { filteredInterpreters } = this.props;

    let content = null;

    if (items) {
      if (items[0] instanceof Organization) {
        content = items.map((item) => (
          <DefaultCell
            selected={this.props.selectedItem === item.id}
            cellTapped={this.props.cellTapped}
            key={item.id}
            id={item.id}
            title={item.name}
            status={item.locked ? 'locked' : null}
          />
        ));
      } else if (items[0] instanceof Interpreter) {
        content = items.map((item) => (
          <DefaultCell
            key={item.id}
            id={item.id}
            selected={this.props.selectedItem === item.id}
            cellTapped={this.props.cellTapped}
            title={item.firstName + ' ' + item.lastName}
            status={item.status}
            number={item.priceClass}
          />
        ));
      } else if (items[0] instanceof User) {
        content = items.map((item) => (
          <DefaultCell
            key={item.id}
            id={item.id}
            selected={this.props.selectedItem === item.id}
            cellTapped={this.props.cellTapped}
            title={item.firstName + ' ' + item.lastName}
          />
        ));
      }

      if (items[0] instanceof Interpreter) {
        if (
          filteredInterpreters.interpreters.length === 0 &&
          filteredInterpreters.isSearchTerm
        ) {
          return (
            <div>
              <p className="no-interpreter">{`Tulkkeja ei löytynyt !`}</p>
            </div>
          );
        } else return <div>{content}</div>;
      }
      return <div>{content}</div>;
    }
  }

  render() {
    return <div className="list">{this.getContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    filteredInterpreters: state.filteredInterpreters,
  };
};

export default connect(mapStateToProps)(List);
