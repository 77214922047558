import React, {Component} from 'react';
import './month-analytics.css'
import ButtonShowDetails from '../ButtonShowDetails/button-show-details';
import LogsTable from '../LogsTable/logs-table';
import Log from '../../models/log'
import AnalyticsTable from '../AnalyticsTable/analytics-table'
import LoadingSpinner from '../LoadingSpinner/loading-spinner';


export default class MonthAnalytics extends Component {

   constructor(props) {
		super(props)

      this.state = {
         logs: null,
         showLogs: props.showLogs ? props.showLogs : false
      }

      this.months = [
         "Tammikuu",
         "Helmikuu",
         "Maaliskuu",
         "Huhtikuu",
         "Toukokuu",
         "Kesäkuu",
         "Heinäkuu",
         "Elokuu",
         "Syyskuu",
         "Lokakuu",
         "Marraskuu",
         "Joulukuu"
      ]
   }

   componentDidMount() {
      this.fetchLogs()
   }

   getTitle() {
      const month = this.props.date.getMonth()
      const year = this.props.date.getFullYear()
      const title = this.months[month] + " " + year
      return title
   }

   getEndDate() {
      const newDate = new Date(this.props.date.getTime())
      // EI TARVITSE KORJATA MILISEKUNTEJA, KOSKA HAKUFUNKTIOSSA < eikä <=
      newDate.setMonth(newDate.getMonth() + 1)
      return newDate
   }

   // KORJAA NÄMÄ OIKEIN
   fetchLogs() {
      const organizationId = this.props.organizationId ? this.props.organizationId : null
      const interpreterId = this.props.interpreterId ? this.props.interpreterId : null
      const userId = this.props.userId ? this.props.userId : null
      // console.log(this.props.date)
      // console.log(this.getEndDate())
      Log.getLogs(this.props.date, this.getEndDate(), organizationId, interpreterId, userId, (logs) => {
         this.setState({ logs })
      }, (error) => {
         console.log(error);
      })
   }

  render() {
   var type = "default"
   if (this.props.interpreterId) {
      type = "interpreter"
   } else if (this.props.organizationId) {
      type = "organization"
   } if (this.props.userId) {
      type = "user"
   }
   const { logs, showLogs } = this.state

    return (
      <div>
         <h4 className="analytics-title-header">{this.getTitle()}</h4>

         {logs ? (
         <div>
            {/* { !showLogs &&
            <AnalyticsTable logs={logs} type={type}/>
            } */}
            <AnalyticsTable logs={logs} type={type}/>

            { showLogs &&
               <div>
                  <br/>
                  <h5 className="no-margin">Loki</h5>
                  <LogsTable logs={logs} type={type}/>
               </div>
            }

         </div>
         ) : (
               <LoadingSpinner/>
         )}

         <div className="details-btn-row">
            <ButtonShowDetails open={showLogs} onClick={() => {
               this.setState({showLogs: !showLogs})
            }}/>
         </div>
      </div>
    );
  }

}
