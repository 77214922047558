import React, { Component } from 'react';
import './dialog.css';
import Button from '../Button/button';

export default class Dialog extends Component {
  render() {
    const {
      title,
      primaryBtnTitle,
      message,
      cancelPressed,
      destructive,
      confirmPressed,
    } = this.props;

    return (
      <div className="dialog-container">
        <div className="dialog">
          <p className="title">{title}</p>
          <p>{message}</p>
          <div className="dialog-buttons">
            {cancelPressed && (
              <Button
                styleType="secondary"
                round
                title="Peruuta"
                onClick={cancelPressed}
              />
            )}
            <Button
              styleType={destructive ? 'danger' : 'primary'}
              round
              title={primaryBtnTitle || 'OK'}
              onClick={confirmPressed}
            />
          </div>
        </div>
      </div>
    );
  }
}
