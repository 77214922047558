import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dateToString } from 'utils/Helper';
import CalendarEvent from 'models/calendar-event';

// components
import AdvancedModal from 'components/AdvancedModal/advanced-modal';
import Modal from 'components/Modal/modal';
import Dialog from 'components/Dialog/dialog';

import './event-modal.css';

class EventModal extends Component {
  static propTypes = {
    event: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    reloadEvents: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      deleteDialogVisible: false,
    };
  }

  getInterpretersName = (id) => {
    const { interpreters } = this.props;
    const interpreter = interpreters.find((obj) => obj.id === id);
    if (interpreter) {
      return interpreter.firstName + ' ' + interpreter.lastName;
    } else {
      return '';
    }
  };

  render() {
    const { event, onClose, onEditPressed, isAdministrator, interpreters } =
      this.props;
    const { deleteDialogVisible } = this.state;

    // const start = event.start.toDate()
    // const end = event.end.toDate()
    // const testStr = dateToString(start,true)
    // console.log(testStr)

    // console.log(event.start.toDate()); // is moment object
    // console.log(event.startCorrected) // is date object

    return (
      <div>
        {deleteDialogVisible && event && isAdministrator && (
          <Modal>
            <Dialog
              title="Poista tapahtuma"
              message="Haluatko varmasti poistaa tapahtuman?"
              primaryBtnTitle="Poista"
              destructive={true}
              cancelPressed={() =>
                this.setState({ deleteDialogVisible: false })
              }
              confirmPressed={() => {
                this.setState({ isLoading: true, deleteDialogVisible: false });
                CalendarEvent.removeEvent(
                  event.id,
                  () => {
                    this.setState({ isLoading: false });
                    this.props.reloadEvents();
                    this.props.onClose();
                  },
                  (error) => {
                    this.setState({ isLoading: false });
                  },
                );
              }}
            />
          </Modal>
        )}

        {!deleteDialogVisible && (
          <AdvancedModal
            onClose={onClose}
            primaryBtnTitle="OK"
            secondaryBtnTitle="Muokkaa"
            primaryBtnPressed={onClose}
            secondaryBtnPressed={onEditPressed}
            removeBtnPressed={
              isAdministrator && event
                ? () => this.setState({ deleteDialogVisible: true })
                : undefined
            }
            title={event.title}
          >
            <div className="event-modal">
              <h4 className="duration-label">
                {dateToString(event.start.toDate(), true, false) +
                  ' klo. ' +
                  dateToString(event.start.toDate(), false, true) +
                  ' - ' +
                  dateToString(event.end.toDate(), false, true)}
              </h4>

              {event.status === 'cancelled' && (
                <p className="cancelled">
                  <strong>(Peruttu)</strong>
                </p>
              )}

              {isAdministrator && interpreters && (
                <p>
                  <strong>Tulkki: </strong>
                  {this.getInterpretersName(event.interpreter)}
                </p>
              )}
              <p>
                <strong>Tyyppi: </strong>{' '}
                {CalendarEvent.getEventType(event.type) || ''}
              </p>

              <p>
                <strong>Lisätietoja: </strong>
                {event.description}
              </p>

              {/* { event.dateCreated && <p><strong>Luotu: </strong>{dateToString(event.dateCreated, true, true)}</p>} */}
              {event.dateEdited && (
                <p>
                  <strong>Muokattu viimeksi: </strong>
                  {dateToString(event.dateEdited, true, true)}
                </p>
              )}

              {event.startCorrected && event.endCorrected && (
                <p>
                  <strong>Korjattu kesto: </strong>
                  {/* {dateToString(event.startCorrected) + " - " + dateToString(event.endCorrected)} */}
                  {dateToString(event.startCorrected, true, false) +
                    ' klo. ' +
                    dateToString(event.startCorrected, false, true) +
                    ' - ' +
                    dateToString(event.endCorrected, false, true)}
                </p>
              )}

              {event.notes && (
                <p>
                  <strong>Tulkin huomioita: </strong>
                  {event.notes}
                </p>
              )}
            </div>
          </AdvancedModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAdministrator: state.auth.user.isAdministrator,
    interpreters: state.interpreters,
  };
};

export default connect(mapStateToProps)(EventModal);
