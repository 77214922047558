import React, { Component } from 'react';
import { faPlus } from '@fortawesome/fontawesome-pro-light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './button-new-item.css';

export default class ButtonNewItem extends Component {
  render() {
    return (
      <button className="button-new-item" onClick={this.props.onClick}>
        <FontAwesomeIcon className="add-btn-fa-icon" icon={faPlus} />
        {this.props.children}
      </button>
    );
  }
}
