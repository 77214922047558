import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { getMonthBeginning } from 'utils/Helper';

// components
import ContainerBox from 'components/ContainerBox/container-box';
import CalendarAnalyticsMonth from './CalendarAnalyticsMonth/calendar-analytics-month';

import './calendar-analytics.css';

export default class CalendarAnalytics extends Component {
  static propTypes = {
    interpreter: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      monthDates: [],
    };

    this.months = [
      'Tammikuu',
      'Helmikuu',
      'Maaliskuu',
      'Huhtikuu',
      'Toukokuu',
      'Kesäkuu',
      'Heinäkuu',
      'Elokuu',
      'Syyskuu',
      'Lokakuu',
      'Marraskuu',
      'Joulukuu',
    ];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.interpreter !== this.props.interpreter) {
      this.setState({ monthDates: [] });
    }
  }

  addMonth(date) {
    const months = [...this.state.monthDates];
    const monthsInMS = months.map((month) => {
      return month.getTime();
    });
    monthsInMS.indexOf(date.getTime()) === -1
      ? months.push(date)
      : console.log('This item already exists');
    months.sort(function (a, b) {
      return b.getTime() - a.getTime();
    });
    this.setState({
      monthDates: months,
    });
  }

  removeMonth(date) {
    if (date) {
      var array = [...this.state.monthDates];
      var index = array.indexOf(date);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        console.log('item does not exist');
      }

      this.setState({ monthDates: array });
    } else {
      console.log('Error: Date missing!');
    }
  }

  // fetchEvents(date) {
  //    CalendarEvent.getEvents(date, this.getEndaDate(), this.props.interpreter, null, null, (events) => {

  //    }, (error) => {
  //       console.error(error)
  //    })
  // }

  getTitle(date) {
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${this.months[month]} ${year}`;
  }

  render() {
    const { monthDates } = this.state;
    //1/7/2018
    const milliseconds = 1530392400000;
    const dateMin = new Date(milliseconds);
    const dateMax = getMonthBeginning(new Date());

    return (
      <div>
        <div className="flex-row">
          <div className="date-picker-button" style={{ marginBottom: '12px' }}>
            <p className="date-picker-btn-lbl">Lisää kuukausi</p>
            <DatePicker
              id="date-picker"
              onChange={(date) => {
                this.addMonth(date);
              }}
              minDetail="year"
              maxDetail="year"
              minDate={dateMin}
              maxDate={dateMax}
              locale="fi"
            />
          </div>
        </div>

        {monthDates.map((date) => {
          return (
            <ContainerBox
              key={date}
              item={date}
              onClose={this.removeMonth.bind(this)}
            >
              <h3>{this.getTitle(date)}</h3>
              <CalendarAnalyticsMonth
                interpreter={this.props.interpreter}
                from={date}
              />
            </ContainerBox>
          );
        })}
      </div>
    );
  }
}
