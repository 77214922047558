import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { getMonthBeginning } from 'utils/Helper';

import ContainerBox from 'components/ContainerBox/container-box';
import MonthAnalytics from 'components/MonthAnalytics/month-analytics';

import './analytics-container.css';

export default class AnalyticsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      monthDates: [],
    };
  }

  componentDidUpdate(prevProps) {
    // reset everything if interpreterId changes -- yksnikertaista koodi, nyt toistoa
    if (this.props.interpreterId !== prevProps.interpreterId) {
      this.setState({ monthDates: [] }, () => {
        this.setInitialMonths();
      });
    } else if (this.props.organizationId !== prevProps.organizationId) {
      this.setState({ monthDates: [] }, () => {
        this.setInitialMonths();
      });
    } else if (this.props.userId !== prevProps.userId) {
      this.setState({ monthDates: [] }, () => {
        this.setInitialMonths();
      });
    }
  }

  setInitialMonths() {
    const currentDate = getMonthBeginning(new Date());

    const prev = getMonthBeginning(new Date());
    prev.setMonth(prev.getMonth() - 1);

    const previous = getMonthBeginning(prev);

    this.setState({
      monthDates: [currentDate, previous],
    });
  }

  componentDidMount() {
    this.setInitialMonths();
  }

  // TARKASTA, ETTÄ AJOISSA EI OLE KOSKAAN HÄIKKÄÄ; MUUTEN LASKUTUKSEEN TUlEE ONGELMIA
  addMonth(date) {
    const months = [...this.state.monthDates];
    const monthsInMS = months.map((month) => {
      return month.getTime();
    });
    monthsInMS.indexOf(date.getTime()) === -1
      ? months.push(date)
      : console.log('This item already exists');
    months.sort(function (a, b) {
      return b.getTime() - a.getTime();
    });
    this.setState({
      monthDates: months,
    });
  }

  removeMonth(date) {
    if (date) {
      var array = [...this.state.monthDates];
      var index = array.indexOf(date);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        console.log('item does not exist');
      }

      this.setState({ monthDates: array });
    } else {
      console.log('Error: Date missing!');
    }
  }

  // addMonthPressed() {
  //    const element = document.getElementsByClassName("react-date-picker__button")
  //    element.click()
  // }

  render() {
    //1/7/2018
    const milliseconds = 1530392400000;
    const dateMin = new Date(milliseconds);
    const dateMax = getMonthBeginning(new Date());

    const { interpreterId, organizationId, userId } = this.props;
    const { monthDates } = this.state;

    return (
      <div>
        {monthDates.map((date) => {
          return (
            <ContainerBox
              key={date}
              item={date}
              onClose={this.removeMonth.bind(this)}
            >
              <MonthAnalytics
                showLogs={false}
                interpreterId={interpreterId}
                organizationId={organizationId}
                userId={userId}
                date={date}
              />
            </ContainerBox>
          );
        })}

        <div className="date-picker-button">
          <p className="date-picker-btn-lbl">Lisää kuukausi</p>
          <DatePicker
            id="date-picker"
            onChange={(date) => {
              this.addMonth(date);
            }}
            minDetail="year"
            maxDetail="year"
            minDate={dateMin}
            maxDate={dateMax}
            locale="fi"
          />
        </div>
      </div>
    );
  }
}
