import React, { Component } from 'react';

// firebase
import { getLanguageDictionary } from 'services/languages-service';

// components
import Modal from 'components/Modal/modal';
import BorderlessButton from 'components/BorderlessButton/borderless-button';
import Button from 'components/Button/button';

import jquery from 'jquery';
import './analytics-table.css';

export default class AnalyticsTable extends Component {
  constructor(props) {
    super(props);

    // sekunteina
    this.state = {
      call: null,
      video: null,
      rating: null,
      languages: null,
      showDetails: false,
    };

    this.languageDict = getLanguageDictionary();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    var call = 0;
    var video = 0;
    var ratings = [];
    var languages = [];

    this.props.logs.forEach((log) => {
      //RATING
      if (log.rating) {
        //should I check if number
        ratings.push(log.rating);
      }
      // TYPE: Calculated duration. If error exists duration is not included
      if ((log.type = 'call')) {
        const callTime = log.getCorrectedDurationInMinutes();
        if (!log.error) {
          call += callTime;
        }
      } else if (log.type === 'video') {
        const videoTime = log.getCorrectedDurationInMinutes();
        if (!log.error) {
          video += videoTime;
        }
      } else {
        console.log('ERROR! - cannot identify Log Type');
      }

      // LANGUAGES / DETAILS
      const duration = log.error ? 0 : log.getCorrectedDurationInMinutes();
      const lang = log.languageFrom;
      if (languages[lang]) {
        languages[lang] = languages[lang] + duration;
      } else {
        languages[lang] = duration;
      }
    });

    // Rating average
    var sum,
      avg = 0;
    if (ratings.length) {
      sum = ratings.reduce(function (a, b) {
        return a + b;
      });
      avg = sum / ratings.length;
    }
    var rounded = '-';
    if (sum) {
      rounded = Math.round(avg * 10) / 10;
    }

    if (jquery.isEmptyObject(languages)) {
      languages = null;
    } else {
      languages = this.sortOnKeys(languages);
    }

    this.setState({
      call,
      video,
      rating: rounded,
      languages,
    });
  }

  sortOnKeys(dict) {
    var sorted = [];
    for (var key in dict) {
      sorted[sorted.length] = key;
    }
    sorted.sort();

    var tempDict = {};
    for (var i = 0; i < sorted.length; i++) {
      tempDict[sorted[i]] = dict[sorted[i]];
    }

    return tempDict;
  }

  render() {
    const { call, video, rating, languages, showDetails } = this.state;

    return (
      <div>
        <table className="analytics-table">
          <tbody>
            <tr>
              {/* <th></th> */}
              <th>Puhelu (min)</th>
              <th>Video (min)</th>
              <th>Yhteensä (min)</th>
              <th>Arvosana ka.</th>
            </tr>

            <tr>
              {/* <th>Kesto (min)</th> */}
              <td>{call}</td>
              <td>{video}</td>
              <td>{call + video}</td>
              <td>{rating}</td>
            </tr>
          </tbody>
        </table>

        {languages && (
          <BorderlessButton
            className="details-button623"
            title="Lisätiedot"
            onClick={() => this.setState({ showDetails: true })}
          />
        )}

        {showDetails && (
          <Modal className="analytic-details">
            <table className="analytic-details-table">
              <tbody>
                <tr>
                  <th>Kielet</th>
                  <th>Kesto (min)</th>
                </tr>

                {Object.keys(languages).map((key) => {
                  return (
                    <tr key={key}>
                      <td>{this.languageDict[key] + ' (' + key + ')'}</td>
                      <td>{languages[key]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="modal-button-row">
              <Button
                round
                title="Sulje"
                onClick={() => this.setState({ showDetails: false })}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
