import React, { Component } from 'react';
import Input, { ValueToState } from '../Input/input';
import ContainerBox from '../ContainerBox/container-box';
import Button from '../Button/button';
import jquery from 'jquery';
import { updateEmail } from '../../services/auth-service';

export default class ChangeEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: null,
      email: null,
      isLoading: false,
      inputErrors: {},
      success: false,
    };
  }

  validate() {
    const inputErrors = {};

    // mehedi - redundant or condition check
    // !this.state.password || this.state.password === ''
    if (!this.state.password) {
      inputErrors.password = 'Vaaditaan';
    }

    // mehedi - redundant or condition check
    // !this.state.email || this.state.email === ''
    if (!this.state.email) {
      inputErrors.email = 'Vaaditaan'; //"Tarkasta sähköposti"
    }

    this.setState({ inputErrors });

    return jquery.isEmptyObject(inputErrors); // inputErrors.isEmpty()
  }

  changeEmail() {
    this.setState({ isLoading: true });

    //validate
    const valid = this.validate();
    if (!valid) {
      this.setState({ isLoading: false });
      return;
    }

    updateEmail(
      this.state.password,
      this.state.email,
      () => {
        this.setState({ success: true, isLoading: false });
      },
      (error) => {
        console.log(error);
        this.setState({
          errorMessage: 'Sähköpostin vaihtaminen epäonnistui',
          isLoading: false,
        });
        if (error.code === 'auth/wrong-password') {
          this.setState({ errorMessage: 'Väärä salasana' });
        } else if (error.code === 'auth/invalid-email') {
          this.setState({ errorMessage: 'Tarkasta sähköposti' });
        } else {
          this.setState({ errorMessage: 'Jotain meni pieleen' });
        }
      },
    );
  }

  render() {
    const { isLoading, inputErrors, errorMessage, success } = this.state;

    return (
      <ContainerBox>
        {!success ? (
          <div>
            <Input
              required
              bold
              title="Uusi sähköposti"
              valueToState={new ValueToState(this, 'email')}
              disabled={isLoading}
              errorMessage={inputErrors && inputErrors.email}
            />

            <Input
              required
              bold
              type="password"
              title="Salasana"
              valueToState={new ValueToState(this, 'password')}
              disabled={isLoading}
              errorMessage={inputErrors && inputErrors.password}
            />

            {errorMessage && <p className="error-txt">{errorMessage}</p>}

            <div className="button-row">
              <Button
                styleType="secondary"
                round
                title="Peruuta"
                onClick={this.props.cancelPressed}
                disabled={isLoading}
              />
              <Button
                round
                title="Vahvista"
                onClick={this.changeEmail.bind(this)}
                disabled={isLoading}
              />
            </div>
          </div>
        ) : (
          <div>
            <h3>Sähköposti vaihdettu</h3>
            <p>{'Sähköposti: ' + this.state.email}</p>
            <br />
            <Button round title="Valmis" onClick={this.props.cancelPressed} />
          </div>
        )}
      </ContainerBox>
    );
  }
}
