import React, {Component} from 'react'
import PropTypes from 'prop-types'
import CalendarEvent from '../../../../models/calendar-event'

export default class CalendarAnalyticsMonth extends Component {

   static propTypes = {
      from: PropTypes.object,
      interpreter: PropTypes.string,
   }

   constructor(props) {
      super(props)

      this.state = {
         durations: {},
         durationsWithoutCorrection: {},
      }

   }

   componentDidMount() {

      const STATUS_NORMAL = "normal"

      const { interpreter, from } = this.props

      CalendarEvent.getEvents(from, this.getEndDate(), interpreter, CalendarEvent.Types.ILMOITUSTULKKAUS, STATUS_NORMAL, false, (jsonEvents, events) => {
         this.getDurationFromEvents(events, CalendarEvent.Types.ILMOITUSTULKKAUS)
      }, (error) => {
         console.error(error)
      })

      CalendarEvent.getEvents(from, this.getEndDate(), interpreter, CalendarEvent.Types.LÄSNÄOLOTULKKAUS, STATUS_NORMAL, false, (jsonEvents, events) => {
         this.getDurationFromEvents(events, CalendarEvent.Types.LÄSNÄOLOTULKKAUS)
      }, (error) => {
         console.error(error)
      })

      CalendarEvent.getEvents(from, this.getEndDate(), interpreter, CalendarEvent.Types.PIKATULKKAUS, STATUS_NORMAL, false, (jsonEvents, events) => {
         this.getDurationFromEvents(events, CalendarEvent.Types.PIKATULKKAUS)
      }, (error) => {
         console.error(error)
      })

      CalendarEvent.getEvents(from, this.getEndDate(), interpreter, CalendarEvent.Types.PUHELINTULKKAUS, STATUS_NORMAL, false, (jsonEvents, events) => {
         this.getDurationFromEvents(events, CalendarEvent.Types.PUHELINTULKKAUS)
      }, (error) => {
         console.error(error)
      })

      CalendarEvent.getEvents(from, this.getEndDate(), interpreter, CalendarEvent.Types.KÄÄNNOS, STATUS_NORMAL, false, (jsonEvents, events) => {
         this.getDurationFromEvents(events, CalendarEvent.Types.KÄÄNNOS)
      }, (error) => {
         console.error(error)
      })
   }

   getEndDate() {
      const newDate = new Date(this.props.from.getTime())
      newDate.setMonth(newDate.getMonth() + 1)
      return newDate
   }


   getDurationFromEvents = (events, type) => {
      let minutes = 0
      let minutesWithoutCorrection = 0
      events.forEach(event => {
         if (event.startCorrected && event.endCorrected) {
            minutes += this.getIntervalInMinutes(event.startCorrected, event.endCorrected)
            minutesWithoutCorrection += this.getIntervalInMinutes(event.start, event.end)
         } else {
            const mins = this.getIntervalInMinutes(event.start, event.end)
            minutes += mins
            minutesWithoutCorrection += mins
         }
      });

      // duration[type] minutes}
      this.setState(prevState => ({
         durations: {
         ...prevState.durations,
         [type]: minutes
         },
         durationsWithoutCorrection: {
            ...prevState.durationsWithoutCorrection,
            [type]: minutesWithoutCorrection,
         }
      })
      )
   }

   getIntervalInMinutes = (start, end) => {
      const _end = end.getTime()
      const _start = start.getTime()
      const difference = _end - _start // ms
      const minutes = difference / 1000 / 60
      const rounded = Math.round(minutes)
      return rounded
   }

   render() {

      const {durations, durationsWithoutCorrection} = this.state

      return (
         <div>
            <div className="calendar-durations">
               <h4>Korjatut</h4>
               {Object.keys(durations).map(key => {
                  return (
                     <div key={key}>
                        <p><strong>{CalendarEvent.getEventType(key)}: </strong>{durations[key]} min.</p>
                     </div>
                  )
               })}
               <br/>
               <h4>Ilman korjauksia</h4>
               {Object.keys(durations).map(key => {
                  return (
                     <div key={key}>
                        <p><strong>{CalendarEvent.getEventType(key)}: </strong>{durationsWithoutCorrection[key]} min.</p>
                     </div>
                  )
               })}
            </div>
         </div>
      )
   }

}