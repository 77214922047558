import { SET_ORGANIZATION_NAME} from "../actions/types";


const initialState = {}

const organizationNamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATION_NAME:
      const names = Object.assign({}, state)
      names[action.payload.id] = action.payload.name
      return names
    default:
      break
  }
  return state;
}

export default organizationNamesReducer;
