import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal/modal';
import Button from 'components/Button/button';

import './advanced-modal.css';

export default class AdvancedModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    // onClose: PropTypes.func.isRequired,
    primaryBtnPressed: PropTypes.func,
    secondaryBtnPressed: PropTypes.func,
    primaryBtnTitle: PropTypes.string,
    secondaryBtnTitle: PropTypes.string,

    removeBtnPressed: PropTypes.func,
    disabled: PropTypes.bool,
  };

  render() {
    const {
      title,
      disabled,
      primaryBtnPressed,
      secondaryBtnPressed,
      removeBtnPressed,
      primaryBtnTitle,
      secondaryBtnTitle,
    } = this.props;

    return (
      <Modal>
        <div className="advanced-modal">
          <h3 className="title">{title}</h3>
          <hr className="title-underline" />
          {this.props.children}

          <hr />

          <div className="advanced-modal-button-row">
            {removeBtnPressed && (
              <Button
                className="secondary-button"
                styleType={Button.StyleType.DANGER}
                round
                title="Poista"
                onClick={removeBtnPressed}
                disabled={disabled}
              />
            )}
            <Button
              className="secondary-button"
              styleType={Button.StyleType.SECONDARY}
              round
              title={secondaryBtnTitle}
              onClick={secondaryBtnPressed}
              disabled={disabled}
            />
            <Button
              round
              title={primaryBtnTitle}
              onClick={primaryBtnPressed}
              disabled={disabled}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
