import React, { Component } from 'react';
import firebase from 'config/firebaseConfig';

import Input from 'components/Input/input.js';
import LoadingSpinner from 'components/LoadingSpinner/loading-spinner';
import Button from 'components/Button/button.js';
import BorderlessButton from 'components/BorderlessButton/borderless-button.js';

import './page-login.css';

// mehedi - unused imports
// import * as firebase from 'firebase';
// import "firebase/firestore";
// import {signIn} from '../../Services/auth-service.js'

export default class PageLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errorMessage: '',
      errors: {},
      isLoading: false,
      changePassword: true,
    };

    this.onChange = this.onChange.bind(this);
    this.loginPressed = this.loginPressed.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  loginPressed(onError) {
    this.setState({ isLoading: true });

    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((data) => {
        return data.user.getIdToken();
      })
      .then((token) => {
        console.log(token);
      })
      .catch(function (error) {
        // Handle Errors here.
        onError(error.code);
        const errorMessage = error.message;
        console.log(errorMessage);
        // ...
      });
  }

  login = () => {
    this.loginPressed((eCode) => {
      if (eCode === 'auth/user-not-found' || eCode === 'auth/wrong-password') {
        this.setState({
          errorMessage: 'Käyttäjätunnus ja salasana eivät täsmää',
        });
      } else {
        this.setState({ errorMessage: 'Jotain meni pieleen' });
        console.log(eCode);
      }
      // auth/user-not-found
      // auth/wrong-password
      this.setState({ isLoading: false });
    });
  };

  render() {
    // const { joinOrganization } = this.props
    const { email, password, errors, isLoading, errorMessage } = this.state;

    const emailError = errors.input && errors.input.email;
    const passwordError = errors.input && errors.input.password;

    return (
      <div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="signInForm">
            <div className="row">
              <div className="col-12">
                <h2>Kirjaudu sisään</h2>
                <br />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Input
                  type="text"
                  name="email"
                  placeholder="Sähköposti"
                  value={email}
                  onChange={this.onChange}
                  autoFocus
                  errorMessage={emailError}
                />

                <Input
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  placeholder="Salasana"
                  errorMessage={passwordError}
                  onEnterPressed={this.login}
                />

                {errorMessage && <p className="error-txt">{errorMessage}</p>}
              </div>
            </div>

            <Button
              title="Kirjaudu"
              styleTYpe="primary"
              round
              onClick={this.login}
            />

            <br />
            <BorderlessButton
              className="create-user-button"
              title="Luo tunnukset"
              onClick={this.props.createAccountPressed}
            />
            <div />
            <BorderlessButton
              className="create-user-button"
              title="Unohtuiko salasana?"
              onClick={this.props.forgotPasswordPressed}
            />
          </div>
        )}
      </div>
    );
  }
}
