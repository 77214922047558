import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getObjectById } from 'utils/Helper.js';

// redux
import { connect } from 'react-redux';
import { setOrganizations } from 'actions/setOrganizations';

// components
import Organization from 'models/organization';
import ListContainer from 'components/ListContainer/list-container.js';
import ContentContainer from 'components/ContentContainer/content-container.js';
import ContainerBox from 'components/ContainerBox/container-box.js';
import AnalyticsContainer from 'components/AnalyticsContainer/analytics-container';
import NewOrganization from './NewOrganization/new-organization.js';
import TitleComponent from 'components/TitleComponent/title-component.js';
import ChangePassword from 'components/ChangePassword/change-password.js';

class PageOrganizations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOrganization: null,
      createNew: false,
      editOrganization: false,
      resetPassword: false,
    };
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  cellTapped(id) {
    // tarkasta jos uuden luominen kesken
    //  this.context.router.history.push('/asiakkaat/' + id)
    const selectedOrganization = getObjectById(this.props.organizations, id);
    this.setState({
      selectedOrganization: selectedOrganization,
      editOrganization: false,
      createNew: false,
      editNotice: false,
      resetPassword: false,
    });
  }

  createNewTapped() {
    //  this.context.router.history.push('/asiakkaat/uusi')
    this.setState({ selectedOrganization: null, createNew: true });
  }

  // kun päivittää komponentin (esim lukitsee organisaation), hakee organisaatiot uudelleen ja päivittää valitun uuteen
  componentDidUpdate(prevProps) {
    if (prevProps.organizations !== this.props.organizations) {
      if (this.state.selectedOrganization) {
        this.cellTapped(this.state.selectedOrganization.id);
      }
    }
  }

  // NYT HAKEE AINA UUDET -> ???
  componentDidMount() {
    this.getOrganizations();
  }

  getOrganizations() {
    Organization.getOrganizations(
      (organizations) => {
        this.props.setOrganizations(organizations);
      },
      (error) => {
        console.log(error);
      },
    );
  }

  menuItemSelected(value) {
    if (value === 'edit') {
      this.setState({ editOrganization: true });
    } else if (value === 'lock') {
      this.state.selectedOrganization.lock(
        () => {
          this.getOrganizations();
        },
        (error) => {
          console.log(error);
        },
      );
    } else if (value === 'changePassword') {
      this.setState({ resetPassword: true, editOrganization: false });
    } else {
      console.log('no such value');
    }
  }

  render() {
    const { selectedOrganization, createNew, editOrganization, resetPassword } =
      this.state;
    const { organizations } = this.props;

    const organizationMenuItems = [
      { value: 'edit', label: 'Muokkaa' },
      { value: 'changePassword', label: 'Vaihda salasana' },
      { value: 'lock', label: 'Lukitse', type: 'danger' },
    ];

    if (selectedOrganization && selectedOrganization.locked === true) {
      organizationMenuItems[2] = { value: 'lock', label: 'Poista lukitus' };
    }

    return (
      <div>
        <ListContainer
          title="Asiakkaat"
          listItems={organizations}
          cellTapped={this.cellTapped.bind(this)}
          createNewTapped={this.createNewTapped.bind(this)}
          selectedItem={selectedOrganization ? selectedOrganization.id : null}
        />

        <ContentContainer>
          {selectedOrganization && !editOrganization && !resetPassword && (
            <div>
              <TitleComponent
                noMargin
                title={selectedOrganization.name}
                menuItems={organizationMenuItems}
                menuItemSelected={this.menuItemSelected.bind(this)}
              />
              <p>
                <strong>Luokat: </strong>
                {(selectedOrganization.priceClasses &&
                  selectedOrganization.priceClasses.map((c, index) => {
                    if (index === 0) {
                      return c;
                    } else {
                      return ', ' + c;
                    }
                  })) ||
                  '-'}
              </p>
              <AnalyticsContainer organizationId={selectedOrganization.id} />
            </div>
          )}

          {selectedOrganization && editOrganization && (
            <ContainerBox>
              <NewOrganization
                organization={selectedOrganization}
                organizationUpdated={() => {
                  this.getOrganizations();
                  this.setState({
                    createNew: false,
                    editOrganization: false,
                  });
                }}
                cancelPressed={() =>
                  this.setState({ createNew: false, editOrganization: false })
                }
              />
            </ContainerBox>
          )}

          {createNew && (
            <ContainerBox>
              <NewOrganization
                oraganizationCreated={() => {
                  this.getOrganizations();
                  // this.setState({
                  //    selectedOrganization: null,
                  //    createNew: false,
                  //    editOrganization: false
                  // })
                }}
                cancelPressed={() =>
                  this.setState({ createNew: false, editOrganization: false })
                }
              />
            </ContainerBox>
          )}

          {resetPassword && selectedOrganization && (
            <ContainerBox>
              <ChangePassword
                id={selectedOrganization.id}
                cancelPressed={() => {
                  this.setState({
                    createNew: false,
                    editOrganization: false,
                    resetPassword: false,
                  });
                }}
              />
            </ContainerBox>
          )}
        </ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organizations,
  };
};

export default connect(mapStateToProps, { setOrganizations })(
  PageOrganizations,
);
