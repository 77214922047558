import React, {Component} from 'react'
import './error-component.css'
import Button from '../Button/button'
import {signOut} from '../../services/auth-service'

export default class ErrorComponent extends Component {

   render() {
      return (
         <section>
            <div className="error-component">
               <h2>{this.props.title}</h2>
               <p>{this.props.subtitle}</p>
               <Button title="OK" onClick={() => {signOut()}}/>
            </div>
         </section>
      )
   }
}