import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import './side-menu.css';
import { connect } from 'react-redux';
import BorderlessButton from '../BorderlessButton/borderless-button';
import { faSignOut } from '@fortawesome/fontawesome-pro-light';
import Logo from '../../tulkki-logo.svg';
/*import firebase from 'firebase/app'
import 'firebase/auth'*/
import firebase from '../../config/firebaseConfig';

class SideMenu extends Component {
  render() {
    const { currentUser } = this.props;

    if (!currentUser) return null;

    return (
      <div className="side-menu">
        <div className="tulkki-logo-row">
          <img className="main-logo" src={Logo} alt="" />
          <h5 className="font-light">
            {currentUser.isAdministrator
              ? 'Pohjois-Savon Tulkkikeskus'
              : currentUser.organizationName && currentUser.organizationName}
          </h5>
        </div>

        <ul className="menu-list">
          {currentUser.isInterpreter && (
            <div>
              <li className="menu-item">
                <NavLink to="/kalenteri" className="menu-link">
                  Kalenteri
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/yhteenveto" className="menu-link">
                  Yhteenveto
                </NavLink>
              </li>
              {/* <li className="menu-item">
               <NavLink exact to="/loki" className="menu-link">Loki</NavLink>
            </li> */}
            </div>
          )}

          {currentUser.isAdministrator && (
            <div>
              <li className="menu-item">
                <NavLink to="/tilaukset" className="menu-link">
                  Tilaukset
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/kalenteri" className="menu-link">
                  Kalenteri
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/yhteenveto" className="menu-link">
                  Yhteenveto
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink exact to="/loki" className="menu-link">
                  Loki
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/asiakkaat" className="menu-link">
                  Asiakkaat
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/tulkit" className="menu-link">
                  Tulkit
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/kielet" className="menu-link">
                  Kielet
                </NavLink>
              </li>
            </div>
          )}

          {currentUser.isOrganizationAdmin && (
            <div>
              <li className="menu-item">
                <NavLink exact to="/loki" className="menu-link">
                  Loki
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/henkilosto" className="menu-link">
                  Henkilöstö
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/tiedot" className="menu-link">
                  Tiedot
                </NavLink>
              </li>
            </div>
          )}

          <BorderlessButton
            styleType="primary"
            className="sign-out-btn"
            title="Kirjaudu ulos"
            icon={faSignOut}
            onClick={() => {
              firebase
                .auth()
                .signOut()
                .then(
                  function () {
                    console.log('Signed Out');
                  },
                  function (error) {
                    console.error('Sign Out Error', error);
                  },
                );
            }}
          />
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps)(SideMenu));
