import React, {Component} from 'react';
import Input from '../Input/input.js';
import "./page-new-account.css"
import LoadingSpinner from '../LoadingSpinner/loading-spinner'
import Button from '../Button/button.js';
import {createUserWithEmailAndPassword} from '../../services/auth-service'
import jquery from 'jquery'
import User from '../../models/user'



export default class PageNewAccount extends Component {

	constructor(props) {
		super(props);

		this.state = {
         email: '',
         phone: '',
         password: '',
         repeated: '',
         firstName: "",
         lastName: "",
         errorMessage: "",
         inputErrors: {},
			isLoading: false,

		}

		this.onChange = this.onChange.bind(this);
      this.createAccount = this.createAccount.bind(this);

	}




  onChange(e) {
  		this.setState({ [e.target.name]: e.target.value });
  }


  isPhoneValid = (number) => {
      return !number.match(/^((90[0-9]{3})?0|\+358\s?)(?!(100|20(0|2(0|[2-3])|9[8-9])|300|600|700|708|75(00[0-3]|(1|2)\d{2}|30[0-2]|32[0-2]|75[0-2]|98[0-2])))(4|50|10[1-9]|20(1|2(1|[4-9])|[3-9])|29|30[1-9]|71|73|75(00[3-9]|30[3-9]|32[3-9]|53[3-9]|83[3-9])|2|3|5|6|8|9|1[3-9])\s?(\d\s?){4,19}\d/)
  }


  validate() {
   var inputErrors = {}
    
   if (!this.state.firstName || (this.state.firstName === "")) {
      inputErrors.firstName = "Vaaditaan"
   }

   if (!this.state.lastName || (this.state.lastName === "")) {
      inputErrors.lastName = "Vaaditaan"
   }

   if (!this.state.email || (this.state.email === "")) {
      inputErrors.email = "Vaaditaan"
   }

   if (!this.state.phone || (this.state.phone === "")) {
      inputErrors.phone = "Vaaditaan"
   } else if (this.isPhoneValid("+358"+ this.state.phone)) {
      inputErrors.phone = "Tarkasta puhelinnumero"
   }

   if (!this.state.password || (this.state.password === "")) {
      inputErrors.password = "Vaaditaan"
   }

   if (!this.state.repeated || (this.state.repeated === "")) {
      inputErrors.repeated = "Vaaditaan"
   }

   this.setState({ inputErrors })

   return jquery.isEmptyObject(inputErrors) // inputErrors.isEmpty()
  }


  createAccount() {

   this.setState({isLoading: true})

     // validation
     const valid = this.validate()
      if (!valid) {
         this.setState({isLoading: false})
         return
      }

   if (this.state.password !== this.state.repeated) {
      this.setState({errorMessage: "Salasanat eivät täsmää"})
      return
   }

   createUserWithEmailAndPassword(this.state.email, this.state.password, (user) => {
      const phone = "+358" + this.state.phone
      User.createUser(user.uid, this.state.firstName, this.state.lastName, phone, () => {
         this.props.userCreated()
      }, (error) => {
         console.log("ERROR SAVING USERS DATA!!", error)
      })
   }, (error) => {
      console.log(error)
      this.setState({isLoading: false})
      if (error.code === "auth/invalid-email") {
         this.setState({errorMessage: "Tarkasta sähköposti osoite"})
      } else if (error.code === "auth/email-already-in-use") {
         this.setState({errorMessage: "Sähköposti on jo käytössä"})
      } else if (error.code === "auth/weak-password") {
         this.setState({errorMessage: "Liian heikko salasana"})
      } else {
         this.setState({errorMessage: "Jotain meni pieleen"})
      }
   })
   
  }


  render() {

   // const { joinOrganization } = this.props
   const { email, password, repeated, firstName, lastName, phone, inputErrors, isLoading, errorMessage } = this.state;


    return (
         <div>

            { isLoading ? ( 
               <LoadingSpinner/>
            ) : (
            <div className="signInForm">
            <div className="row">
               <div className="col-12">
                  <h2>Luo tunnukset</h2>
                  <br/>
               </div>
            </div>

            <div className="row">
               <div className="col-12">

               <Input
                  type="text"
                  name="firstName"
                  title="Etunimi"
                  value={firstName}
                  onChange={this.onChange}
                  autoFocus
                  errorMessage={inputErrors && inputErrors.firstName}
                  disabled={isLoading}
               />

               <Input
                  type="text"
                  name="lastName"
                  title="Sukunimi"
                  value={lastName}
                  onChange={this.onChange}
                  autoFocus
                  errorMessage={inputErrors && inputErrors.lastName}
                  disabled={isLoading}
               />


               <Input
                  type="text"
                  name="email"
                  title="Sähköposti"
                  value={email}
                  onChange={this.onChange}
                  autoFocus
                  errorMessage={inputErrors && inputErrors.email}
                  disabled={isLoading}
               />


               <Input
                  type="text"
                  name="phone"
                  leftText="+358"
                  title="Puhelinnumero"
                  value={phone}
                  onChange={this.onChange}
                  autoFocus
                  errorMessage={inputErrors && inputErrors.phone}
                  disabled={isLoading}
               />

               <Input
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  title="Salasana"
                  errorMessage={inputErrors && inputErrors.password}
                  disabled={isLoading}
               />

               <Input
                  type="password"
                  name="repeated"
                  value={repeated}
                  onChange={this.onChange}
                  title="Toista salasana"
                  errorMessage={inputErrors && inputErrors.repeated}
                  disabled={isLoading}
               />

               {errorMessage && 
                  <p className="error-txt">{errorMessage}</p>
               }

               </div>
            </div>
               

               <div className="button-row">
                     <Button styleType="secondary" round title="Peruuta" onClick={this.props.cancelPressed} disabled={isLoading}/>
                     <Button round title="Kirjaudu" onClick={this.createAccount} disabled={isLoading}/>
               </div>


               </div>
            )}
         </div>


    );
  }

}
