import firebase from '../config/firebaseConfig';
import { fService } from '../services/FService.js';
/*import firebase from 'firebase/app'
import 'firebase/firestore/dist/index.cjs';*/

export default class Log {
  constructor(
    id,
    type,
    interpreterId,
    organizationId,
    userId,
    languageFrom,
    languageTo,
    timestamp,
    duration,
    rating,
    error,
    userReport,
  ) {
    this.id = id;
    this.type = type;
    this.interpreterId = interpreterId;
    this.organizationId = organizationId;
    this.userId = userId;
    this.languageFrom = languageFrom;
    this.languageTo = languageTo;
    this.timestamp = timestamp;
    this.duration = duration;
    this.rating = rating;
    this.error = error;
    this.userReport = userReport;
  }

  getError() {
    if (this.error) {
      switch (this.error) {
        case 'cancelled':
          return 'Peruutettu';
        case 'no_duration':
          return 'Kestoa ei saatu!';
        case 'user_report':
          return 'Asiakas ilmoitti tulkkauksen epäonnistuneeksi';
        case 'timeout':
          return 'Kukaan ei vastannut';
        case 'unavailable':
          return 'Ei tulkkeja online';
        default:
          return 'Virhe';
      }
    } else {
      return '';
    }
  }

  getCorrectedDurationInMinutes() {
    const fullMinutes = Math.floor(this.duration / 60);
    const remainingSec = this.duration - fullMinutes * 60;
    // pyöristetään ylöspäin minuutin tarkkuudella - ????
    var corrected = fullMinutes;
    if (remainingSec > 0) {
      corrected += 1;
    }
    return corrected;
  }

  getDateString() {
    const month = this.timestamp.getMonth() + 1;
    const day = this.timestamp.getDate();
    const dateString = day + '.' + month + '. ';
    return dateString;
  }

  getUserName() {
    return ' - ';
  }

  getTimeString() {
    const minutes =
      this.timestamp.getHours() * 60 + this.timestamp.getMinutes();
    // const minutes = this.timestamp.getMinutes()
    const timeString = this.convertMinsToHrsMins(minutes);
    return timeString;
  }

  getLanguageString() {
    return this.languageFrom + ' - ' + this.languageTo;
  }

  getOrganizationName() {
    return ' - ';
  }

  getInterpreter() {
    return ' - ';
  }

  convertMinsToHrsMins(mins) {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${h}:${m}`;
  }

  removeError(callback, onError) {
    const ref = fService.REF_LOGS.doc(this.id);
    ref
      .update({
        error: firebase.firestore.FieldValue.delete(),
      })
      .then(function () {
        callback();
      })
      .catch(function (error) {
        onError('Removing error failed: ' + error);
      });
  }

  updateDuration(duration, callback, onError) {
    const ref = fService.REF_LOGS.doc(this.id);
    ref
      .update({
        duration,
      })
      .then(function () {
        //this.duration = duration
        callback();
      })
      .catch(function (error) {
        onError('Error updating log: ' + error);
      });
  }

  static logsFromSnapshot(querySnapshot) {
    var logs = [];
    querySnapshot.forEach(function (doc) {
      const data = doc.data();
      const timestamp = data.timestamp;
      const date = timestamp.toDate();
      // TARVITSEEKO TARKASTAA JOS JOTKUT OMINAISUUDET OVAT PAKOLLISIA - muuten kusee kaikki?
      // pakollisia timestamp duration ...
      const newLog = new Log(
        doc.id,
        data.type,
        data.interpreterId,
        data.organizationId,
        data.userId,
        data.languageFrom,
        data.languageTo,
        date,
        data.duration,
        data.rating,
        data.error,
        data.userReport,
      );
      logs.push(newLog);
    });
    return logs;
  }

  // https://firebase.google.com/docs/firestore/query-data/indexing
  //MUISTA LAITTAA INDEXIT

  static getLogs(
    dateFrom,
    dateTo,
    organizationId,
    interpreterId,
    userId,
    callback,
    onError,
  ) {
    const logsRef = fService.REF_LOGS;

    if (userId && organizationId) {
      logsRef
        .where('timestamp', '>=', dateFrom)
        .where('timestamp', '<', dateTo) // EI TARVITSE KORJATA MILLISEKUNTEJA
        .where('userId', '==', userId)
        .where('organizationId', '==', organizationId)
        .orderBy('timestamp')
        .get()
        .then((querySnapshot) => {
          const logs = Log.logsFromSnapshot(querySnapshot);
          callback(logs);
        })
        .catch((error) => {
          onError('error getting documents' + error);
        });
    } else if (interpreterId) {
      logsRef
        .where('timestamp', '>=', dateFrom)
        .where('timestamp', '<', dateTo) // EI TARVITSE KORJATA MILLISEKUNTEJA
        .where('interpreterId', '==', interpreterId)
        // .orderBy("timestamp", "desc")
        .orderBy('timestamp')
        .get()
        .then((querySnapshot) => {
          const logs = Log.logsFromSnapshot(querySnapshot);
          callback(logs);
        })
        .catch((error) => {
          onError('error getting documents' + error);
        });
    } else if (organizationId) {
      logsRef
        .where('timestamp', '>=', dateFrom)
        .where('timestamp', '<', dateTo) // EI TARVITSE KORJATA MILLISEKUNTEJA
        .where('organizationId', '==', organizationId)
        //.orderBy("timestamp", "desc")
        .orderBy('timestamp')
        .get()
        .then((querySnapshot) => {
          const logs = Log.logsFromSnapshot(querySnapshot);
          callback(logs);
        })
        .catch((error) => {
          onError('Error getting documents: ' + error);
        });
    } else {
      // tällä hakee kaikki
      logsRef
        .where('timestamp', '>=', dateFrom)
        .where('timestamp', '<', dateTo) // EI TARVITSE KORJATA MILLISEKUNTEJA
        //.orderBy("timestamp", "desc")
        .orderBy('timestamp')
        .get()
        .then((querySnapshot) => {
          const logs = Log.logsFromSnapshot(querySnapshot);
          callback(logs);
        })
        .catch((error) => {
          onError('error getting documents' + error);
        });
    }
  }
}
