import { SET_CURRENT_USER , UPDATE_USER_DATA} from '../actions/types';
//import jQuery from 'jquery';
import CurrentUser from '../models/current-user'
import firebase from 'firebase/app'
import 'firebase/auth'
// import isEmpty from 'lodash/isEmpty'

const initialState = {
	isAuthenticated: null,
	user: null
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
      case SET_CURRENT_USER:
			return {
				isAuthenticated: (firebase.auth().currentUser != null), //(action.user != null && !jQuery.isEmptyObject(action.user.firebaseUser)),
				user: action.user,
         };
      case UPDATE_USER_DATA:
         console.log(state.user)
         const user = Object.assign(new CurrentUser(), state.user)
         user.firstName = action.payload.data.firstName
         user.lastName = action.payload.data.lastName
			return {
            isAuthenticated: (firebase.auth().currentUser != null),
				user: user
			};
		default: return state;
	}
}