/*import * as firebase from 'firebase'*/
import firebase from '../config/firebaseConfig';
import { fService } from '../services/FService';

export default class InterpretationRequest {
  // Constructor is the same in tulkki-24-7-hallinta and tilauslomake
  constructor(
    id,
    timestamp,
    status,
    language,
    type,
    location,
    startTime,
    duration,
    customerName,
    notes,
    ordererName,
    ordererEmail,
    ordererPhone,
    billingInfo,
    organization,
    attachment,
  ) {
    this.id = id;
    this.timestamp = timestamp;
    this.status = status;
    this.language = language;
    this.type = type;
    this.location = location;
    this.startTime = startTime;
    this.duration = duration;
    this.customerName = customerName; // Person that needs interpretation
    this.notes = notes;

    // Orderer info
    this.ordererName = ordererName; // Person that orders interpretation
    this.ordererEmail = ordererEmail;
    this.ordererPhone = ordererPhone;
    this.billingInfo = billingInfo;
    this.organization = organization;

    this.attachment = attachment;
  }

  static objectsFromSnapshot = (snapshot) => {
    const requests = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      const startTime = data.startTime ? data.startTime.toDate() : undefined;
      const obj = new InterpretationRequest(
        doc.id,
        data.timestamp.toDate(),
        data.status,
        data.language,
        data.type,
        data.location,
        startTime,
        data.duration,
        data.customerName,
        data.notes,
        data.ordererName,
        data.ordererEmail,
        data.ordererPhone,
        data.billingInfo,
        data.organization,
        data.attachment,
      );
      requests.push(obj);
    });
    return requests;
  };

  static getAll = (callback) => {
    const ref = fService.REF_INTERPRETATION_REQUESTS;
    ref.orderBy('timestamp', 'desc').onSnapshot(function (querySnapshot) {
      const requests = InterpretationRequest.objectsFromSnapshot(querySnapshot);
      callback(null, requests);
    });
  };

  markHandled = () => {
    return new Promise((resolve, reject) => {
      const ref = fService.REF_INTERPRETATION_REQUESTS.doc(this.id);
      ref
        .update({ status: 'handled' })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  delete = () => {
    // Create a reference to the file to delete
    const storageRef = firebase
      .storage()
      .ref('request_attachments/' + this.attachment);

    // Delete the file
    storageRef
      .delete()
      .then(() => {
        console.log('succesfully deleted file');
      })
      .catch((error) => {
        console.error(error);
      });

    return new Promise((resolve, reject) => {
      const ref = fService.REF_INTERPRETATION_REQUESTS.doc(this.id);
      ref
        .delete()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
        });
    });
  };

  downloadAttachment = () => {
    return new Promise((resolve, reject) => {
      // Create a reference to the file we want to download
      const storageRef = firebase
        .storage()
        .ref('request_attachments/' + this.attachment);
      // Get the download URL
      storageRef
        .getDownloadURL()
        .then(function (url) {
          resolve(url);
        })
        .catch(function (error) {
          console.error(error.code);
          reject(error);
        });
    });
  };
}
