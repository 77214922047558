import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { getLanguageDictionary } from 'services/languages-service';
import Interpreter from 'models/interpreter';

// components
import CalendarEvent from 'models/calendar-event';
import Input, { ValueToState } from 'components/Input/input';
import SingleSelectDropdown from 'components/SingleSelectDropdown/single-select-dropdown';
import AdvancedModal from 'components/AdvancedModal/advanced-modal';
import Dialog from 'components/Dialog/dialog';
import Modal from 'components/Modal/modal';

import 'react-datepicker/dist/react-datepicker.css';
import './new-event-box.css';

// mehedi - unused imports
// import Button from '../../Button/button'
// import { request } from 'https';
// import fi from 'date-fns/locale/fi'

class NewEventBox extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onEventCreated: PropTypes.func.isRequired, // Currently runs also when event is modified
    reFetchEvents: PropTypes.func.isRequired,
    event: PropTypes.object,
    interpretationRequest: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: '',
      start: null, // start and end are moment objects, corrected dates are date objects
      end: null,
      startCorrected: null,
      endCorrected: null,
      notes: '',
      interpreter: null,
      interpreterOptions: [],
      eventType: null,
      eventStatus: 'normal',
      errorMessage: null,
      isLoading: false,
      overlappingDialogVisible: false,
    };

    this.languages = getLanguageDictionary();

    const types = CalendarEvent.Types;
    this.eventTypes = [
      { value: types.ILMOITUSTULKKAUS, label: 'Ilmoitustulkkaus' },
      { value: types.LÄSNÄOLOTULKKAUS, label: 'Läsnäolotulkkaus' },
      { value: types.PIKATULKKAUS, label: 'Pikatulkkaus' },
      { value: types.PUHELINTULKKAUS, label: 'Puhelintulkkaus' },
      { value: types.KÄÄNNOS, label: 'Käännös' },
      { value: types.VIDEOPUHELET, label: 'Video puhelut' },
      { value: types.UNAVAILABLE, label: 'Ei käytettävissä' },
    ];
  }

  getRequestTypeName(type) {
    const types = {
      ilmoitustulkkaus: 'Ilmoitus',
      läsnäolotulkkaus: 'Läsnä',
      pikatulkkaus: 'Pika',
      puhelintulkkaus: 'Puhelin',
      käännös: 'Käännös',
    };
    return types[type];
  }

  setInitialState = () => {
    const { interpretationRequest, event } = this.props;
    if (interpretationRequest) {
      const type = interpretationRequest.type;
      const language = this.languages[interpretationRequest.language];
      const location = interpretationRequest.location;
      const title = this.getRequestTypeName(type) + ', ' + language;
      let description = CalendarEvent.getEventType(type) + ' - ' + language;
      if (interpretationRequest.customerName)
        description += `,\nTulkattava: ${interpretationRequest.customerName}`;
      if (location) description += `,\nSijainti: ${location}`;
      if (interpretationRequest.notes)
        description += `,\n${interpretationRequest.notes}`;
      const startTime = interpretationRequest.startTime;
      const duration = interpretationRequest.duration;
      const endTime =
        duration && startTime
          ? new Date(startTime.getTime() + duration * 60000)
          : null;

      this.setState({
        title,
        description: description,
        start: startTime,
        end: endTime,
        eventType: type,
      });
    } else if (event) {
      this.setState({
        title: event.title,
        description: event.description,
        start: event.start.toDate(), // start and end are moment objects, corrected dates are date objects
        end: event.end.toDate(),
        startCorrected: event.startCorrected ? event.startCorrected : null,
        endCorrected: event.endCorrected ? event.endCorrected : null,
        notes: event.notes ? event.notes : '',
        interpreter: event.interpreter,
        eventType: event.type,
        eventStatus: event.status,
      });
    }
  };

  componentDidMount() {
    this.setInitialState();

    Interpreter.getInterpreters((interpreters) => {
      const options = [];
      interpreters.forEach((interpreter) => {
        // TODO: Add validation in necessary
        const option = {
          value: interpreter.id,
          label: interpreter.firstName + ' ' + interpreter.lastName,
        };
        options.push(option);
      });
      this.setState({ interpreterOptions: options });
    });
  }

  sameDay(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  savePressed = async () => {
    const {
      title,
      start,
      end,
      eventType,
      eventStatus,
      interpreter,
      startCorrected,
      endCorrected,
    } = this.state;
    const { event } = this.props;

    // VALIDATE
    const _title = title.trim();
    if (
      !_title ||
      !start ||
      !end ||
      !eventType ||
      !eventStatus ||
      !interpreter
    ) {
      this.setState({ errorMessage: 'Täytä kaikki vaaditut kentät' });
      return;
    }

    if (start >= end) {
      this.setState({
        errorMessage: 'Päättymisajan on oltava alkamisen jälkeen',
      });
      return;
    }

    // if (!this.sameDay(start, end)) {
    //    this.setState({errorMessage: "Päättymis ja alkamisaikojen on oltava samana päivänä"})
    //    return
    // }

    if (
      (startCorrected && !endCorrected) ||
      (!startCorrected && endCorrected)
    ) {
      this.setState({
        errorMessage: 'Päättymisajan on oltava alkamisen jälkeen',
      });
      return;
    }

    if (startCorrected && endCorrected && startCorrected >= endCorrected) {
      this.setState({
        errorMessage: 'Päättymisajan on oltava alkamisen jälkeen',
      });
      return;
    }

    if (
      startCorrected &&
      endCorrected &&
      !this.sameDay(startCorrected, endCorrected)
    ) {
      this.setState({
        errorMessage:
          'Korjattujen päättymis ja alkamisaikojen on oltava samana päivänä',
      });
      return;
    }

    if (startCorrected && !this.sameDay(start, startCorrected)) {
      this.setState({
        errorMessage: 'Korjattu päivä ei voi olla eri kuin alkuperäinen',
      });
      return;
    }

    // Check for overlapping events
    try {
      this.setState({ isLoading: true });
      const eventId = event && event.id;
      const overlappingExists = await CalendarEvent.overlappingExists(
        start,
        end,
        interpreter,
        eventId,
      );
      if (overlappingExists) {
        this.setState({ isLoading: false, overlappingDialogVisible: true });
        // Use this line to preve this.setState({errorMessage: 'Et voi tallentaa päällekkäisiä tapahtumia', isLoading: false})
      } else this.saveToFirebase();
    } catch (err) {
      this.setState({ errorMessage: err, isLoading: false });
    }
  };

  saveToFirebase = () => {
    const {
      title,
      start,
      end,
      eventType,
      eventStatus,
      description,
      interpreter,
      startCorrected,
      endCorrected,
      notes,
    } = this.state;
    const { onEventCreated, event } = this.props;
    const _title = title.trim();

    if (event) {
      // Is editing existing
      const dateEdited = new Date();
      CalendarEvent.editEvent(
        event.id,
        start,
        end,
        eventStatus,
        eventType,
        _title,
        description,
        interpreter,
        notes,
        startCorrected,
        endCorrected,
        dateEdited,
      )
        .then(() => {
          onEventCreated(); // there is no spesific function for event updated. HEnce we use onEventCreated
          this.props.reFetchEvents();
        })
        .catch(() => {
          this.setState({
            errorMessage: 'Tapahtuman muokkaaminen epäonnistui',
            isLoading: false,
          });
        });
    } else {
      const dateCreated = new Date();
      CalendarEvent.saveEventToFirebase(
        start,
        end,
        eventStatus,
        eventType,
        _title,
        description,
        interpreter,
        dateCreated,
      )
        .then(() => {
          onEventCreated();
        })
        .catch(() => {
          this.setState({
            errorMessage: 'Tapahtuman tallennus epäonnistui',
            isLoading: false,
          });
        });
    }
  };

  render() {
    const {
      title,
      description,
      start,
      end,
      startCorrected,
      endCorrected,
      notes,
      interpreter,
      interpreterOptions,
      errorMessage,
      eventType,
      isLoading,
      eventStatus,
      overlappingDialogVisible,
    } = this.state;
    const { onClose, event, isAdministrator } = this.props;

    return (
      <>
        <AdvancedModal
          title="Uusi varaus"
          primaryBtnTitle="Tallenna"
          secondaryBtnTitle="Peruuta"
          primaryBtnPressed={this.savePressed}
          secondaryBtnPressed={onClose}
          disabled={isLoading}
        >
          <div className="new-event-box">
            {isAdministrator && (
              <div>
                <p>Otsikko*</p>
                <Input
                  value={title}
                  valueToState={new ValueToState(this, 'title')}
                  disabled={isLoading}
                />

                <div className="date-picker-row">
                  <div>
                    <p>Alkaa*</p>
                    <DatePicker
                      id="datePickerStart"
                      selected={start}
                      onChange={(date) => {
                        this.setState({ start: date });
                      }}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      dateFormat="dd.MM.yyyy, HH:mm"
                      timeCaption="klo"
                      disabled={isLoading}
                    />
                  </div>
                  <div>
                    <p>Päättyy*</p>
                    <DatePicker
                      id="datePickerEnd"
                      selected={end}
                      onChange={(date) => {
                        this.setState({ end: date });
                      }}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      dateFormat="dd.MM.yyyy, HH:mm"
                      timeCaption="klo"
                      disabled={isLoading}
                    />
                  </div>
                </div>

                <SingleSelectDropdown
                  title="Tapahtuman tyyppi*"
                  // errorMessage={ inputErrors && inputErrors.prop }
                  // disabled={isLoading}
                  options={this.eventTypes}
                  value={eventType}
                  valueChanged={(eventType) => this.setState({ eventType })}
                  disabled={isLoading}
                />

                <SingleSelectDropdown
                  title="Tapahtuman status*"
                  options={[
                    { value: 'normal', label: 'Normaali' },
                    { value: 'cancelled', label: 'Peruttu' },
                  ]}
                  value={eventStatus}
                  valueChanged={(status) =>
                    this.setState({ eventStatus: status })
                  }
                  clearable={false}
                  disabled={isLoading}
                />

                <SingleSelectDropdown
                  title="Tulkki*"
                  // errorMessage={ inputErrors && inputErrors.prop }
                  // disabled={isLoading}
                  searchable={true}
                  options={interpreterOptions}
                  value={interpreter}
                  valueChanged={(interpreter) => this.setState({ interpreter })}
                  disabled={isLoading}
                />

                <p>Lisätietoja</p>
                <Input
                  value={description}
                  valueToState={new ValueToState(this, 'description')}
                  rows={3}
                  disabled={isLoading}
                />
              </div>
            )}

            {/* If Event exists, editing */}
            {event && (
              <div className="date-picker-row">
                <div>
                  <p>Korjattu alkamisaika*</p>
                  <DatePicker
                    id="datePickerStart"
                    selected={startCorrected}
                    onChange={(date) => {
                      this.setState({ startCorrected: date });
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    dateFormat="dd.MM.yyyy, HH:mm"
                    timeCaption="klo"
                    disabled={isLoading}
                  />
                </div>
                <div>
                  <p>Korjattu päättymisaika*</p>
                  <DatePicker
                    id="datePickerEnd"
                    selected={endCorrected}
                    onChange={(date) => {
                      this.setState({ endCorrected: date });
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    dateFormat="dd.MM.yyyy, HH:mm"
                    timeCaption="klo"
                    disabled={isLoading}
                  />
                </div>
              </div>
            )}

            {!isAdministrator && (
              <div>
                <p>Huomiota</p>
                <Input
                  value={notes}
                  valueToState={new ValueToState(this, 'notes')}
                  rows={3}
                  disabled={isLoading}
                />
              </div>
            )}

            <p className="error">{errorMessage}</p>
          </div>
        </AdvancedModal>

        {overlappingDialogVisible && (
          <Modal>
            <Dialog
              title="Rinnakkainen tapahtuma on olemassa"
              message="Haluatko silti tallentaa tapahtuman"
              primaryBtnTitle="Tallenna"
              destructive={true}
              cancelPressed={() =>
                this.setState({ overlappingDialogVisible: false })
              }
              confirmPressed={() => {
                this.setState({
                  isLoading: true,
                  overlappingDialogVisible: false,
                });
                this.saveToFirebase();
              }}
            />
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAdministrator: state.auth.user.isAdministrator,
  };
};

export default connect(mapStateToProps)(NewEventBox);
