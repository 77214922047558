import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltToLeft } from '@fortawesome/fontawesome-pro-solid';
import { faArrowAltFromLeft } from '@fortawesome/fontawesome-pro-solid';

// redux
import { connect } from 'react-redux';

// components
import ButtonNewItem from 'components/ButtonNewItem/button-new-item.js';
import List from 'components/List/list.js';
import InterpreterSearch from 'components/interpreterSearch/interpreter-search';
import './list-container.css';

class ListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
    };
  }

  render() {
    const { title, isMobile, createNewTapped } = this.props;

    return (
      <div
        className={'list-container' + (this.state.toggled ? ' toggled' : '')}
        style={{
          display: isMobile && title === 'Kalenteri' ? 'none' : '',
        }}
      >
        <div className="list-inner-container">
          {!this.state.toggled && (
            <div>
              <div className="list-title-section">
                <h2>{title}</h2>

                {createNewTapped && <ButtonNewItem onClick={createNewTapped} />}
              </div>

              {(title === 'Tulkit' ||
                title === 'Kalenteri' ||
                title === 'Yhteenveto') && <InterpreterSearch />}

              <List
                listItems={this.props.listItems}
                cellTapped={this.props.cellTapped}
                selectedItem={this.props.selectedItem}
              />
            </div>
          )}

          <div />

          <div
            className="toggle-button"
            onClick={() => {
              this.setState({ toggled: !this.state.toggled });
            }}
          >
            {!this.state.toggled ? (
              <FontAwesomeIcon icon={faArrowAltToLeft} />
            ) : (
              <FontAwesomeIcon icon={faArrowAltFromLeft} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.device.isMobile,
  };
};

export default connect(mapStateToProps)(ListContainer);
