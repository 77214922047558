import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './toggle.css'


// Toggle created from example: https://codepen.io/mallendeo/pen/eLIiG
export default class Toggle extends Component {

	static propTypes = {
		toggleId: PropTypes.string.isRequired,
		isOn: PropTypes.bool,
		defaultValue: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
	}
	static defaultProps = {
		defaultValue: true
	}

	constructor(props) {
		super(props)

		this.state = {
			isOn: this.props.isOn != null ? this.props.isOn : this.props.defaultValue,
		}
	}

	componentDidUpdate(prevProps) {
		const { isOn } = this.props
		if (isOn !== prevProps.isOn) {
			this.setState({ isOn })
		}
	}

	render() {
		const { isOn } = this.state
		const { onChange, toggleId, disabled } = this.props

		return (
			<div className="toggle-btn">
				<input
					id={toggleId}
					className="tgl tgl-light"
					type="checkbox"
					checked={isOn}
					disabled={disabled}
					onChange={() => {
						const value = !isOn
						if (!this.props.isOn) this.setState({ isOn : value })
						onChange(value)
					}}
				/>
				<label
					className="tgl-btn"
					htmlFor={toggleId}
				/>
			</div>
		)
	}


}