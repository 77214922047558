import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { throttle } from 'lodash';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi';
import CurrentUser from 'models/current-user';

// redux
import { connect } from 'react-redux';
import { setSize } from 'actions/device-actions';
import { setCurrentUser } from 'actions/setCurrentUser';

// firebase
import firebase from 'config/firebaseConfig';
import { signOut } from 'services/auth-service';

// components
import SideMenu from 'components/SideMenu/side-menu.js';
import PageOrganizations from 'components/PageOrganizations/page-organization';
import pageInterpreters from 'components/PageInterpreters/page-interpreters';
import PageLogin from 'components/PageLogin/page-login';
import LoadingSpinner from 'components/LoadingSpinner/loading-spinner';
import pageLogs from 'components/PageLogs/page-logs';
import pageStaff from 'components/PageStaff/page-staff';
import PageAccountComponent from 'components/PageAccount/page-account';
import PageJoinOrganization from 'components/PageJoinOrganization/page-join-organization';
import PageNewAccount from 'components/PageNewAccount/page-new-account';
import PageLanguages from 'components/PageLanguages/page-languages';
import ErrorComponent from 'components/ErrorComponent/error-component';
import PageForgotPassword from 'components/PageForgotPassword/page-forgot-password';
import PageCalendar from 'components/PageCalendar/page-calendar';
import Navbar from 'components/Navbar/navbar';
import pageCalendarLogs from 'components/PageCalendarLogs/page-calendar-logs';
import PageRequests from 'components/PageRequests/page-requests';

import './App.css';
import './bro-theme.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.appState = {
      IS_LOADING: 'IS_LOADING',
      SIGN_IN: 'SIGN_IN',
      CREATE_USER: 'CREATE_USER',
      FORGOT_PASSWORD: 'FORGOT_PASSWORD',
      JOIN_ORGANIZATION: 'JOIN_ORGANIZATION',
      USER_HAS_NO_PERMISSION: 'USER_HAS_NO_PERMISSION',
      ORGANIZATION_LOCKED: 'ORGANIZATION_LOCKED',
      IS_ADMINISTRATOR: 'IS_ADMINISTRATOR',
      IS_ORGANIZATION_ADMIN: 'IS_ORGANIZATION_ADMIN',
      IS_INTERPRETER: 'IS_INTERPRETER',
    };

    this.state = {
      appState: this.appState.IS_LOADING,
      joinOrganization: false,
      createAccount: false,
    };

    firebase.auth().onAuthStateChanged(
      function (user) {
        if (user) {
          const currentUser = new CurrentUser();
          currentUser.setupUser(user, (joinOrganization) => {
            // data ready
            if (joinOrganization) {
              this.setState({ appState: this.appState.JOIN_ORGANIZATION });
            } else {
              this.props.setCurrentUser(currentUser);
              this.setupAppState(currentUser);
            }
          });
        } else {
          this.setState({ appState: this.appState.SIGN_IN });
          this.props.setCurrentUser(null);
          // this.setState({joinOrganization: false})
        }
      }.bind(this),
    );

    //  firebase.auth().onAuthStateChanged(function(user) {
    //    if (user) {
    //       console.log("user signed in")
    //       const currentUser = new CurrentUser()

    //       currentUser.setupUser(user, (joinOrganization) => {
    //          // data ready
    //          if (joinOrganization) {
    //             this.setState({joinOrganization: true})
    //          } else {
    //             this.props.setCurrentUser(currentUser)
    //          }
    //       })
    //    } else {
    //       console.log("user is signed out");
    //       this.props.setCurrentUser(null)
    //       this.setState({joinOrganization: false})
    //    }
    //  }.bind(this));
  }

  componentDidMount() {
    this.props.setSize(window.innerWidth);
    window.addEventListener('resize', throttle(this.onWindowResize, 200));

    // Set react-datepicker locale
    registerLocale('fi', fi);
    setDefaultLocale('fi');
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }
  onWindowResize = () => {
    this.props.setSize(window.innerWidth);
  };

  setupAppState(currentUser) {
    if (currentUser.isAdministrator) {
      this.setState({ appState: this.appState.IS_ADMINISTRATOR });
    } else if (currentUser.isOrganizationAdmin) {
      this.setState({ appState: this.appState.IS_ORGANIZATION_ADMIN });
    } else if (currentUser.hasNoPermission) {
      this.setState({ appState: this.appState.USER_HAS_NO_PERMISSION });
    } else if (currentUser.isOrganizationLocked) {
      this.setState({ appState: this.appState.ORGANIZATION_LOCKED });
    } else if (currentUser.isInterpreter) {
      this.setState({ appState: this.appState.IS_INTERPRETER });
    } else {
      console.log('cannot identify case');
    }
  }

  userCreated() {
    this.setState({ createAccount: false });
    const user = firebase.auth().currentUser;
    if (user) {
      const currentUser = new CurrentUser();
      currentUser.setupUser(user, () => {
        this.props.setCurrentUser(currentUser);
      });
    } else {
      console.log('Sign out because no user');
      signOut();
    }
  }

  joinedOrganization() {
    const user = firebase.auth().currentUser;
    if (user) {
      const currentUser = new CurrentUser();
      currentUser.setupUser(user, () => {
        // this.setState({joinOrganization: false})
        this.props.setCurrentUser(currentUser);
        this.setupAppState(currentUser);
      });
    } else {
      console.log('Sign out because no user');
      signOut();
    }
  }

  redirect = () => {
    return <Redirect to={'/kalenteri'} />;
  };

  render() {
    // const { createAccount } = this.state
    const { isMobile } = this.props;

    const PageAccount = () => {
      return (
        <PageAccountComponent
          exittedCompany={() => {
            this.setState({ joinOrganization: true });
          }}
        />
      );
    };

    let elements;
    switch (this.state.appState) {
      case this.appState.CREATE_USER:
        elements = (
          <PageNewAccount
            userCreated={this.userCreated.bind(this)}
            cancelPressed={() =>
              this.setState({ appState: this.appState.SIGN_IN })
            }
          />
        );
        break;
      case this.appState.SIGN_IN:
        elements = (
          <PageLogin
            createAccountPressed={() =>
              this.setState({ appState: this.appState.CREATE_USER })
            }
            forgotPasswordPressed={() =>
              this.setState({ appState: this.appState.FORGOT_PASSWORD })
            }
          />
        );
        break;
      case this.appState.FORGOT_PASSWORD:
        elements = (
          <PageForgotPassword
            cancelPressed={() =>
              this.setState({ appState: this.appState.SIGN_IN })
            }
          />
        );
        break;
      case this.appState.JOIN_ORGANIZATION:
        elements = (
          <PageJoinOrganization joined={this.joinedOrganization.bind(this)} />
        );
        break;
      case this.appState.USER_HAS_NO_PERMISSION:
        elements = (
          <ErrorComponent
            title="Sinulla ei ole oikeutta hallintajärjestelmään!"
            subtitle="Ota yhteyttä tulkkikeskukseen"
          />
        );
        break;
      case this.appState.ORGANIZATION_LOCKED:
        elements = (
          <ErrorComponent
            title="Tunnukset on lukittu!"
            subtitle="Ota yhteyttä tulkkikeskukseen"
          />
        );
        break;
      case this.appState.IS_ADMINISTRATOR:
        // mehedi
        elements = (
          <Switch>
            <Route exact path="/tilaukset" component={PageRequests} />
            <Route exact path="/loki" component={pageLogs} />
            <Route path="/asiakkaat" component={PageOrganizations} />
            <Route path="/tulkit" component={pageInterpreters} />
            <Route path="/kielet" component={PageLanguages} />
            <Route path="/kalenteri" component={PageCalendar} />
            <Route path="/yhteenveto" component={pageCalendarLogs} />
            <Route path="/" component={this.redirect} />
          </Switch>
        );
        break;
      case this.appState.IS_ORGANIZATION_ADMIN:
        // mehedi
        elements = (
          <Switch>
            <Route exact path="/loki" component={pageLogs} />
            <Route path="/henkilosto" component={pageStaff} />
            <Route path="/tiedot" component={PageAccount} />
            <Route path="/" component={this.redirect} />
          </Switch>
        );
        break;

      case this.appState.IS_INTERPRETER:
        // mehedi
        elements = (
          <Switch>
            {/* <Route exact path="/loki" component={pageLogs}/> */}
            <Route path="/kalenteri" component={PageCalendar} />
            <Route path="/yhteenveto" component={pageCalendarLogs} />
            <Route path="/" component={this.redirect} />
          </Switch>
        );
        break;

      default:
        break;
    }

    const navBarHeight = 56;
    return (
      <div>
        {this.state.appState === this.appState.IS_LOADING ? (
          <LoadingSpinner />
        ) : (
          <div>
            {this.state.appState === this.appState.IS_ADMINISTRATOR ||
            this.state.appState === this.appState.IS_ORGANIZATION_ADMIN ||
            this.appState.IS_INTERPRETER ? (
              <div>
                <BrowserRouter>
                  <div>
                    {isMobile && <Navbar />}
                    {/* style={{height: `calc(100% - ${navBarHeight}px)`, marginTop: `${navBarHeight}px`}} */}
                    <div className="main-container">
                      {!isMobile && <SideMenu />}
                      <div
                        className="page-container"
                        style={
                          isMobile ? { paddingTop: `${navBarHeight}px` } : {}
                        }
                      >
                        {elements}
                      </div>
                    </div>
                  </div>
                </BrowserRouter>
              </div>
            ) : (
              <div>{elements}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.auth.user,
    isMobile: state.device.isMobile,
  };
};

export default connect(mapStateToProps, { setCurrentUser, setSize })(App);
