import React, {Component} from 'react';
import './container-box.css'
import BorderlessButton from '../BorderlessButton/borderless-button.js'
import {connect} from 'react-redux'
import classNames from 'classnames'
// import Dropdown from '../Dropdown/dropdown.js'
// import faTimes from "@fortawesome/fontawesome-pro-light/faTimes";

// import {faCog as faCogS} from '@fortawesome/fontawesome-pro-solid'
import {faTimes} from '@fortawesome/fontawesome-pro-light'

class ContainerBox extends Component {

  render() {
   // menuItems, menuItemSelected, 
   const { onClose, item, isMobile } = this.props

    return (
      <div className={classNames("container-box", {mobile: isMobile})}>

        {/* { menuItems &&
          <Dropdown menuItems={menuItems} onSelect={menuItemSelected} button={
              <BorderlessButton className="cog-wheel" icon={faCogS}/>
            }/>
        } */}

        { onClose &&
            <BorderlessButton className="close-button" icon={faTimes} onClick={() => {
               onClose(item)
            }}/>
        }


        {this.props.children}

      </div>
    );
  }

}

const mapStateToProps = state => {
  return {
    isMobile: state.device.isMobile
  }
}

export default connect(mapStateToProps)(ContainerBox)
