import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './button.css';

export default class Button extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { type, onClick } = this.props;
    if (type === Button.Type.FILE) {
      this.inputRef.current.value = '';
      this.inputRef.current.click();
    }
    if (onClick) onClick();
  }

  render() {
    const {
      id,
      className,
      title,
      type,
      accept,
      onFileInserted,
      styleType,
      disabled,
      noMargin,
      round,
      outline,
      highlightOnFocus,
      minWidth,
      float,
      marginEnd,
      hidden,
      children,
      style,
    } = this.props;

    var classN = 'bro-button btn btn-';
    if (outline) classN += 'outline-';
    switch (styleType) {
      case Button.StyleType.PRIMARY:
        classN += 'primary';
        break;
      case Button.StyleType.SUCCESS:
        classN += 'success';
        break;
      case Button.StyleType.WARNING:
        classN += 'warning';
        break;
      case Button.StyleType.DANGER:
        classN += 'danger';
        break;
      case Button.StyleType.SECONDARY:
        classN += 'secondary';
        break;
      default:
        break;
    }
    if (className) classN += ' ' + className;
    if (float) classN += ' float-' + float;
    if (round) classN += ' round';

    const styling = {
      marginTop: !noMargin ? '12px' : null,
      marginBottom: !noMargin ? '8px' : null,
      minWidth: minWidth ? minWidth : null,
      marginRight: marginEnd ? marginEnd : null,
      display: hidden ? 'none' : null,
    };

    if (style) {
      Object.assign(styling, style);
    }

    return (
      <button
        id={id}
        type={type === Button.Type.SUBMIT ? 'submit' : 'button'}
        className={classN}
        disabled={disabled}
        onClick={this.onClick}
        data-type={styleType}
        data-highlight-on-focus={highlightOnFocus}
        style={styling}
      >
        {type === Button.Type.FILE && (
          <input
            ref={this.inputRef}
            name="file"
            type="file"
            accept={accept}
            onChange={onFileInserted && onFileInserted}
          />
        )}
        {title}
        {children}
      </button>
    );
  }

  static Type = Object.freeze({
    BUTTON: 'button',
    SUBMIT: 'submit',
    FILE: 'file',
  });

  static StyleType = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
  });

  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string, // teksti
    outline: PropTypes.bool, // outline button
    round: PropTypes.bool, // pyöreät reunat
    highlightOnFocus: PropTypes.bool, // tuleeko highlight ympärille kun nappi on focused
    onClick: PropTypes.func,
    type: PropTypes.string,
    onFileInserted: PropTypes.func,
    styleType: PropTypes.string, // "primary", "secondary", jne. voi kutsua suoraan StyleType objektista tai kirjoittaa vaan suoraan. Voi keksiä myös omia
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    minWidth: PropTypes.string,
    noMargin: PropTypes.bool, // napissa on defaulttina pienet marginit. jos tämä on true älä käytä niitä. jos ei halua koskaan niin defaultPropseista noMargin: true
    marginEnd: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    hidden: PropTypes.bool, // hävitä nappi näkyvistä (ei vie tilaa - display: none)
    float: PropTypes.string, // "left", "right" ..
    style: PropTypes.object,
  };

  static defaultProps = {
    type: Button.Type.BUTTON,
    styleType: Button.StyleType.PRIMARY,
    outline: false,
    round: false,
    noMargin: false,
    highlightOnFocus: false,
    disabled: false,
    hidden: false,
  };
}
