import React, { Component } from 'react';
import sha1 from 'sha1';

// firebase
import firebase from 'config/firebaseConfig';
import { signOut } from 'services/auth-service';

// components
import Input from 'components/Input/input.js';
import LoadingSpinner from 'components/LoadingSpinner/loading-spinner';
import Button from 'components/Button/button.js';
import BorderlessButton from 'components/BorderlessButton/borderless-button.js';

import jquery from 'jquery';
import './page-join-organization.css';

// mehedi - unused imports
// import * as firebase from 'firebase';
// import "firebase/firestore";
// import {signIn} from '../../Services/auth-service.js'

export default class PageJoinOrganization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      errorMessage: '',
      inputErrors: {},
      isLoading: false,
      changePassword: true,
      focusOnErrorInput: false,
    };

    this.onChange = this.onChange.bind(this);
    this.joinPressed = this.joinPressed.bind(this);
  }

  validate() {
    var inputErrors = {};

    if (!this.state.password || this.state.password === '') {
      inputErrors.password = 'Vaaditaan';
    }

    if (!this.state.login || this.state.login === '') {
      inputErrors.login = 'Vaaditaan';
    }

    this.setState({ inputErrors });

    return jquery.isEmptyObject(inputErrors); // inputErrors.isEmpty()
  }

  joinPressed() {
    this.setState({ isLoading: true });

    // validate
    const valid = this.validate();
    if (!valid) {
      this.setState({ isLoading: false });
      return;
    }

    const addMessage = firebase.functions().httpsCallable('/organization/join');
    const pass = sha1(this.state.password);
    addMessage({ login: this.state.login, password: pass })
      .then(() => {
        this.props.joined();
      })
      .catch((error) => {
        if (error.code === 'invalid-argument') {
          this.setState({
            errorMessage: 'Käyttäjätunnus ja salasana eivät täsmää',
            isLoading: false,
          });
          this.setState({ isLoading: false });
        } else {
          console.log(error);
          this.setState({
            errorMessage: 'Jotain meni pieleen',
            isLoading: false,
          });
        }
      });

    // firebase.functions().httpsCallable('/organization/join').
    // addMessage({ ogin: this.state.login, password: this.state.password })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    // const { joinOrganization } = this.props
    const { login, password, errorMessage, inputErrors, isLoading } =
      this.state;

    return (
      <div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="signInForm">
            <div className="row">
              <div className="col-12">
                <h2>Liity yritykseen/organisaatioon</h2>
                <br />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Input
                  type="text"
                  name="login"
                  placeholder="Käyttäjätunnus"
                  value={login}
                  onChange={this.onChange}
                  autoFocus
                  errorMessage={inputErrors && inputErrors.login}
                  disabled={isLoading}
                />

                <Input
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  placeholder="Salasana"
                  errorMessage={inputErrors && inputErrors.login}
                  disabled={isLoading}
                />

                {errorMessage && <p className="error-txt">{errorMessage}</p>}
              </div>
            </div>

            <Button
              title="Liity"
              styleType="primary"
              round
              onClick={this.joinPressed}
            />

            <div>
              <BorderlessButton
                className="log-out-btn"
                title="Kirjaudu ulos"
                styleType="danger"
                onClick={() => signOut()}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
