import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import Modal from 'components/Modal/modal';
import Button from 'components/Button/button';
import InterpreterSearch from 'components/interpreterSearch/interpreter-search';
import './interpreters-modal.css';

// this modal only view in small devices
export default class InterpretersModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    primaryBtnPressed: PropTypes.func,
    primaryBtnTitle: PropTypes.string,
    disabled: PropTypes.bool,
  };

  render() {
    const { title, disabled, primaryBtnPressed, primaryBtnTitle } = this.props;

    return (
      <Modal isInterpreterModal={true}>
        <div className="interpreter-modal">
          <h3 className="title">{title}</h3>
          <hr className="title-underline" />

          <InterpreterSearch />

          {this.props.children}

          <hr />

          <div className="interpreter-modal-button-row">
            <Button
              round
              title={primaryBtnTitle}
              onClick={primaryBtnPressed}
              disabled={disabled}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
