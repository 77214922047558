import React from 'react';
import ReactDOM from 'react-dom';

// redux
import { rootReducer } from './reducers/rootReducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { unregister } from './registerServiceWorker';

window.$ = window.jQuery = require('jquery'); // Required for Bootstrap
// window.Tether = require('tether')  // Required for Bootstrap
window.Popper = require('popper.js'); // // Required for Bootstrap
require('bootstrap'); // Needed only for Bootstrap JavaScript features

const store = createStore(rootReducer, composeWithDevTools());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
unregister();
