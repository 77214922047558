import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import './modal.css';

class Modal extends Component {
  render() {
    const { isInterpreterModal } = this.props;

    return (
      <div className="modal-window">
        <div
          className={classNames('modal-content-container', {
            mobile: this.props.isMobile,
          })}
          style={{ width: isInterpreterModal ? '100%' : '' }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.device.isMobile,
  };
};

export default connect(mapStateToProps)(Modal);
