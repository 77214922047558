import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import guid from 'utils/Uuid';

import './input.css';

export default class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id ? this.props.id : guid(),
    };

    this.lastVal = this.props.value ? this.props.value : '';
    this.input = null;

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { reference } = this.props;
    if (reference) reference(this.input);
    this.input.value = this.props.value
      ? this.props.value
      : this.props.valueToState
      ? this.props.valueToState._this.state[this.props.valueToState.name]
        ? this.props.valueToState._this.state[this.props.valueToState.name]
        : ''
      : '';
  }

  onChange(e) {
    if (this.props.regex) {
      var result = e.target.value.match(this.props.regex);
      if (!result) this.input.value = this.lastVal;
      else if (this.props.onChange) this.props.onChange(e);
      if (this.props.valueToState)
        this.props.valueToState.setState(e.target.value);
    } else {
      if (this.props.onChange) this.props.onChange(e);
      if (this.props.valueToState)
        this.props.valueToState.setState(e.target.value);
    }
    this.lastVal = e.target.value;
  }

  onKeyDown = (e) => {
    const { onEnterPressed } = this.props;
    if (e.keyCode === 13) {
      if (onEnterPressed) onEnterPressed();
    }
  };

  render() {
    const { id } = this.state;
    const {
      className,
      title,
      name,
      value,
      placeholder,
      leftText,
      rightText,
      bold,
      large,
      autoFocus,
      autoComplete,
      type,
      error,
      errorMessage,
      disabled,
      hint,
      rows,
      onEnterPressed,
    } = this.props;

    return (
      <div
        className={'bro-input form-group ' + (className ? className : '')}
        style={{ marginBottom: title ? null : '8px' }}
      >
        {title && (
          <label htmlFor={id} data-bold={bold}>
            {title}
          </label>
        )}
        {!rows || rows <= 1 ? (
          <div className="input-group">
            {leftText && (
              <div className="input-group-append">
                <span className="input-group-text">{leftText}</span>
              </div>
            )}
            <input
              ref={(input) => (this.input = input)}
              id={id}
              name={name}
              value={value}
              onChange={this.onChange}
              onKeyDown={onEnterPressed ? this.onKeyDown : undefined}
              placeholder={placeholder}
              className={classnames(
                'form-control',
                { 'form-control-lg': large },
                { 'is-invalid': error || errorMessage },
              )}
              type={type}
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              disabled={disabled}
            />

            {rightText && (
              <div className="input-group-append">
                <span className="input-group-text">{rightText}</span>
              </div>
            )}
          </div>
        ) : (
          <textarea
            ref={(input) => (this.input = input)}
            id={id}
            rows={rows}
            name={name}
            value={value}
            onChange={this.onChange}
            placeholder={placeholder}
            className={classnames(
              'form-control',
              { 'form-control-lg': large },
              { 'is-invalid': error || errorMessage },
            )}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            disabled={disabled}
          />
        )}
        {hint && <small className="hint">{hint}</small>}
        {errorMessage && (
          <small className="error-message">{errorMessage}</small>
        )}
      </div>
    );
  }

  static propTypes = {
    title: PropTypes.string, // Otsikko yläpuolelle
    bold: PropTypes.bool, // Tuleeko title boldina
    hint: PropTypes.string, // Pieni musta viesti fieldin alapuolelle
    large: PropTypes.bool, // Hitusen isompi koko
    disabled: PropTypes.bool, // Kenttä disabloitu
    error: PropTypes.bool, // Punaiseksi fieldin reunat
    errorMessage: PropTypes.oneOfType([
      // Pieni punainen error viesti fieldin alapuolelle + punaiseksi fieldin reunat
      PropTypes.bool,
      PropTypes.string,
    ]),
    rows: PropTypes.number, // Multiline (numer of rows)
    regex: PropTypes.string, // Hyväksy vain regexin sallimat merkit

    leftText: PropTypes.string, // Left input group text
    rightText: PropTypes.string, // Right input group text

    name: PropTypes.string, // name
    type: PropTypes.string, // type
    value: PropTypes.string, // value
    placeholder: PropTypes.string, // placeholder
    onChange: PropTypes.func, // onChange
    autoComplete: PropTypes.string, // autoComplete
    autoFocus: PropTypes.bool, // Autofocus tähän kenttään
    reference: PropTypes.func, // ref
    onEnterPressed: PropTypes.func,

    valueToState: PropTypes.object, // päivittää automaattisesti stateen tämän inputin valuen siihen elementtiin jossa tätä komponenttia käytetään - new ValueToState(this, stateVariableName)
    //PropTypes.objectOf(Input.ValueToState)
  };

  static defaultProps = {
    title: '',
    large: false,
    disabled: false,
    error: false,
    errorMessage: null,
    autoComplete: 'off',
  };
}

export class ValueToState {
  constructor(_this, name) {
    this._this = _this;
    this.name = name;
  }

  setState = (value) => {
    this._this.setState({
      [this.name]: value,
    });
  };
}
