import { combineReducers } from 'redux';
import organizationsReducer from './organizations-reducer.js';
import interpretersReducer from './interpreters-reducer.js';
import currentUserReducer from './current-user-reducer';
import staffReducer from './staff-reducer';
import organizationNamesReducer from './organization-names-reducer';
import interpreterNamesReducer from './interpreter-names-reducer';
import userNamesReducer from './user-names-reducer.js';
import deviceReducer from './device-reducer';
import searchInterpretersReducer from './search-interpreters-reducer';

export const rootReducer = combineReducers({
  organizations: organizationsReducer,
  auth: currentUserReducer,
  staff: staffReducer,
  organizationNames: organizationNamesReducer,
  userNames: userNamesReducer,
  interpreters: interpretersReducer,
  interpreterNames: interpreterNamesReducer,
  filteredInterpreters: searchInterpretersReducer,
  device: deviceReducer,
});
