import React, { Component } from 'react';
import ContentContainer from '../ContentContainer/content-container.js';
import { connect } from 'react-redux';
import ListContainer from '../ListContainer/list-container';
import { setStaff } from 'actions/setStaff';
import { getObjectById } from 'utils/Helper';
import User from '../../models/user';
import TitleComponent from '../TitleComponent/title-component';
import AnalyticsContainer from '../AnalyticsContainer/analytics-container';
import Dialog from '../Dialog/dialog';
import Modal from '../Modal/modal.js';

class PageStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
      showRemoveDialog: false,
    };

    this.menuItems = [{ value: 'remove', label: 'Poista', type: 'danger' }];
  }

  componentDidMount() {
    this.getOrganizations();
  }

  getOrganizations() {
    User.getUsers(
      this.props.organizationId,
      (staff) => {
        this.props.setStaff(staff);
      },
      (error) => {
        console.log(error);
      },
    );
  }

  cellTapped(id) {
    const selectedUser = getObjectById(this.props.staff, id);
    this.setState({ selectedUser });
  }

  menuItemSelected(value) {
    if (value === 'remove') {
      this.setState({ showRemoveDialog: true });
    }
  }

  render() {
    const { staff, organizationId } = this.props;
    const { selectedUser, showRemoveDialog } = this.state;

    return (
      <div>
        <ListContainer
          title="Henkilöstö"
          listItems={staff}
          cellTapped={this.cellTapped.bind(this)}
          selectedItem={selectedUser ? selectedUser.id : null}
        />

        <ContentContainer>
          {selectedUser && (
            <div>
              <TitleComponent
                title={selectedUser.firstName + ' ' + selectedUser.lastName}
                menuItems={this.menuItems}
                menuItemSelected={this.menuItemSelected.bind(this)}
              >
                {/* <p>{selectedUser.phoneNumber}</p> */}
              </TitleComponent>
              <AnalyticsContainer
                userId={selectedUser.id}
                organizationId={organizationId}
              />
            </div>
          )}
        </ContentContainer>

        {showRemoveDialog && (
          <Modal>
            <Dialog
              // mehedi - change the string concatenation
              // title={"Haluatko poistaa henkilön organisaatiosta: " + "\"" + this.props.organizationName + "\""}
              title={`Haluatko poistaa henkilön organisaatiosta: "${this.props.organizationName}"`}
              primaryBtnPressed="Poista"
              destructive={true}
              cancelPressed={() => this.setState({ showRemoveDialog: false })}
              confirmPressed={() => {
                this.setState({ showRemoveDialog: false });
                this.state.selectedUser.removeFromOrganization(
                  () => {
                    this.getOrganizations();
                    this.setState({ selectedUser: null });
                  },
                  (error) => {
                    console.log(error);
                  },
                );
              }}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizationId: state.auth.user.organizationId,
    staff: state.staff,
    organizationName: state.auth.user.organizationName,
  };
};

export default connect(mapStateToProps, { setStaff })(PageStaff);
