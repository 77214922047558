import React, { Component } from 'react';
import { removeObjectFromArray } from 'utils/Helper';

// firebase
import {
  getLanguageDictionary,
  getLanguageOptions,
  getLanguages,
  saveLanguagesToFirebase,
} from 'services/languages-service';

// components
import ContentContainer from 'components/ContentContainer/content-container.js';
import ContainerBox from 'components/ContainerBox/container-box.js';
import SingleSelectDropdown from 'components/SingleSelectDropdown/single-select-dropdown';
import Button from 'components/Button/button';
import RemovableListItem from 'components/RemovableListItem/removable-list-item';

import './page-languages.css';

// mehedi - unused imports
// import { connect } from 'react-redux'
// import AnalyticsContainer from '../AnalyticsContainer/analytics-container'
// import LanguageService from '../../services/languages-service.js';

export default class PageLanguages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      languageFrom: null,
      languageTo: null,
      languagesFrom: [],
      languagesTo: [],
    };

    this.getLanguagesFromFirebase = this.getLanguagesFromFirebase.bind(this);
    this.savePressed = this.savePressed.bind(this);

    this.languages = getLanguageDictionary();
    this.languageOptions = getLanguageOptions();

    this.getLanguagesFromFirebase();
  }

  getLanguagesFromFirebase() {
    getLanguages(
      (data) => {
        this.setState({ isLoading: false });
        if (data) {
          this.origFrom = data.from.sort();
          this.origTo = data.to.sort();
          this.setState({
            languagesFrom: data.from.sort(),
            languagesTo: data.to.sort(),
          });
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  savePressed() {
    this.setState({ isLoading: true });
    saveLanguagesToFirebase(
      this.state.languagesFrom,
      this.state.languagesTo,
      () => {
        this.getLanguagesFromFirebase();
      },
      (error) => {
        console.log(error);
      },
    );
  }

  addLanguageFrom() {
    if (this.state.languageFrom) {
      const newLang = this.state.languageFrom;
      const languages = [...this.state.languagesFrom];
      languages.indexOf(newLang) === -1
        ? languages.push(newLang)
        : console.log('This item already exists');
      this.setState({ languagesFrom: languages.sort(), languageFrom: null });
    }
  }

  addLanguageTo() {
    if (this.state.languageTo) {
      const newLang = this.state.languageTo;
      const languages = [...this.state.languagesTo];
      languages.indexOf(newLang) === -1
        ? languages.push(newLang)
        : console.log('This item already exists');
      this.setState({ languagesTo: languages.sort(), languageTo: null });
    }
  }

  // for sorting
  compare(a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  }

  render() {
    const { languagesFrom, languagesTo, isLoading } = this.state;

    var languagesFromObjects = languagesFrom.map((code) => {
      return { code: code, name: this.languages[code] };
    });
    languagesFromObjects.sort(this.compare);

    var languagesToObjects = languagesTo.map((code) => {
      return { code: code, name: this.languages[code] };
    });
    languagesToObjects.sort(this.compare);

    return (
      <div>
        <ContentContainer>
          <ContainerBox>
            <h4>Kielistä</h4>
            <div className="languagesTable">
              {languagesFromObjects.map((obj, index) => {
                return (
                  <RemovableListItem
                    key={obj.code}
                    title={obj.name}
                    index={index}
                    removePressed={() => {
                      const languages = removeObjectFromArray(
                        [...this.state.languagesFrom],
                        obj.code,
                      );
                      this.setState({ languagesFrom: languages });
                    }}
                  />
                );
              })}
            </div>

            <div className="language-dropdown-container">
              <SingleSelectDropdown
                // errorMessage={ inputErrors && inputErrors.sex }
                searchable={true}
                className="language-dropdown"
                disabled={isLoading}
                title="Uusi kieli"
                options={this.languageOptions}
                value={this.state.languageFrom}
                valueChanged={(languageFrom) => this.setState({ languageFrom })}
              />
            </div>

            <button
              className="add-language-button"
              onClick={this.addLanguageFrom.bind(this)}
            >
              Lisää
            </button>
          </ContainerBox>

          <ContainerBox>
            <h4>Kielille</h4>
            <div className="languagesTable">
              {languagesToObjects.map((obj, index) => {
                return (
                  <RemovableListItem
                    key={obj.code}
                    title={obj.name}
                    index={index}
                    removePressed={() => {
                      const languages = removeObjectFromArray(
                        [...this.state.languagesTo],
                        obj.code,
                      );
                      this.setState({ languagesTo: languages });
                    }}
                  />
                );
              })}
            </div>

            <div className="language-dropdown-container">
              <SingleSelectDropdown
                // errorMessage={ inputErrors && inputErrors.sex }
                searchable={true}
                className="language-dropdown"
                disabled={isLoading}
                title="Uusi kieli"
                options={this.languageOptions}
                value={this.state.languageTo}
                valueChanged={(languageTo) => this.setState({ languageTo })}
              />
            </div>
            <button
              className="add-language-button"
              onClick={this.addLanguageTo.bind(this)}
            >
              Lisää
            </button>
          </ContainerBox>

          <div className="button-row">
            <Button
              styleType="secondary"
              round
              title="Peruuta"
              onClick={() =>
                this.setState({
                  languagesFrom: this.origFrom,
                  languagesTo: this.origTo,
                })
              }
              disabled={isLoading}
            />
            <Button
              round
              title="Tallenna"
              onClick={this.savePressed}
              disabled={isLoading}
            />
          </div>
        </ContentContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//    return {
//       organizationId: state.auth.user.organizationId,
//       isAdministrator: state.auth.user.isAdministrator
//    }
// }

// export default connect(mapStateToProps)(PageLogs)
